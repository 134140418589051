import _ from 'lodash';
import $ from 'jquery';
import Auth from 'j-toker';
import { defaultHeaders } from 'redux-rest-resource';

const authUrl = '/user'

// Configure j-Toker
Auth.configure({
  apiUrl: `${authUrl}`,
  passwordResetSuccessUrl: function(){
    return window.location.origin+'/reset-password';
  },
});

let headers = {
  'Content-Type': 'application/json',
  'Locale': localStorage.Locale || 'en',
};

if(!_.isUndefined($.cookie('authHeaders'))){
  Object.assign(defaultHeaders, {
    ...headers,
    ...JSON.parse($.cookie('authHeaders')),
    "Accept": "application/json",
    "Content-Type": "application/json"
  });
}

const handleMessages = (response) => {
  return _.get(response, 'data.errors.full_messages', null) ||
    _.get(response.errors, 'full_messages', null) ||
    response.data.errors ||
    ["Please fill in the form and click submit"]
};

const handleAuthSuccess = () => {
  // ANY ACTIONS TO DO ON AUTH SUCCESS
};

export const signUp = ({email, password, first_name, last_name, age_group, accepted_terms, opt_out_badge_email, subscribe_to_email, postcode, private_profile, bam_opt_in, group_id, grecaptcha_token, success}) => {
  return dispatch => {
    dispatch({
      type: 'CREATE_USER',
      email: email,
      password: password,
    });

    Auth.emailSignUp({
      email,
      password,
      first_name,
      last_name,
      age_group,
      accepted_terms,
      opt_out_badge_email,
      unsubscribe_newsletter: !subscribe_to_email,
      private: private_profile,
      bam_opt_in,
      postcode,
      group_id,
      grecaptcha_token,
    }).then( (response) => {
      dispatch({
        type: 'CREATE_USER_SUCCESS',
        payload: response.data
      });

      handleAuthSuccess();
      success && success(response);

    }).fail( (response) => {
      dispatch({
        type: 'CREATE_USER_FAILED',
        full_messages: handleMessages(response)
      })
    });
  }
};

export const signIn = (email, password, success) => {
  return dispatch => {
    dispatch({
      type: 'SIGN_IN',
      email: email,
      password: password,
    });

    Auth.emailSignIn({
      email: email,
      password: password
    }).then( (response) => {

      dispatch({
        type: 'SIGN_IN_SUCCESS',
        payload: response.data
      });

      handleAuthSuccess();
      success && success(response);

    }).fail( (response) => {
      dispatch({
        type: 'SIGN_IN_FAILED',
        full_messages: handleMessages(response)
      })
    });
  }
};

export const requestPasswordReset = (email, success) => {
  return dispatch => {
    dispatch({
      type: 'RESET_PASSWORD',
      email: email,
    });

    if(_.isUndefined(email)){
      return dispatch({
        type: 'RESET_PASSWORD_FAILED',
        full_messages: ['Please enter your email address']
      })
    }

    Auth.requestPasswordReset({email: email}).then((response) => {

      dispatch({
        type: 'RESET_PASSWORD_SUCCESS',
        payload: response.data,
        full_messages: ["We've emailed you instructions to reset your password"]
      });

      success && success();

    }).fail((response) => {
      dispatch({
        type: 'RESET_PASSWORD_FAILED',
        full_messages: handleMessages(response)
      })
    })
  }

};

export const updateAccount = (user, success, fail) => {
  return dispatch => {
    dispatch({
      type: 'UPDATE_USER'
    });

    Auth.updateAccount(user).then((response) => {
      dispatch({
        type: 'UPDATE_USER_SUCCESS',
        payload: response
      });

      success && success();

    }).fail((response) => {
      dispatch({
        type: 'UPDATE_USER_FAILED',
        full_messages: handleMessages(response)
      });
    })
  }
};

export const isSignedIn = (signedIn, signedOut) => {
  return dispatch => {
    dispatch({
      type: 'CHECK_SIGNED_IN',
    });

    Auth.validateToken().then( (response) => {

      dispatch({
        type: 'CHECK_SIGNED_IN_SUCCESS',
        payload: response
      });

      handleAuthSuccess();
      signedIn && signedIn();

    }).fail( (response) => {
      console.log(['Auth.validateToken response', response]);

      dispatch({
        type: 'CHECK_SIGNED_IN_FAILED',
        full_messages: handleMessages(response)
      });

      signedOut && signedOut()
    });
  }
};

export const signOut = (success) => {
  return dispatch => {

    dispatch({
      type: 'SIGN_OUT'
    });

    Auth.signOut().then( () => {
      // TODO: Remove token from header

      dispatch({
        type: 'SIGN_OUT_SUCCESS'
      });

      success && success();
    })
  }
};
