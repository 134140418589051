import moment from 'moment'

export const dateDisplayFormat = 'MMM D, YYYY'
export const dateFormat = 'DD-MM-YYYY'
export const now = moment()

export const thisWeek = {
  from: now.clone().startOf('week'),
  to: now.clone().endOf('week')
}

export const lastWeek = {
  from: now.clone().subtract('week', 1).startOf('week'),
  to: now.clone().subtract('week', 1).endOf('week')
}

export const thisMonth = {
  from: now.clone().startOf('month'),
  to: now.clone().endOf('month')
}

export const rangeOptions = (start) => {
  return ({
    "This week": thisWeek,
    "Last week": lastWeek,
    "Last 30 days": {
      from: now.clone().subtract('days', 30),
      to: now.clone()
    },
    "This month": thisMonth,
    "Last month": {
      from: now.clone().subtract('month', 1).startOf('month'),
      to: now.clone().subtract('month', 1).endOf('month')
    },
    "Last 3 months": {
      from: now.clone().subtract('month', 3).startOf('month'),
      to: now.clone().subtract('month', 1).endOf('month')
    },
    "Last 6 months": {
      from: now.clone().subtract('month', 6).startOf('month'),
      to: now.clone().subtract('month', 1).endOf('month')
    },
    "Last year": {
      from: now.clone().subtract('year', 1).startOf('year'),
      to: now.clone().subtract('year', 1).endOf('year')
    },
    "All time": {
      from: start,
      to: now.clone(),
    },
  })
}