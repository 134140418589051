import React, { Component } from 'react'
import { Modal, ButtonGroup, Button, FormGroup, FormControl } from 'react-bootstrap'
import {connect} from 'react-redux'
import Navigation from 'Portal/components/Navigation.jsx'
import Footer from 'Portal/components/Footer.jsx'
import Auth from 'j-toker'
import configureAuth from '../../../../lib/configureAuth.js'

class ResetPassword extends Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      pwd: '',
      pwdConfirm: '',
      message: '',
      submitting: false,
      success: false,
    }
  }

  componentDidMount(){
    configureAuth();
  }

  setIsSubmitting(){
    this.setState({
      message: '',
      submitting: true
    });
  }

  handleSubmit(){

    this.setIsSubmitting();

    Auth.updatePassword({
      password: this.state.pwd,
      password_confirmation: this.state.pwdConfirm,
    }).then((res) => {

      this.setState({
        submitting: false,
        message: res.message,
        success: true,
      });

    }).fail((res) => {

      this.setState({
        submitting: false,
        message: res.data.errors.join(', ')
      });

    })
  }

  render() {
    const { success } = this.state

    return (
      <div className="app-portal">
        <Navigation />
        <div className="wrapper">
          <h1 className="login__header">Reset Password</h1>
          <div className="login__container">
            <form className="login__form">
              {
                this.state.message.length > 0 && <div className="alert alert-warning">{this.state.message}</div>
              }
              <FormGroup>
                <label>New Password</label>
                <FormControl value={this.state.pwd} onChange={(e) => this.setState({pwd: e.target.value})}  type="password" />
              </FormGroup>
              <FormGroup>
                <label>Confirm Password</label>
                <FormControl value={this.state.pwdConfirm} onChange={(e) => this.setState({pwdConfirm: e.target.value})}  type="password" />
              </FormGroup>
              <Button
                onClick={this.handleSubmit.bind(this)} className="login__button button button--green button--w-full"
                disabled={this.state.submitting}
              >
                {
                  this.state.submitting ?
                    'Submitting...' :
                    'Submit'
                }
              </Button>
            </form>
            {
              success &&
              <a href="/members">Login to your account</a>
            }
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default connect(
  // mapStateToProps
  state => ({
  }),
  // mapDispatchToProps
  dispatch => ({
  })
)(ResetPassword);