import { compose, createStore, applyMiddleware, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { routerReducer } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form';
import { reducers as captureReducer } from 'Portal/containers/members/Captures/store';
import { reducers as groupCaptureReducer } from 'Portal/containers/members/Group/store';
import { reducers as userValidationsReducer } from 'Portal/containers/user_validations/store';
import { reducers as mapCaptureReducer } from 'Portal/containers/map_captures/store';
import { reducers as frogsReducer } from 'Portal/containers/frogs/store';
import { reducers as frogGroupsReducer } from 'Portal/containers/frog_groups/store';
import { reducers as groupsReducer } from 'Portal/containers/groups/store/index';
import { reducers as usergroupsReducer } from 'Portal/containers/groups/store/private';
import { reducers as leaderboardsReducer } from 'Portal/containers/leaderboards/store';
import { userReducer, initialState as userInitialState } from '../auth/userReducer.jsx';


// add pagination from the headers
import {defaultTransformResponsePipeline as reduxRestResourceTransformResponsePipeline} from 'redux-rest-resource';
Object.assign(reduxRestResourceTransformResponsePipeline, [
  res => res.json().then(body => (
    {
    body,
    // The backend provides the pagixnation values (page num, count, etc) as headers which are
    // eventually treated as text by the browser. To simplify usage, they should be coerced
    // back to numeric values
    total_pages: parseInt(res.headers.get('total_pages')),
    total_count: parseInt(res.headers.get('total_count')),
    rpp: parseInt(res.headers.get('rpp')),
    page: parseInt(res.headers.get('page')),
    code: res.status
  }))
]);

export default props => {

  // Redux expects to initialize the store using an Object, not an Immutable.Map
  const initialState = {
    user: userInitialState,
  };

  const reducer = combineReducers({
    form: formReducer,
    routing: routerReducer,
    user: userReducer,
    groups: groupsReducer,
    usergroups: usergroupsReducer,
    leaderboards: leaderboardsReducer,

    // Add all other reducers here
    mapCaptures: mapCaptureReducer,
    captures: captureReducer,
    groupCaptures: groupCaptureReducer,
    frogs: frogsReducer,
    frogGroups: frogGroupsReducer,
    userValidations: userValidationsReducer,

  });
  const composedStore = compose(
    //applyMiddleware(thunkMiddleware, loggerMiddleware),
    applyMiddleware(thunkMiddleware),

    // React devTools
    window.devToolsExtension ? window.devToolsExtension() : f => f
  );
  const storeCreator = composedStore(createStore);
  const store = storeCreator(reducer, initialState);

  return store;
};
