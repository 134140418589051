import 'jquery'
import 'babel-polyfill'

import ReactOnRails from 'react-on-rails';

import PortalApp from '../bundles/Portal/components/PortalApp';

// This is how react_on_rails can see the HelloWorld in the browser.
ReactOnRails.register({
  PortalApp,
});
