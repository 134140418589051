import React, { Component } from 'react'
import axios from 'axios';
import $ from 'jquery';
import AlertPopup from 'react-s-alert'
import { IndexLink, Link } from "react-router";
import connect, { Field, Resolve } from 'Portal/containers/form'
import { FormInput, FormImageUpload, FormAutocomplete } from 'Portal/components/FormInputs'
import { LoaderButton } from 'Portal/components/Loaders'
import ProfileSharing from 'Portal/components/ProfileSharing'
import { convertToHttps } from 'Admin/helpers'

class Edit extends Component {
  state = {
    deleteAccountAlertOpen: false,
  }

  openDeleteAccountAlert(e) {
    // don't submit the edit form
    e.preventDefault();
    console.log(this.state)
    this.setState({
      deleteAccountAlertOpen: true,
    })
  }

  closeAlert() {
    this.setState({
      deleteAccountAlertOpen: false,
    })
  }

  handleUpdate(e) {
    const { userActions, currentValues } = this.props
    const { first_name, last_name, email, avatar, private: private_profile, bam_opt_in, postcode, age_group, accepted_terms, opt_out_badge_email, unsubscribe_newsletter } = currentValues
    e.preventDefault()

    userActions.updateAccount({
      first_name,
      last_name,
      email,
      avatar,
      private: private_profile,
      bam_opt_in,
      postcode,
      age_group,
      accepted_terms,
      opt_out_badge_email,
      unsubscribe_newsletter
    }, () => {
      // Success
      AlertPopup.success('Saved changes')
    }, () => {
      // Failed
      AlertPopup.error('There was a problem while saving your changes. Please try again.')
    })
  }

  render() {
    const { user, currentValues } = this.props
    const { profile_url, display_name } = user

    return (
      <>
        <section className="wrapper">
          <div>
            <div className="profile-info profile-info--centred">
              {
                !_.isEmpty(currentValues.avatar) &&
                <img src={convertToHttps(currentValues.avatar)} className="profile-info__avatar" />
              }
              <h1 className='profile-info__name'>{user.first_name} {user.last_name}</h1>
              <label className="checkbox-label profile-info__privacy">
                <Field type="checkbox" name="private" component={FormInput} />
                Make profile private
              </label>
              {
                !currentValues.private &&
                <ProfileSharing profile_url={profile_url || "Click Save below to generate..."} display_name={display_name} />
              }
            </div>
          </div>
        </section>

        <section className="wrapper clear-both">
          <form onSubmit={this.handleUpdate.bind(this)} className="centre-column edit-form">


            <div className="edit-form__row edit-form__row--w-full">
              <label>Avatar upload</label>
              <Field name="avatar" component={FormImageUpload} />
            </div>
            <div className="edit-form__row edit-form__row--w-full">
              <label>First name</label>
              <Field name="first_name" component={FormInput} placeholder="First name" />
            </div>
            <div className="edit-form__row edit-form__row--w-full">
              <label>Last name</label>
              <Field name="last_name" component={FormInput} placeholder="Last name" />
            </div>
            <div className="edit-form__row edit-form__row--w-full">
              <label>Email</label>
              <Field name="email" component={FormInput} placeholder="Email" />
            </div>
            <div className="edit-form__row">
              <label>Age Group</label>
              <Field
                name="age_group"
                options={[{ label: 'Under 18', value: 'under_18' }, { label: '18 & over', value: 'over_18' }]}
                component={FormAutocomplete}
                className="dropdown"
              />
            </div>
            <div className="edit-form__row">
              <label>Postcode</label>
              <Field name="postcode" component={FormInput} />
            </div>
            <div className="edit-form__row edit-form__row--w-full">
              <label className="checkbox-label">
                <Field type="checkbox" name="bam_opt_in" component={FormInput} />
                Formal biodiversity survey opt-in< br />
                Selecting this checkbox allows accredited users to flag FrogID recordings as formal biodiversity surveys eg. NSW Government Biodiversity Assessment Method
              </label>
            </div>
            <div className="edit-form__row edit-form__row--w-full">
              <label className="checkbox-label">
                <Field type="checkbox" name="accepted_terms" component={FormInput} />
                I accept the&nbsp;<a target="_new" href="/terms">terms and conditions</a>&nbsp;to use this app.</label>
            </div>
            <div className="edit-form__row edit-form__row--w-full">
              <label className="checkbox-label">
                <Field type="checkbox" name="opt_out_badge_email" component={FormInput} />
                Opt-out of receiving emails regarding new badge awards.</label>
            </div>
            <div className="edit-form__row edit-form__row--w-full">
              <button onClick={this.handleUpdate.bind(this)} className="button button--green button--w-full">Save</button>
            </div>
            <div className="edit-form__row edit-form__row--w-full">
              <button onClick={this.openDeleteAccountAlert.bind(this)} className="button button--red button--w-full">Delete my account</button>
            </div>
          </form>
        </section>
        {/* Delete account alert (hidden by default) */}
        <div className={this.state.deleteAccountAlertOpen ? "alert--open" : "alert--closed"}>
          <div className="alert__background"></div>
          <div className="alert__dialog-box">
            <p>Would you like to delete your account? Clicking OK will remove all personal data associated with your FrogID account. This action is not reversible.</p>
            <button className="button button--red" onClick={this.handleDeleteAccount.bind(this)}>Yes</button>
            <button className="button button--green" onClick={() => this.closeAlert()}>Cancel</button>
          </div>
        </div>
      </>
    )
  }

  onUpload = (data) => {
    console.log(data)
  }

  handleDeleteAccount = () => {
    const headers = JSON.parse($.cookie('authHeaders'))
    axios.delete('/api/user/delete_account', { headers }).then(() => {
      AlertPopup.success('Successfully deleted your account.');
      setTimeout(() => {
        window.location.href = "/"
      }, 1000)
    })
  }
}


const form = {
  name: 'user',
  fields: [
    'first_name',
    'last_name',
    'age_group',
    'accepted_terms',
    'unsubscribe_newsletter',
    'postcode',
    'private',
    'bam_opt_in',
    'email',
    'avatar',
    'opt_out_badge_email',
  ],
  initialize: (state) => ({
    ...state.user.toJS().item || {}
  })
};

export default connect(Edit, form);
