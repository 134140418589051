import React, {Component} from 'react'
import Panel from 'Portal/components/Identify/Panel'

const answers = [
  {title: 'Species A'},
  {title: 'Species B'},
  {title: 'Species C'},
  {title: 'Species D'},
  {title: 'Species E'},
  {title: 'Species F'},
]

class IdentifyPractice extends Component {

  handleSubmit(){
    console.log('submitted')
  }

  render(){
    return (
      <Panel
        title="Identifier Practice Question"
        submitTitle="Submit answer"
        onSubmit={this.handleSubmit.bind(this)}
        answers={answers}
      />
    )
  }
}

export default IdentifyPractice