import React, { Component } from 'react'
import connect from 'Portal/containers/connect'
import { dateTimeToString } from 'Admin/components/captures/List'
import CallAudio from 'Portal/components/Captures/Audio'
import Footer from 'Portal/components/Footer'
import { string } from 'prop-types'
import { convertToHttps } from 'Admin/helpers'


class CapturesDetail extends Component {

  componentDidMount() {
    const captureId = this.props.params.id;
    window.smuCreateCaptureEmbed("#userRecordsEmbed", {
      captureId,
    });
    this.fetchData();
  }

  fetchData = () => {
    const { captureActions, params: { id } } = this.props;
    const { query } = location;

    // const query = {
    // show_archived: true
    // }
    captureActions.getCapture({ id })

    //     // gives
    //     {id: 331849, user_id: 105, user_email: 'jencork@gmail.com', state: 'NSW', lat: -33.6826366978081, …}
    // archived: false
    // call_audio: "http://res.cloudinary.com/ausmus/video/upload/v1637831078/amkkcmxooqb10buniua1.aac"
    // capture_time: "2021-11-25T20:04:20.346+11:00"
    // filtered_frog_ids: (33) [37, 41, 54, 59, 63, 64, 67, 69, 74, 84, 88, 100, 101, 102, 107, 110, 112, 124, 133, 135, 149, 151, 154, 157, 160, 171, 179, 186, 188, 189, 221, 232, 249]
    // filters: {habitat: '0', water_body: '0'}
    // filters_readable: {habitat: 'None selected', water_body: 'None selected'}
    // frog_group: null
    // id: 331849
    // images: []
    // is_bam: false
    // lat: -33.6826366978081
    // lng: 150.558171663574
    // location_accuracy: 83.5204995809013
    // matched_frog_ids: [133]
    // not_a_frog_type: null
    // note: ""
    // null_record: false
    // num_matches: 1
    // state: "NSW"
    // updated_at: "2021-12-14T16:34:18.099+11:00"
    // user_email: "jencork@gmail.com"
    // user_id: 105
    // validated_frog_ids: [133]
    // validated_frog_names: ['Litoria peronii']
    // validated_frog_common_names: ["common froglet"]
    // validated_status: "published"
    // validated_type: "frogs"
    // validator_comment: "Thank you for your submission! We are hoping to get a better understanding of exactly when different frog species are calling and how that changes over time and with weather, so please do record often (once a night or once a week or as often as you can!), even if it’s the same frogs calling at the same place. Every recording helps us better understand and conserve frogs - thank you!"
    // verified_as_frog: true
    // verified_frog_ids: [133]
    //   }

    // and user:

    // accepted_terms: true
    // age_group: "over_18"
    // allow_password_change: false
    // app_using_time: 0
    // avatar: "http://res.cloudinary.com/ausmus/image/upload/v1633598196/iwivas5do8ou0licqtpf.jpg"
    // bam_opt_in: false
    // capture_states: []
    // captured_frog_count: 149
    // captures_count: 149
    // community_level: null
    // confirm_token: null
    // confirmed_email: true
    // created_at: "2017-08-02T15:18:19.207+10:00"
    // email: "jencork@gmail.com"
    // first_name: "Jen"
    // frog_species_count: 11
    // group_id: 153
    // id: 105
    // last_name: "Cork"
    // location: null
    // nickname: null
    // owned_group_id: 153
    // passed_test: true
    // pending_captures_count: 0
    // postcode: "2776"
    // private: false
    // provider: "email"
    // published_captures_count: 59
    // roles_names: []
    // steps_taken: 0
    // uid: "jencork@gmail.com"
    // unsubscribe_newsletter: false
    // updated_at: "2022-06-06T16:41:47.495+10:00"
    // validated_captures_count: 90
    // verification_status: false

  }

  render() {

    const { capture, user } = this.props;
    const full_name = user.first_name + " " + user.last_name;

    const removeUnderscore = (str) => {
      if (!str) return "";
      return str.replace(/_/g, " ");
    }

    const capitaliseFirstLetter = (str) => {
      if (!str) return "";
      return str.charAt(0).toUpperCase() + str.slice(1);
    }

    return (
      <>
        <div className="wrapper">
          <a href="/members/captures" className="return-link">← My Captures</a>

          <section className="capture-detail__section capture-detail__section--pt-0">
            <p>Capture</p>
            <h1 className="page-heading page-heading--m-0">{capture.id}</h1>
            <p className="capture-detail__author"><img src={convertToHttps(user.avatar)} alt="" className="capture-detail__avatar" /> Captured by <a href="/members/profile">{full_name}</a> on <time>{dateTimeToString(capture.capture_time)}</time></p>
            <p><svg xmlns="http://www.w3.org/2000/svg" width="14.311" height="14.309" viewBox="0 0 14.311 14.309" className="captures-table__verification-icon">
              <path id="Path_58" data-name="Path 58" d="M112.234,36.043a2.385,2.385,0,0,1,0-1.812l.08-.191a2.391,2.391,0,0,0-1.287-3.115l-.175-.079a2.327,2.327,0,0,1-1.287-1.287L109.5,29.4a2.423,2.423,0,0,0-3.131-1.287l-.159.064a2.425,2.425,0,0,1-1.828,0l-.143-.064a2.4,2.4,0,0,0-3.1,1.3l-.064.127a2.327,2.327,0,0,1-1.287,1.287l-.143.064a2.4,2.4,0,0,0-1.272,3.116l.064.143a2.385,2.385,0,0,1,0,1.812l-.064.175a2.355,2.355,0,0,0,1.287,3.1l.159.064a2.327,2.327,0,0,1,1.287,1.287l.079.175a2.375,2.375,0,0,0,3.1,1.3l.175-.08a2.425,2.425,0,0,1,1.828,0l.143.064a2.391,2.391,0,0,0,3.115-1.287l.064-.127a2.327,2.327,0,0,1,1.287-1.287l.127-.048a2.372,2.372,0,0,0,1.287-3.115Zm-7.566,2.146-3.1-2.607.954-1.129,1.971,1.669,3.481-4.133,1.129.954Z" transform="translate(-98.188 -27.935)" />
            </svg>
              {capitaliseFirstLetter(capture.validated_status)} – {removeUnderscore(capture.validated_type)}</p>
          </section>

          {capture.validated_type === 'frogs' &&
            <section className="capture-detail__section">
              <ul className="capture-detail__frog-species-list">
                {capture.validated_frogs && _.times(capture.validated_frogs.length, idx => (
                  <li className="capture-detail__frog-species-item" key={idx}>
                    <img src={convertToHttps(capture.validated_frogs[idx].cover_photo)} alt={capture.validated_frogs[idx].scientific_name} className="image" />
                    <div>
                      <a href={'/frogs/' + capture.validated_frogs[idx].friendly_id} className="capture-detail__frog-scientific-name">{capture.validated_frogs[idx].scientific_name}</a>
                      <p>{capture.validated_frogs[idx].common_names}</p>
                    </div>
                  </li>
                ))}
              </ul>
            </section>
          }



          <section className="capture-detail__section">
            <div className="capture-detail__audio-and-map">
              <CallAudio id={capture.id} call_audio={capture.call_audio} user_full_name={user.full_name} />
              <div className={ capture.validated_type === "frogs" ? "capture-detail__map-container" : "hidden" }>
                <div className="embed" id="userRecordsEmbed"></div>
                <p>{capture.state}, Lat {parseFloat(capture.lat).toFixed(3)}, Lng: {parseFloat(capture.lng).toFixed(3)}</p>
              </div>
            </div>
            {
              capture.images &&
              <div className="capture-detail__gallery">
                {_.times(capture.images.length, idx => (
                  <img src={convertToHttps(capture.images[idx].card)} alt="" className="image" />
                ))}
              </div>
            }
          </section>

          {(capture.note || capture.validator_comment) &&
            <section className="capture-detail__section">
              {
                capture.note &&
                <div className="capture-detail__notes"><h3>Notes</h3> <p>{capture.note}</p></div>
              }
              {
                capture.validator_comment &&
                <div className="capture-detail__notes">
                  <h3>Validator comments</h3>
                  <p>{capture.validator_comment}</p>
                </div>
              }
            </section>
          }
        </div>
        <Footer />
      </>
    )
  }
}

export default connect(CapturesDetail)
