import React, { Component } from 'react';
import { FormControl } from 'react-bootstrap';

import Select from 'react-select';
import 'react-select/dist/react-select.css';
import Uploader from 'Portal/components/uploaders/Uploader';

export const FormInputUnstyled = props => <input {...props.input} {...props} />;
export const FormInput = props => <FormControl {...props.input} {...props} />;
export const FormTextareaAutoSize = props => <Textarea {...props.input} {...props} />;
export const FormQuill = props => <ReactQuill id={props.name} theme="snow" {...props.input} {...props} />;

export const FormSelect = props => {
  const { onChange, value } = props.input;

  return (
    <FormControl onChange={onChange} value={value} componentClass="select">
      {
        props.options.map((field, idx) => (
          <option defaultValue={field.selected} disabled={field.disabled} key={idx} value={field.value}>{field.label}</option>
        ))
      }
    </FormControl>
  )
};

export const FormAutocomplete = props => {
  const {onChange, value} = props.input;
  const {options, onSelected, isLoading, placeholder} = props;

  return <Select
    placeholder={placeholder}
    options={options}
    isLoading={isLoading}
    onChange={(obj) => {
      let val = _.get(obj, 'value', null)
      onChange(val);
      onSelected && onSelected(val);
    }}
    value={value}
  />
}

export const FormImageUpload = (props) => {

  const { name, multiple } = props
  const { onChange, value } = props.input

  return (
    <div>
      <Uploader
        help="PNG or JPG files less than 2MB"
        tags={['images', name]}
        multiple={multiple}
        type="image"
        onUpload={(data) => {
          onChange(data.file)
          props.onUpload && props.onUpload(data)
        }}
      />
    </div>
  )
};