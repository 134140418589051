import React, {Component} from 'react'

class Panel extends Component {
  render(){
    const { title, answers, submitTitle, onSubmit } = this.props

    return (
      <div className="panel panel-validate">
        <div className="panel-header">
          <div>{title}</div>
          <div className="actions">
            <a onClick={onSubmit} className="btn-secondary">{submitTitle}</a>
          </div>
        </div>
        <div className="panel-body">
          <div className="hint">Select the correct frog species in the frog call below.</div>
          <div className="validate-header">
            <a className="audio">
              <i className="fa fa-play" />
            </a>
            <div className="question">What frog species can you identify in this call?</div>
          </div>
          <div className="validate-answers">
            {
              answers.map((answer, idx) => (
                <div key={idx} className="answer">
                  <label>
                    <input name="answer" type="radio" />
                    <a className="audio">
                      <i className="fa fa-play" />
                    </a> {answer.title}
                  </label>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    )
  }
}

export default Panel