import Auth from 'j-toker';

const configureAuth = () => {
  Auth.configure({
    apiUrl: window.location.origin+'/user',
    passwordResetSuccessUrl: function(){
      return window.location.origin+'/reset-password';
    },
  });
};

export default configureAuth