import React from 'react'
import Sound, { toWaveForm } from 'shared/components/captures/sound'

class IdentifyFrog extends React.Component {
  state = {
    audioIdx: 0,
  }

  componentDidMount(){
    const {
      frog: { audios = []}
    } = this.props
    const { audioIdx } = this.state

    this.setState({
      audio: _.get(audios, `[${audioIdx}].url`)
    })
  }

  render() {
    const { frog: {
      id, selected, selectable, audios = [], scientific_name, common_names
    }, onChange } = this.props
    const { audioIdx } = this.state
    const audio = getAudio(audios, audioIdx)

    return (
      <div className={`Frog ${selected && 'Frog-Selected'}`}>
        <label>
          <div className="Frog-Selection">
            {
              selectable && <input onChange={onChange} checked={selected} type="checkbox" />
            }
            <div className="Frog-detail">
              <div className="sci-name">{scientific_name}</div>
              <div className="common-name">{common_names.join(', ')}</div>
            </div>
          </div>
        </label>
        <div className="Frog-Audio-Select">
          <i className="fa fa-volume-up" />
          {
            audios.map((audio, idx) => (
              <a
                onClick={() => this.setState({audioIdx: idx})}
                key={audio.id}
                className={idx === audioIdx ? 'active' : ''}
              >{idx+1}</a>
            ))
          }
        </div>
        {
          audio &&
          <div className="Frog-Audio">
            <div className="Sound">
              <Sound url={audio.url} />
            </div>
            <div className="waveform">
              <img src={toWaveForm(audio.url, 100, 30)} />
            </div>
          </div>
        }
      </div>
    )
  }
}

const getAudio = (audios = [], idx) => {
  return audios[idx]
}

export default IdentifyFrog