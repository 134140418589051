import React from 'react'
import { Link } from 'react-router'
import connect from 'Portal/containers/connect'
import Navigation from 'Portal/components/Navigation.jsx'
import Alert from 'Portal/components/Alert'
import { LoaderButton, LoaderPage } from 'Portal/components/Loaders'
import Footer from 'Portal/components/Footer'

class Login extends React.Component {

  state = {
    email: '',
    password: '',
    showPassword: false,
  }

  handleLogin(e) {
    const { userActions } = this.props
    const { email, password } = this.state

    e.preventDefault()
    userActions.signIn(email, password, (res) => {
      if ( res.confirmed_email ){
        window.location = '/members'
      } else {
        window.location = '/verify-email'
      };
    })
  }

  render() {
    const { email, password, showPassword } = this.state
    const { userState } = this.props
    const { isFetching } = userState

    return (
      <div className="app-portal">
        <Navigation />
        <div className="wrapper">
          <h1 className="login__header">Log in to your account</h1>
          <div className="login__container">
            <LoaderPage loading={isFetching}>
              <form className="login__form">
                <Alert type="warning" full_messages={userState.full_messages} />
                <div className="form-group">
                  <label>Email</label>
                  <input autoFocus type="email" value={email} onChange={(e) => this.setState({ email: e.target.value })} className="form-control" />
                </div>
                <div className="form-group">
                  <label>Password</label>
                  <input type={showPassword ? 'text' : 'password'} value={password} onChange={(e) => this.setState({ password: e.target.value })} className="form-control" />
                </div>
                <div className='show_password'>
                  <div
                    onClick={() =>
                      this.setState((prevState) => ({
                        showPassword: !prevState.showPassword,
                      }))
                    }
                  >
                    <i className={showPassword ? 'fa fa-check' : ''} />
                  </div>
                  <span className="">Show Password</span>
                </div>
                <LoaderButton loading={isFetching} onClick={this.handleLogin.bind(this)} className="login__button button button--green button--w-full">Login</LoaderButton>
              </form>
            </LoaderPage>
            <Link to="/signup">Need an account?</Link><br />
            <a href="/forgot-password">Forgot your password?</a>
          </div>
        </div>
        <Footer />
      </div>
    );

  }
}

export default connect(Login)
