import React, {Component} from 'react'
import { withGoogleMap, GoogleMap, Circle } from 'react-google-maps'
import MarkerClusterer from 'react-google-maps/lib/addons/MarkerClusterer'
import reactCSS from 'reactcss'
import moment from 'moment'

const styles = reactCSS({
  'default': {
    container: {
      width: '100%',
      height: '700px',
      background: '#efefef',
    },
  },
})

const dateFormat = 'DD-MM-YYYY'
const dateToMoment = (date) => moment(date, dateFormat)

const GoogleMapHOC = withGoogleMap(props => (
  <GoogleMap
    ref={props.onMapLoad}
    defaultZoom={7}
    defaultCenter={{ lat: -33.8688, lng: 150.2093 }}
    options={{
      maxZoom: 13,
      scrollwheel: false,
      navigationControl: false,
      streetViewControl: false,
      fullscreenControl: false,
    }}
    onClick={props.onMapClick}
  >
    {props.children}
  </GoogleMap>
))

class Map extends Component {

  render(){
    const { markers } = this.props
    const color = '#d50000'

    return (
      <div className="captures-map">
        <div style={styles.container}>
          <GoogleMapHOC
            containerElement={
              <div style={{ height: `100%` }} />
            }
            mapElement={
              <div style={{ height: `100%` }} />
            }
            onMapLoad={_.noop}
            onMapClick={_.noop}
          >
            {
              markers.map((marker, index) => {
                const { lat, lng, radius } = marker.position

                return (
                  (lat && lng) ?
                    <Circle
                      key={marker.key}
                      center={marker.position}
                      radius={radius}
                      options={{
                        fillColor: color,
                        fillOpacity: 0.35,
                        strokeColor: color,
                        strokeOpacity: 1.0,
                        strokeWeight: 1.0,
                      }}
                      onRightClick={_.noop}
                    /> : null
                )
              })
            }
          </GoogleMapHOC>
        </div>
      </div>
    )
  }
}

export default Map