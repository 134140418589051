import React, {Component} from 'react'
import { Avatar } from './TopGroupsList'
import connect from '../connect'
import { convertToHttps } from 'Admin/helpers'

class TopGroups extends Component {
  componentDidMount(){
    const { leaderboardActions } = this.props
    leaderboardActions.fetchLeaderboards()
  }

  render() {
    const { leaderboard } = this.props
    const users = _.reverse(_.orderBy(_.values(leaderboard.users), 'published_captures_count'))

    return (
      <div className="users-top">
        <table className="table table-responsive">
          <thead>
            <tr>
              <th width="150" colSpan="2">Rank</th>
              <th>Name</th>
              <th>Group</th>
              <th width="180">Captures Count</th>
            </tr>
          </thead>
          <tbody>
            {
              users.map((user, idx) => (
                <tr key={user.id}>
                  <td className="text-center">{idx+1}</td>
                  <td>
                    <Avatar className="avatar" src={convertToHttps(user.avatar)} />
                  </td>
                  <td>{user.public_name}</td>
                  <td>{user.group_name}</td>
                  <td className="text-center">{user.published_captures_count}</td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    )
  }
}

export default connect(TopGroups)