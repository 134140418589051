import React, { Component } from "react";
import Content from './TermsContent';

export default class TermsApp extends Component {
  render() {
    return (
      <div className="app-portal static-page terms">
        <div className="container">
          <div className="section hor">
            <div className="detail">
              <div className="desc">
                <Content />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
