import hostUrl from 'Portal/constants/api.jsx';
import { createResource, mergeReducers } from 'redux-rest-resource';

const resource = createResource({
  name: 'leaderboard',
  url: `${hostUrl}/leaderboards/:id`
});

const types = {...resource.types};
const actions = {...resource.actions};
const reducers = mergeReducers(resource.reducers, {});
export {types, actions, reducers};