import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Navigation from "Portal/components/Navigation";
import Footer from "Portal/components/Footer";
import { Avatar } from "Portal/containers/leaderboards/TopGroupsList";
import { actions as leaderboardActions } from "Portal/containers/leaderboards/store";
import { convertToHttps } from 'Admin/helpers'

class FrogIDWeek extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { leaderboardActions } = this.props;
    leaderboardActions.getLeaderboard("frogid_week");
  }

  render() {
    const { loading, stats, top_users, top_groups } = this.props;

    return (
      <div className="app-portal frog-count frogid-week">
        <Navigation />
        <div className="section">
          <div className="container">
            <div className="section-inner">
              <div className="detail hor text-center">
                <h2>What is FrogID week?</h2>
                <br />
                <p><strong>FrogID Week is Australia’s Biggest Frog Count</strong>, held annually for Australians to help record frog calls as a measurement of frog health and distribution around the nation.</p>
                <br />
                <p>It aims to <strong>monitor frog distributions over time</strong>, helping us to understand how frogs and their ecosystems are responding to a changing planet.</p>
                <br />
              </div>
            </div>
          </div>
        </div>

        <div className="section section-gray">
          <div className="section-inner">
            <div className="detail hor text-center">
              <h2>FrogID Week 2019 highlights</h2>
              <p>Nov 8, 2019 - Nov 17, 2019</p>
              {loading ? (
                <div>
                  <br />
                  <br />
                  <p>Loading data...</p>
                </div>
              ) : (
                <div className="groups-top">
                  <div className="group">
                    <div className="title">Calls Submitted</div>
                    <div className="count">{stats.captures_count}</div>
                  </div>
                  <div className="group">
                    <div className="title">Verified Frogs</div>
                    <div className="count">{stats.published_frogs_count}</div>
                  </div>
                  <div className="group">
                    <div className="title">Species Found</div>
                    <div className="count">
                      {stats.published_species_count}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="container">
          <div className="section">
            <div className="section-inner">
              <div className="detail hor text-center">
                <h2>Top groups during FrogID Week</h2>
                <p>by number of verified frogs</p>
                <div className="users-top">
                  <table className="table table-responsive">
                    <thead>
                      <tr>
                        <th width="150" colSpan="2">
                          Rank
                        </th>
                        <th>Name</th>
                        <th width="180">Captures Count</th>
                      </tr>
                    </thead>
                    <tbody>
                      {top_groups.map((user, idx) => (
                        <tr>
                          <td className="text-center">{idx + 1}</td>
                          <td>
                            <Avatar className="avatar" src={convertToHttps(user.avatar)} />
                          </td>
                          <td>{user.name}</td>
                          <td className="text-center">{user.count}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="section">
            <div className="section-inner">
              <div className="detail hor text-center">
                <h2>Top froggers during FrogID Week</h2>
                <p>by number of verified frogs</p>
                <div className="users-top">
                  <table className="table table-responsive">
                    <thead>
                      <tr>
                        <th width="150" colSpan="2">
                          Rank
                        </th>
                        <th>Name</th>
                        <th width="180">Captures Count</th>
                      </tr>
                    </thead>
                    <tbody>
                      {top_users.map((user, idx) => (
                        <tr>
                          <td className="text-center">{idx + 1}</td>
                          <td>
                            <Avatar className="avatar" src={convertToHttps(user.avatar)} />
                          </td>
                          <td>{user.name}</td>
                          <td className="text-center">{user.count}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section section-gray">
          <div className="container">
            <div className="Video">
              <div className="embed-container">
                <iframe
                  src="https://www.youtube.com/embed/aBoHdAhJm0I"
                  frameborder="0"
                  allowfullscreen
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="section">
            <div className="section-inner">
              <div className="detail hor text-center">
                <h2><strong>FrogID Week 2018</strong> results</h2>
                <img className="img-responsive" src="https://res.cloudinary.com/ausmus/image/upload/c_scale,w_2000/v1570670841/Website/FrogID_Website.jpg" />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default connect(
  state => {
    const statsState = state.leaderboards;
    const stats = statsState.item || {};

    return {
      loading: statsState.isFetchingItem,
      stats,
      top_users: stats.top_users,
      top_groups: stats.top_groups
    };
  },
  dispatch => {
    return {
      leaderboardActions: bindActionCreators(
        { ...leaderboardActions },
        dispatch
      )
    };
  }
)(FrogIDWeek);

const StatCount = ({ loading, count }) => (
  <div className="StatCount">{loading ? "..." : count}</div>
);

FrogIDWeek.propTypes = {};

FrogIDWeek.defaultProps = {
  top_users: [],
  top_groups: []
};
