import React from 'react';
import { Provider } from 'react-redux';
import { Router, browserHistory } from 'react-router';

import createStore from '../store/portalStore.jsx';
import Routes from '../routes/routes.jsx';
import { syncHistoryWithStore } from 'react-router-redux';

// See documentation for https://github.com/reactjs/react-redux.
// This is how you get props from the Rails view into the redux store.
// This code here binds your smart component to the redux store.
export default (props, location) => {
  const store = createStore(props);

  // Create an enhanced history that syncs navigation events with the store
  const history = syncHistoryWithStore(
    browserHistory,
    store
  );

  history.listen(location => {
    setTimeout(() => {
      if (location.action === 'POP') {
        return;
      }
      window.scrollTo(0, 0);
    });
  });

  const handleUpdate = () => {
    window.ga && window.ga('send', 'pageview')
  }

  const reactComponent = (
    <Provider store={store}>
      <Router onUpdate={handleUpdate} history={history} children={Routes} />
    </Provider>
  );
  return () => reactComponent;
};