import React from 'react';
import { IndexLink, Link } from 'react-router';

export default class Footer extends React.Component {

  constructor(props, context) {
    super(props, context);
  }

  componentWillMount() {
  }

  render() {
    return (
      <footer className="footer">
        <div className="wrapper footer-inner">
          <div className="footer-inner--left">
            <nav className="footer-nav">
              <ul className="footer-nav__links-list">
                <li className="footer-nav__links-list-item">
                  <h3>What is FrogID</h3>
                  <ul>
                    <li><a href="/about-frogid">About Us</a></li>
                    <li><a href="/science">FrogID Science</a></li>
                    <li><a href="/schools">FrogID for Schools</a></li>
                    <li><a href="/partners">Our partners</a></li>
                  </ul>
                </li>
                <li className="footer-nav__links-list-item">
                  <h3>Participate</h3>
                  <ul>
                    <li><a href="/get-involved">Get involved</a></li>
                    <li><a href="/explore">Explore FrogID Records</a></li>
                    <li><a href="https://res.cloudinary.com/ausmus/image/upload/v1635889297/FrogID-HowToGuide-2021_tg5het.pdf">Detailed guide</a></li>
                    <li><a href="/terms">Terms of Use</a></li>
                    <li><a href="/privacy">Privacy Policy</a></li>
                  </ul>
                </li>
                <li className="footer-nav__links-list-item">
                  <h3>Contact us</h3>
                  <ul>
                    <li><a href="/faq">Frequently Asked Questions</a></li>
                    <li><a href="mailto:calls@frogid.net.au ">General Enquiries</a></li>
                    <li><a href="mailto:research@frogid.net.au">Research Enquiries</a></li>
                    <li><a href="mailto:events@frogid.net.au">Event Enquiries</a></li>
                    <li><a href="mailto:media@frogid.net.au">Media Enquiries</a></li>
                  </ul>
                </li>
              </ul>
            </nav>
            <div className="footer-external-links">
              <a href="https://www.facebook.com/FrogIDAus"><i className="fa fa-facebook-official footer-social-media-icon" /></a>
              <a href="https://twitter.com/FrogIDAus"><i className="fa fa-twitter footer-social-media-icon" /></a>
              <a href="https://instagram.com/FrogIDAus"><i className="fa fa-instagram footer-social-media-icon" /></a>
            </div>
          </div>
          <a href="https://australian.museum/" className="footer-external-links">
            <img className="footer-logo" src="/images/am_logo-mono.png" />
          </a>
        </div>
      </footer >
    );

  }
}
