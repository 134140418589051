import React, { Component } from 'react'
import connect from 'Portal/containers/connect'
import { BADGE_ORDERING } from 'Portal/helpers'
import ProfileSharing from 'Portal/components/ProfileSharing'
import Footer from 'Portal/components/Footer'

class ProfileBadges extends Component {

  percentageFromStats = (user_count, total_users) => {
    const statPercentage = user_count / total_users * 100;
    return (statPercentage < 0.01 && statPercentage > 0)
            ? '< 0.01'
            : Math.round(statPercentage * 100) / 100;
  }

  render() {
    const { user } = this.props
    const { badge_statistics, captures_count, captured_frog_count, frog_species_count, published_captures_count } = user
    const percentageFromStats = this.percentageFromStats;

    return (
      <>
        <div className="wrapper">
          <div className="badges__intro">
            <h1 className="page-heading">My Badges</h1>
            {user.profile_url &&
              <div className="profile-info__sharing">
                <ProfileSharing profile_url={user.profile_url} display_name={user.display_name} />
              </div>
            }
            <ul>
              <li>Submitted captures: <strong>{captures_count}</strong></li>
              <li>Published frog captures: <strong>{published_captures_count}</strong></li>
              <li>Verified frogs: <strong>{captured_frog_count}</strong></li>
              <li>Species found: <strong>{frog_species_count}</strong></li>
            </ul>
          </div>

          <table className="badges-table">
            <thead>
              <tr className="badges-table__row">
                <th colSpan="2" className="badges-table__cell">Badge and description</th>
                <th className="badges-table__cell badges-table__cell--text-right">% of users who have badge</th>
              </tr>
            </thead>

            <tbody>
              {BADGE_ORDERING.map(function (badge_name, i) {
                return (
                  <tr className="badges-table__row">
                    <td className="badges-table__cell badges-table__cell--w-2/12">
                      <img src={
                        "/images/badges/FrogID-badge-" +
                        // add grey- if the person doesn't have the badge
                        (!badge_statistics.user_badges.includes(badge_name) ? "grey-" : "") +
                        // add new- if the person recently received the badge
                        (badge_statistics.user_recent_badges.includes(badge_name) ? "new-" : "") +
                        badge_statistics['badge_details'][badge_name]['image_suffix'] +
                        ".png"
                      }
                        alt={badge_name + " badge"} className={"image" + (badge_statistics.user_badges.includes(badge_name) ? " awarded" : "") + " image--mb-0"} />
                    </td>
                    <td className="badges-table__cell badges-table__cell--w-7/12">
                      <dl>
                        <dt className="badge-term">{badge_name}</dt>
                        <dd className="badge-definition">{badge_statistics['badge_details'][badge_name]['description']}</dd>
                      </dl>
                    </td>
                    <td className="badges-table__cell badges-table__cell--text-right badge-table__percentage">{percentageFromStats(badge_statistics['badge_details'][badge_name]['num_awarded'], badge_statistics["total_users"])}%</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
        <Footer />
      </>
    )
  }
}

export default connect(ProfileBadges)
