import React, {Component} from 'react'
import { LoaderPage } from 'Portal/components/Loaders'
import List from 'Portal/components/Frogs/List'
import { Link } from 'react-router'

import connect from '../connect'

const searches = [
  { label: 'Scientific/Common name', key: 'name' },
]

const dropdowns = [
  { label: 'Habitat', key: 'habitat' },
  { label: 'State', key: 'state' },
]

class FrogSearch extends Component {
  state = {
    name: '',
    state: '',
    habitat: '',
    limit: 3,
  }

  componentDidMount(){
    const { frogActions } = this.props

    frogActions.fetchFrogs('list')
  }

  onSearch = (field, e) => {
    const { value } = e.target

    this.setState({
      [field]: value
    })
  }

  loadMore = () => {
    const { limit } = this.state

    this.setState({
      limit: limit + 9
    })

  }

  render(){

    const { frogs, frogsState } = this.props
    const { name, habitat, state, limit } = this.state

    const options = {
      habitat: _.map(_.uniq(_.flattenDeep(_.map(frogs, 'habitats'))), (habitat) => _.capitalize(habitat)),
      state: _.uniq(_.flattenDeep(_.map(frogs, 'states')))
    }

    const toLower = (collection) => _.map(collection, (item) => _.lowerCase(item))

    let filtered = frogs

    if(name.length > 0){
      filtered = _.filter(frogs, (frog) =>
        _.includes(_.lowerCase(frog.scientific_name), _.lowerCase(name)) ||
        _.some(toLower(frog.common_names), _.method('includes', _.lowerCase(name)))
      )
    }

    if(habitat.length > 0){
      filtered = _.filter(filtered, (frog) =>
        _.some(toLower(frog.habitats), _.method('includes', _.lowerCase(habitat)))
      )
    }

    if(state.length > 0){
      filtered = _.filter(filtered, (frog) =>
        _.some(toLower(frog.states), _.method('includes', _.lowerCase(state)))
      )
    }

    const sorted = _.sortBy(filtered, 'scientific_name')

    const collection = _.take(sorted, limit)

    return (
      <div className="container">
        <div className="filter panel">
          {
            searches.map((filter, idx) => (
              <div key={idx} className="filter-search">
                <div className="title">Search by {filter.key}:</div>
                <div>
                  <input onChange={this.onSearch.bind(this, filter.key)} placeholder={filter.label} className="form-control" />
                </div>
              </div>
            ))
          }
          {
            dropdowns.map((filter, idx) => (
              <div key={idx} className="filter-search">
                <div className="title">Filter by {filter.key}:</div>
                <div>
                  <select onChange={this.onSearch.bind(this, filter.key)} placeholder={filter.label} className="form-control">
                    <option value="">-</option>
                    {
                      options[filter.key].map((option, idx) => (
                        <option key={idx} value={option}>{option}</option>
                      ))
                    }
                  </select>
                </div>
              </div>
            ))
          }
        </div>

        <div className="search-result">
          <LoaderPage loading={frogsState.isFetching}>
            <div className="text-center">
              Found <strong>{sorted.length}</strong> frogs matching your query
            </div>

            <List frogs={collection} />

            {
              limit < sorted.length &&
              <div className="text-center">
                <a onClick={this.loadMore} className="load-more text-center">Load more +</a>
              </div>
            }
          </LoaderPage>
        </div>


      </div>
    )
  }
}

export default connect(FrogSearch)