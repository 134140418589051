import React, {Component} from 'react'
import connect from '../connect'
import { convertToHttps } from 'Admin/helpers'

export const normalizedGroups = (groups) => {
  return _.reverse(_.orderBy(groups, 'published_captures_count'))
}

class TopGroups extends Component {
  componentDidMount(){
    const { leaderboardActions } = this.props
    leaderboardActions.fetchLeaderboards({}, {
      query: {
        rpp: 5,
      }
    })
  }

  render() {
    const { leaderboard, leaderboardsState: {isFetching} } = this.props
    const schools = normalizedGroups(leaderboard.schools)

    return (
      <div>
        <div className="users-top">
          <table className="table table-responsive">
            <thead>
            <tr>
              <th width="150" colSpan="2">Rank</th>
              <th>Name</th>
              <th width="180">Captures Count</th>
            </tr>
            </thead>
            <tbody>
            {
              schools.map((group, idx) => (
                <tr key={group.id}>
                  <td className="text-center">{idx+1}</td>
                  <td>
                    <Avatar className="avatar" src={convertToHttps(group.avatar)} />
                  </td>
                  <td>{group.name}</td>
                  <td className="text-center">{group.published_captures_count}</td>
                </tr>
              ))
            }
            </tbody>
          </table>
        </div>

        <div>
          {
            isFetching ?
              <button disabled className="btn btn-primary">Loading...</button> :
              <button onClick={this.handleShowAll} className="btn btn-primary">See all School Groups/Classes</button>
          }
        </div>
      </div>
    )
  }

  handleShowAll = () => {
    const { leaderboardActions } = this.props
    leaderboardActions.fetchLeaderboards()
  }
}

export const Avatar = props => {
  const size = '60px'

  return (
    props.src ?
      <img {...props} /> :
      <div style={{
        width: size,
        height: size,
        borderRadius: size,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#efefef',
      }}>
        <i style={{
          color: '#d8d8d8',
          fontSize: '30px',
        }} className="fa fa-user" />
      </div>
  )
}

export default connect(TopGroups)