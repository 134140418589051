import React, {Component} from 'react'
import Alert from 'react-s-alert'

import GroupFields from 'Admin/containers/groups/_Fields'
import { LoaderButton } from 'Portal/components/Loaders'
import connect, { Field, Resolve } from 'Portal/containers/form'
import { browserHistory } from 'react-router';
import { convertToHttps } from 'Admin/helpers'

class GroupEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      full_messages: [],
    };
  }

  componentDidMount(){

    const { usergroupActions, user, params: { slug } } = this.props

    usergroupActions.getUsergroup({ slug })
  }

  render() {
    const { currentValues: { is_school }, usergroupsState: {isUpdating}, usergroup: group} = this.props
    const { full_messages } = this.state
    return (
      <div className="wrapper">
        {
          !_.isEmpty(full_messages) &&
          <div className="alert alert-warning">
            {
              full_messages.map((message, index) => (
                <p key={index}>{message}</p>
              ))
            }
          </div>
        }
        <div className="profile-info profile-info--centred">
          {
            group.avatar &&
            <img src={convertToHttps(group.avatar)} className="profile-info__avatar" />
          }
          <h1 className='profile-info__name'>{group.name}</h1>
        </div>

        <GroupFields isSchool={is_school} />
        <div className="centre-column">
          <LoaderButton onClick={this.onSubmit} loading={isUpdating} className="button button--green button--w-full" type="submit">Update Group</LoaderButton>
        </div>
        <br/>
      </div>
    )
  }

  onSubmit = () => {
    const { usergroup: group, usergroupActions, currentValues } = this.props

    usergroupActions.updateUsergroup({
      slug: group.slug,
      ...currentValues
    }).then(({body: group}) => {
      browserHistory.push("/members/group/profile/" + group.slug);
    }).catch((error) => {
      this.setState({full_messages: [error.body.message]})
    })
  }
}

const form = {
  name: 'group_edit',
  fields: [
    'name',
    'avatar',
    'desc',
    'postcode',
    'is_school',
    'school_name',
    'private',
  ],
  initialize: (state) => ({
    ...state.usergroups.item || {}
  })
};

export default connect(GroupEdit, form);
