import React, { Component } from 'react'
import AlertPopup from 'react-s-alert'
import { Link, browserHistory } from 'react-router'

import { LoaderPage } from 'Portal/components/Loaders'
import connect, { Field, Resolve } from 'Portal/containers/form'
import { FormInput, FormImageUpload, FormAutocomplete } from 'Portal/components/FormInputs'
import GroupCard from 'Portal/components/Group/Card'

class GroupShow extends Component {

  componentDidMount() {
    const { groupActions, user: { group_id } } = this.props

    groupActions.fetchGroups()

    if (group_id) {
      groupActions.getGroup({
        id: group_id
      })
    }
  }

  render() {
    const { currentValues: group_id, groups, group: { users = [] }, groupsState, user } = this.props
    const group_options = _.map(groups, (group) => ({ label: group.name, value: group.id }))
    const loading = groupsState.isFetching
    const selected_group = !_.isNull(group_id) && !_.isUndefined(group_id)
    const group = findGroup(user.group_id, groups)
    const isOwner = group.owner_id === user.id

    return (
      <LoaderPage loading={loading}>
          {
            !_.isEmpty(group) ?
              <section className="login__container group-search">
              <GroupCard
                {...group}
                isOwner={isOwner}
                handleLeaveGroup={this.handleLeave} />
              </section>
              :
              <section className="login__container group-search">
                {this.props.location.pathname.includes("group/new") ||
                this.props.location.pathname.includes("group/edit") ?
                ""
                :
                <div>
                  <Field
                    isLoading={groupsState.isFetching}
                    options={group_options}
                    name="group_id"
                    placeholder="Start typing to search for a group..."
                    component={FormAutocomplete}
                    style="margin-bottom: 1rem;" />
                  {
                    selected_group ?
                      <a className="button button--w-full button--green" onClick={this.handleJoin}>Join this group</a> :
                      <Link className="button button--w-full button--green" to="/members/group/new">Start a new group</Link>
                  }
                </div>
                }
              </section>
          }

        {this.props.children}

      </LoaderPage>
    )
  }

  handleLeave = () => {
    const { userActions, change, groups, user: { group_id } } = this.props
    const group = findGroup(group_id, groups)

    if (confirm(`Are you sure you want to leave the group - ${group.name}`)) {

      change('group_id', null)

      userActions.updateAccount({
        group_id: null,
      }, () => {
        // Success
        AlertPopup.success('Successfully left group')
      })
    }
  }

  handleJoin = () => {
    const { groups, groupActions, userActions, currentValues: group_id } = this.props

    const group = findGroup(group_id, groups)

    if (confirm(`Confirm joining the group - ${group.name}`)) {
      userActions.updateAccount({
        group_id: group_id,
      }, () => {
        // Success
        AlertPopup.success('Successfully joined group')

        groupActions.getGroup({
          id: group_id
        })
        browserHistory.push('/members/group')


      })
    }
  }

  handleCreate = () => {

  }
}

const findGroup = (id, groups) => {
  return _.find(groups, { id }) || {}
}

const form = {
  name: 'group_show',
  fields: [
    'group_id',
  ],
  initialize: (state) => ({
    group_id: state.user.toJS().group_id
  })
};

export default connect(GroupShow, form)
