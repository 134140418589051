import React, {Component} from 'react'
import Map from './map'
import Sound from './sound'
import { filter_labels } from './filters'
import { convertToHttps } from 'Admin/helpers'

export const toWaveForm = (audio_url, width = 160, height = 50) =>
  audio_url.replace('aac', 'png')
    .replace('m4a', 'png')
    .replace('wav', 'png')
    .replace('upload', `upload/fl_waveform,w_${width},h_${height},co_rgb:888888,b_transparent`)

class Validate extends Component {

  state = {
    filter_scientific_name: '',
    filter_type: 'matched_frog_ids',
    validator_note: null,
    geoprivacy: null,
  }

  render() {
    const { capture = {}, validated_type, frog_ids, frogs = [], onSaveValidatorNote } = this.props
    const { filter_scientific_name, filter_type, validator_note: state_validator_note, geoprivacy: state_geoprivacy } = this.state

    const {
      id, call_audio = "", images = [], filters = {},
      lat, lng, state, note, validator_note, geoprivacy, user_id, user_full_name,
      validated_status, location_accuracy
    } = capture

    const disabled = capture.validated_status !== 'pending'

    const center = {
      lat: lat,
      lng: lng,
    }

    const showMap =
      lat !== 0 &&
      lng !== 0 &&
      !_.isUndefined(lat) &&
      !_.isUndefined(lng)

    const Input = (props) =>
      <input name="type"
             type="radio"
             disabled={disabled}
             checked={validated_type === props.validated_type}
             onChange={this.handleChangeType.bind(this, props.validated_type)}
      />


    const isSelected = (id) => _.includes(frog_ids, id)

    const tabs = [
      {label: 'Guessed', key: 'matched_frog_ids'},
      {label: 'Filtered', key: 'filtered_frog_ids'},
      {label: 'Remaining', key: 'remaining_frog_ids'},
    ]

    const current_tab_ids = capture[filter_type] || []

    let filtered_frogs = _.filter(frogs, frog => _.includes(current_tab_ids, frog.id)) || []

    filtered_frogs = _.isEmpty(filter_scientific_name) ? filtered_frogs :  _.filter(
      filtered_frogs, (frog) => (
        _.includes(_.lowerCase(frog.scientific_name), _.lowerCase(filter_scientific_name))
      ))

    const current_validator_note = _.isNull(state_validator_note) ? validator_note : state_validator_note
    const current_geoprivacy = _.isNull(state_geoprivacy) ? geoprivacy : state_geoprivacy

    return (
      <div className="validate-form">
        <div className="row">
          <div className="col-md-6">
            {
              !_.isEmpty(call_audio) &&
              <div className="audio-container">
                <div className="audio">
                  <div className="play">
                    <Sound key={id} url={call_audio} />
                  </div>
                  <div>
                    Listen to {user_full_name}'s recording
                    <div key={toWaveForm(call_audio)} className="waveform">
                      <img src={convertToHttps(toWaveForm(call_audio))} />
                    </div>
                  </div>
                </div>
                <a target="_new" download={`Capture_${id}_by_${_.snakeCase(user_full_name)}`} className="download" href={call_audio}>Download</a>
                <hr />
              </div>
            }
            <div className="section">
              <div className="title">Location</div>
              {
                showMap ?
                  <div className="map-container">
                    <div className="map">
                      <Map
                        key={id}
                        defaultCenter={center}
                        marker={{key:'location', position: center}}
                      />
                    </div>
                    <div className="map-coords">
                      Lat: {lat.toFixed(4)} / Lng: {lng.toFixed(4)} / Accuracy: {location_accuracy} <br/>
                      <a target="new" href={`https://www.google.com/maps/search/?api=1&query=${lat},${lng}`}>Open in Google Maps</a>
                    </div>
                  </div> :
                  <div className="blank">No location recorded</div>
              }
            </div>
            <div className="section">
              <div className="title">Images taken during recording</div>
              <div className="images">
                {
                  _.isEmpty(images) ?
                    <div className="blank">
                      No images recorded
                    </div> :
                    images.map((image, idx) => (
                    <div key={idx} className="image">
                      <img src={convertToHttps(image.card)} />
                    </div>
                  ))
                }
              </div>
            </div>
            <div className="section">
              <div className="title">Filters submitted during recording</div>
              <div className="filters">
                {
                  _.isEmpty(filters) ?
                    <div className="blank">
                      No filters
                    </div> :
                    _.map(filters, (val, key) => (
                      <div className="filter">
                        <div>{_.startCase(key)}: <strong>{filter_labels[key][val]}</strong></div>
                      </div>
                    ))
                }
              </div>
            </div>
            <div className="section">
              <div className="title">Notes submitted during recording</div>
              {
                _.isEmpty(note) ?
                  <div className="blank">No notes recorded</div> :
                  note
              }
            </div>
            <div className="section">
              <div className="title"><strong>Validator notes</strong> (not viewable by public)</div>
              <textarea onChange={this.handleChangeValidatorNote} rows="4" value={current_validator_note} className="form-control" />
              <a onClick={this.handleSaveValidatorNote}>Save</a>
            </div>
            <div className="section">
              <div className="title">Privacy</div>
              <select value={current_geoprivacy} onChange={this.handleChangeGeoprivacy} className="form-control">
                <option value="open">Open</option>
                <option value="obscured">Obscured</option>
                <option value="private">Private</option>
              </select>
              <a onClick={this.handleSaveGeoprivacy}>Save</a>
            </div>
          </div>
          <div className="col-md-6">
            <div>
              <div className="hint">
                Listen to matching frog calls and select your answer. If you have identified frog(s), tick the species you think is recorded in the call. Call may contain multiple species
              </div>
              <div>
                <label>
                  <Input validated_type="frogs"/> One or more of these frogs:
                </label>
                {
                  validated_type === 'frogs' &&
                  <div>
                    <div>
                      <div className="validate-filter">
                        <div className="tabs">
                          {
                            tabs.map(tab => (
                              <a onClick={() => this.setState({filter_type: tab.key})} className={`tab ${tab.key === filter_type && 'active'}`}>{tab.label}</a>
                            ))
                          }
                        </div>
                        <div>
                          <input value={filter_scientific_name} onChange={(e) => this.setState({
                            filter_scientific_name: e.target.value
                          })} placeholder="Filter by scientific name" className="search" type="search" />
                        </div>
                      </div>
                    </div>
                    <div className="frog-options">
                      {_.isEmpty(filtered_frogs) && <div className="placeholder">No results</div>}
                      {
                        filtered_frogs.map((frog) => (
                          <div key={frog.id}>
                            <div className="frog-option">
                              <input disabled={disabled} onChange={this.handleChangeFrog.bind(this, frog.id)} checked={isSelected(frog.id)} type="checkbox" />
                              <div className="thumb">
                                <img src={convertToHttps(frog.thumb)} />
                              </div>
                              <div>
                                <div>{frog.scientific_name}</div>
                                <div className="common-name">{frog.common_names.join(', ')}</div>
                                <div className="audios">
                                  {
                                    frog.audios.map(audio => (
                                      <div key={audio.id} className="audio">
                                        <Sound key={audio.id} url={audio.url} />
                                        <div key={toWaveForm(audio.url, 100, 30)} className="waveform">
                                          <img src={convertToHttps(toWaveForm(audio.url, 100, 30))} />
                                        </div>
                                      </div>
                                    ))
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                }
              </div>
              <div>
                <label>
                  <Input validated_type="not_a_frog"/> Not a frog
                </label>
              </div>
              <div>
                <label>
                  <Input validated_type="unidentified"/> Unidentified frog
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  handleChangeGeoprivacy = (e) => {
    this.setState({geoprivacy: e.target.value})
  }

  handleChangeValidatorNote = (e) => {
    this.setState({validator_note: e.target.value})
  }

  handleSaveGeoprivacy = () => {
    const { geoprivacy } = this.state
    const { onChangeGeoprivacy } = this.props
    onChangeGeoprivacy(geoprivacy)
  }

  handleSaveValidatorNote = () => {
    const { validator_note } = this.state
    const { onChangeValidatorNote } = this.props
    onChangeValidatorNote(validator_note)
  }

  handleChangeFrog = (id, e) => {
    const { onChange, validated_type } = this.props
    let { frog_ids } = this.props

    const selected = e.target.checked

    if(selected){
      frog_ids = _.union(frog_ids, [id])
    } else {
      frog_ids = _.without(frog_ids, id)
    }

    onChange({
      validated_type: validated_type,
      frog_ids: frog_ids
    })

  }

  handleChangeType = (type) => {
    const { onChange, frog_ids } = this.props

    onChange({
      validated_type: type,
      frog_ids: frog_ids
    })
  }
}

export default Validate