import reactCSS from 'reactcss'

export default reactCSS({
  'default': {
    fileInput: {
      cursor: 'pointer',
      opacity: 0,
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
    },
    buttonContainer: {
      cursor: 'pointer',
      position: 'relative',
      display: 'flex',
      border: '1px dashed #d8d8d8',
      borderRadius: '3px',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '5px 8px',
      width: '150px',
    },
    icon: {
      color: '#888888'
    },
    hint: {
      color: '#888888',
      marginLeft: '5px',
      textDecoration: 'none',
    },
    pending: {
      color: '#888888',
    },
    uploadsContainer: {
      marginTop: '5px',
    },
    error: {
      fontSize: '12px',
      color: "#e74c3c",
    },
    upload: {
      fontSize: '12px',
      color: "#888888",
    },
  },
})