import 'blueimp-file-upload/js/vendor/jquery.ui.widget.js'
import 'blueimp-file-upload/js/jquery.iframe-transport.js'
import 'blueimp-file-upload/js/jquery.fileupload.js'
import 'cloudinary-jquery-file-upload/cloudinary-jquery-file-upload'
import React, { Component } from 'react'
import PropTypes from "prop-types"
import $ from 'jquery'

import { upload_types, generateUUID, inputID, cl_preset, cl_cloudname } from './settings'
import styles from './styles'

class Uploader extends Component {

  static propTypes = {
    tags: PropTypes.array.isRequired,
    type: PropTypes.oneOf(_.keys(upload_types)).isRequired
  }

  state = {
    uploading: [],
    uploaded: [],
  }

  onAdd = (e, data) => {
    const id =  generateUUID()
    const file = data.files[0]

    const { uploading } = this.state
    const { type } = this.props
    const upload_type = upload_types[type] || {}

    const upload = {
      id: id,
      name: file.name,
      progress: 0
    }

    data.id = id

    if(!_.includes(upload_type.file_types, file.type)){
      upload.status = upload_type.message
    } else {
      data.submit()
    }

    uploading.push(upload)
    this.setState({uploading: uploading})
  }

  onSend = (e, data) => {
    // Have to clear headers for CORS preflight
    // $.ajaxSetup({beforeSend: _.noop});
  }

  onProgress = (e, data) => {
    let { uploading } = this.state
    const progress = Math.round((data.loaded * 100.0) / data.total)

    uploading = _.map(uploading, (upload) => {
      if(upload.id == data.id){
        upload.progress = progress
      }

      return upload
    })

    this.setState({uploading: uploading})
  }

  onDone = (e, data) => {
    const { onUpload } = this.props
    const { public_id, secure_url: file, duration } = data.result || {}
    let { uploading, uploaded } = this.state
    const upload = _.find(uploading, {id: data.id})

    uploading = _.without(uploading, upload)
    uploaded = _.union(uploaded, [upload])

    this.setState({
      uploading: uploading,
      uploaded: uploaded,
    })

    onUpload && onUpload({
      public_id: public_id,
      file: file,
      duration: duration
    })
  }

  onFail = (e, data) => {
    console.log('onFail')
  }

  componentDidMount(){
    const { tags, multiple = false } = this.props

    $(this.refs.fileInput).unsigned_cloudinary_upload(
      cl_preset,
      { cloud_name: cl_cloudname, tags: tags },
      { multiple: multiple, dropZone: null, autoUpload: false }
    )
      .bind('fileuploadadd', this.onAdd.bind(this))
      .bind('fileuploaddrop', _.noop)
      .bind('fileuploadsend', this.onSend.bind(this))
      .bind('cloudinaryprogress', this.onProgress.bind(this))
      .bind('cloudinarydone', this.onDone.bind(this))
      .bind('cloudinaryfail', this.onFail.bind(this))
  }
  
  render(){
    const { uploading } = this.state
    const { type, help } = this.props

    const pending = _.filter(uploading, {status: null})
    const upload_type = upload_types[type] || {}

    return (
      <div>
        <div style={styles.buttonContainer}>
          <input ref="fileInput" style={styles.fileInput} accept={upload_type.accept} type="file" name="file" />
          {
            pending.length == 0 ?
              <div >
                <i style={styles.icon} className="fa fa-cloud-upload" />
                <a style={styles.hint}>Select or drop file</a>
              </div> :
              <div>
                <span style={styles.pending}>Uploading...</span>
              </div>
          }
        </div>
        <div style={styles.uploadsContainer}>
          {help && <div style={styles.upload}>{help}</div>}
          {
            uploading.map((upload, idx) => (
              upload.status ?
                <div key={idx} style={styles.error}>{upload.status}</div> :
                <div key={idx} style={styles.upload}>{upload.name} - {upload.progress}%</div>
            ))
          }
        </div>
      </div>
    )
  }
}

export default Uploader