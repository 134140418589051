import React, { Component } from 'react'
import { Link } from 'react-router'
import BadgesGrid from 'Portal/components/Badges/Grid'
import connect from 'Portal/containers/connect'
import ProfileSharing from 'Portal/components/ProfileSharing'
import { convertToHttps } from 'Admin/helpers'

class Profile extends Component {

  state = {
    public_user : {}
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    const { userActions, location } = this.props
    const { pathname } = location;

    const path_parts = pathname.split("/");
    const user_slug = path_parts[path_parts.length - 1];
    const user_parts = user_slug.split("-");
    const user_id = user_parts.at(-1);
    // const query = {
      // show_archived: true
    // }

    fetch("/api/user/users/" + user_id + "/profile")
    .then(res => res.json())
    .then(
      (result) => {
        this.setState({public_user: result});
        window.smuCreateUserEmbed("#userRecordsEmbed", {
          userId: user_id,
        });
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {

        this.setState({
          isLoaded: true,
          error
        });
      }
    )
  }


  render() {
    const {public_user} = this.state;

    return (
      <>
      { public_user.profile_url ?
        <div>
          <div className="desktop-double-column">
            <section className="wrapper">
              <div className="profile-info">
                <img src={convertToHttps(public_user.avatar)} alt="Profile picture" className="profile-info__avatar" />
                <h1 className='profile-info__name'>{public_user.display_name}</h1>
                <div className="profile-info__sharing">
                  <ProfileSharing profile_url={public_user.profile_url} display_name={public_user.full_name} />
                </div>
                <div>
                  <h2 className="profile-info__groups-heading">Group memberships</h2>
                  <ul>
                    <li>
                      {
                        public_user.group &&
                        <p>Member of: <Link to={"/members/group/profile/" + public_user.group.id} className="profile-info__sharing-button">{public_user.group.name}</Link></p>
                      }
                    </li>
                  </ul>
                </div>
              </div>
            </section>
            <section className="highlights">
              <ul>
                <li className="highlights__card highlights__card--orange">
                  <div className="wrapper">
                    <div className="highlights__card-inner">
                      <div className="highlights__figure">{public_user.total_capture_count}</div>
                      <div className="highlights__descriptor">
                        Captures<br />
                        Submitted
                      </div>
                    </div>
                  </div>
                </li>
                <li className="highlights__card highlights__card--green">
                  <div className="wrapper">
                    <div className="highlights__card-inner">
                      <div className="highlights__figure">{public_user.validated_captures_count}</div>
                      <div className="highlights__descriptor">
                        Verified<br />
                        Frogs
                      </div>
                    </div>
                  </div>
                </li>
                <li className="highlights__card highlights__card--yellow">
                  <div className="wrapper">
                    <div className="highlights__card-inner">
                      <div className="highlights__figure">{public_user.frog_species_count}</div>
                      <div className="highlights__descriptor">
                        Species<br />
                        Found
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </section>
          </div>

          <div className="desktop-double-column">
            <section className="map">
              <div className="wrapper">
                <h2 className="section-heading">
                  <a href="/members/captures/list">Map of {public_user.first_name}'s captures</a>
                  <a href="/members/captures/list">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20.414" height="10.828" viewBox="0 0 20.414 10.828" className="link-arrow">
                      <path id="Path_9" data-name="Path 9" d="M17,8l4,4m0,0-4,4m4-4H3" transform="translate(-2 -6.586)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                    </svg>
                  </a>
                </h2>
              </div>
              <div className="wrapper">
                <div className="embed" id="userRecordsEmbed"></div>
                <a href="/explore">View the map of all users' captures</a>
              </div>
            </section>
            <section>
              <div className="wrapper">
                <h2 className="section-heading">
                  <a href="/members/profile/badges">{public_user.first_name}'s badges</a>
                  <a href="/members/profile/badges">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20.414" height="10.828" viewBox="0 0 20.414 10.828" className="link-arrow">
                      <path id="Path_9" data-name="Path 9" d="M17,8l4,4m0,0-4,4m4-4H3" transform="translate(-2 -6.586)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                    </svg>
                  </a>
                </h2>
              </div>
              <div className="wrapper">
                {
                  public_user.badge_statistics &&
                  <BadgesGrid badge_statistics={public_user.badge_statistics} />
                }
              </div>
            </section>
          </div>
        </div>
        :
        <div className="desktop-double-column">
          <section className="wrapper">
            <div className="profile-info">
              No public profile available for selected user
            </div>
          </section>
        </div>
        }
      </>
    )
  }
}

export default connect(Profile)
