import React, {Component} from 'react'

class Index extends Component {
  render(){
    return(
      <div>{this.props.children}</div>
    )
  }
}

export default Index