import React from 'react'
import { BADGE_ORDERING } from 'Portal/helpers'


class BadgesGrid extends React.Component {
	render() {
		const { badge_statistics } = this.props
		const user_badges = badge_statistics['user_badges']
		const user_recent_badges = badge_statistics['user_recent_badges']
		return (
			<>
				{
					<div className="badges__grid">
						{BADGE_ORDERING.map(function (badge_name, i) {
              if (user_recent_badges.includes(badge_name)){
								return <img src={"/images/badges/FrogID-badge-new-" + badge_statistics['badge_details'][badge_name]['image_suffix'] + ".png"} alt={badge_name + " badge"} className="image awarded" key={i} />;
              }
							else if (user_badges.includes(badge_name)) {
								return <img src={"/images/badges/FrogID-badge-" + badge_statistics['badge_details'][badge_name]['image_suffix'] + ".png"} alt={badge_name + " badge"} className="image awarded" key={i} />;
							}
              else {
                return <img src={"/images/badges/FrogID-badge-grey-" + badge_statistics['badge_details'][badge_name]['image_suffix'] + ".png"} alt={badge_name + " badge"} className="image " key={i} />;
              }
						})
						}
					</div>
				}
			</>
		)
	}
}

export default BadgesGrid
