import React, { Component } from 'react';

export default class LoaderPage extends Component {
  render() {
    const { loading, children } = this.props

    return (
      <div className="load">
        {
          loading ?
            <div style={{textAlign: 'center', padding: '100px 0'}}>
              Loading...
            </div> :
            children
        }
      </div>
    );
  }
}