import React from 'react';
import { Button } from 'react-bootstrap';

export default class LoaderButton extends React.Component {
  render() {
    return (
      <Button {...this.props} disabled={this.props.loading} >
        {
          this.props.loading ?
            <i className="icon ion-loading-c" />
            :
            this.props.children
        }
      </Button>
    );
  }
}