import Immutable from 'immutable';

export const initialState = Immutable.fromJS({
  isValidatingSignedIn: false,
  isFetching: false,
  isSignedIn: false,
  item: {
    company: {

    }
  },
  status: null,
  full_messages: [],
});

export function userReducer(state = initialState, action = null) {

  switch (action.type) {
    case 'CREATE_USER':
      return initialState.merge({
        isFetching: true,
      });

    case 'UPDATE_USER': {
      return state.merge({
        isFetching: true,
      });
    }

    case 'DELETE_USER': {
      return initialState.merge({
        isFetching: true,
      });
    }

    case 'CREATE_USER_SUCCESS':
      return state.merge({
        status: 'success',
        isFetching: true,
        full_messages: [],
      });

    case 'CREATE_USER_FAILED':
      return state.merge({
        isFetching: false,
        status: 'failed',
        isSignedIn: false,
        full_messages: action.full_messages || [],
      });

    case 'UPDATE_USER_SUCCESS':
      return state.merge({
        isFetching: false,
        status: 'success',
        item: action.payload,
        full_messages: [],
      });

    case 'UPDATE_USER_FAILED':
      return state.merge({
        isFetching: false,
        status: 'failed',
        full_messages: action.full_messages,
      });

    case 'DELETE_USER_SUCCESS':
      return initialState;

    case 'CHECK_SIGNED_IN':
      return state.merge({
        isValidatingSignedIn: true,
      });

    case 'CHECK_SIGNED_IN_SUCCESS':
      let user = state.get('item');

      return initialState.merge({
        isSignedIn: true,
        item: user.merge(action.payload),
      });

    case 'CHECK_SIGNED_IN_FAILED':
      return initialState.merge({
        isValidatingSignedIn: true,
        isSignedIn: false,
      })

    case 'SIGN_IN':
      return initialState.merge({
        isFetching: true,
      });

    case 'SIGN_IN_SUCCESS':
      return initialState.merge({
        isFetching: true
      });

    case 'SIGN_IN_FAILED':
      return initialState.merge({
        full_messages: action.full_messages,
      });

    case 'SIGN_OUT':
      return state.merge({
        isFetching: true,
        isValidatingSignedIn: true,
      });

    case 'SIGN_OUT_SUCCESS':
      return initialState.merge({
        isFetching: true,
        isValidatingSignedIn: true,
      });

    case 'RESET_PASSWORD':
      return initialState.merge({
        isFetching: true,
      });

    case 'RESET_PASSWORD_SUCCESS':
      return initialState.merge({
        isFetching: false,
        full_messages: action.full_messages,
      });

    case 'RESET_PASSWORD_FAILED':
      return initialState.merge({
        isFetching: false,
        full_messages: action.full_messages,
      });

    default:
      return state;
  }
}