import React from 'react'
import Frog from './frog'
import './frogs.scss'

class IdentifyFrogs extends React.Component {
  state = {
    loaded: 10,
    query: '',
  }

  render() {
    const { data } = this.props
    const { loaded, query } = this.state
    const filtered = _.take(filter(data, query), loaded)

    return (
      <div className="Frogs-Selection">
        <div className="Search">
          <input
            onChange={e => this.setState({query: e.target.value})}
            value={query}
            placeholder="Type name to filter..."
            className="form-control"
            type="search"
          />
        </div>
        <div className="Frogs">
          {
            filtered.map(frog => (
              <Frog 
                frog={frog}
                onChange={this.handleChange.bind(this, frog)}
              />
            ))
          }
        </div>
        <div className="text-center">
          <div>
            Showing {filtered.length} of {data.length} frogs
          </div>
          <a onClick={this.handleLoad}>Load more</a>
        </div>
      </div>
    )
  }

  handleLoad = () => {
    const { loaded } = this.state
    this.setState({loaded: loaded + 10})
  }

  handleChange = (frog, e) => {
    const { onChange } = this.props

    onChange(frog, e.target.checked)
  }
}

const filter = (frogs, query) => {
  return _.filter(frogs, frog => (
    _.includes(_.lowerCase(frog.scientific_name), _.lowerCase(query)) ||
    _.includes(_.lowerCase(frog.common_names), _.lowerCase(query)) ||
    _.includes(_.lowerCase(frog.id), _.lowerCase(query))
  ))
}

export default IdentifyFrogs