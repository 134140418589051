import React, { Component } from 'react'
import Navigation from 'Portal/components/Navigation.jsx'
import Footer from 'Portal/components/Footer.jsx'
import Partners from 'Portal/components/Partners.jsx'

export default class extends Component {

  render() {
    return (
      <div className="app-portal">
        <Navigation />
        <div className="container">
          <div className="section">
            <div className="desc">
              <Partners />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}

