import hostUrl from 'Portal/constants/api.jsx';
import { createResource, mergeReducers } from 'redux-rest-resource';

// this is used for both group list /groups, and group detail /group/slug
const resource = createResource({
  name: 'group',
  url: `${hostUrl}/groups/:slug`
});

const types = {...resource.types};
const actions = {...resource.actions};
const reducers = mergeReducers(resource.reducers, {});
export {types, actions, reducers};
