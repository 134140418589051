import React, { Component } from 'react'
import { FormControl } from 'react-bootstrap'
import Select from 'react-select'
import 'react-select/dist/react-select.css'
import 'react-datetime/css/react-datetime.css'
import { convertToHttps } from 'Admin/helpers'

// Uploads
import Uploader from '../components/uploaders/Uploader'

import moment from 'moment'
import DateTime from 'react-datetime'
const dateFormat = 'DD-MM-YYYY'
const timeFormat = 'hh:mm A'
const dateToMoment = (date) => {
  return moment(date, `${dateFormat} ${timeFormat}`)
}

const renderUnstyledInput = props => <input {...props.input} {...props} />
const renderInput = props => <FormControl {...props.input} {...props} />
const renderSelect = props => {
  const { onChange, value } = props.input

  return (
    <FormControl onChange={onChange} value={value} componentClass="select">
      {
        props.options.map((field, idx) => (
          <option defaultValue={field.selected} disabled={field.disabled} key={idx} value={field.value}>{field.label}</option>
        ))
      }
    </FormControl>
  )
}

const renderAutocomplete = props => {
  const { onChange, value } = props.input
  const { options, onSelected, isLoading, multi, disabled } = props

  return <Select
    disabled={disabled}
    options={options}
    multi={multi}
    isLoading={isLoading}
    onChange={(newVal) => {
      let val = multi ? _.map(newVal, (val) => val.value) : _.get(newVal, 'value', null)
      onChange(val)
      onSelected && onSelected(val)
    }}
    value={value}
  />
}

const renderImageUploader = (props) => {

  const { name, multiple } = props
  const { onChange, value } = props.input

  return (
    <div>
      <Uploader
        help="PNG or JPG files less than 2MB"
        tags={['images', name]}
        multiple={multiple}
        type="image"
        onUpload={(data) => {
          onChange(data.file)
          props.onUpload && props.onUpload(data)
        }}
      />
      {!_.isEmpty(value) && <img className="uploader-img" src={convertToHttps(value)} />}
    </div>
  )
};

const renderDateTime = (props) => {

  const { onChange, value } = props.input

  return (
    <span className="form-date">
      <DateTime
        dateFormat={dateFormat}
        timeFormat={timeFormat}
        {...props}
        onChange={(date) => {
          if (typeof date !== 'string') {
            onChange(date.format(`${dateFormat} ${timeFormat}`))
          }
        }}
        value={dateToMoment(value)}
      />
      {!_.isEmpty(value) && <a className="clear" onClick={() => onChange('')}><i className="fa fa-close" /></a>}
    </span>
  )
}

// module.exports = {
//   'FormInputUnstyled': renderUnstyledInput,
//   'FormInput': renderInput,
//   'FormSelect': renderSelect,
//   'FormAutocomplete': renderAutocomplete,
//   'FormImageUpload': renderImageUploader,
//   'FormDateTime': renderDateTime,
// }

export const FormInputUnstyled = renderUnstyledInput
export const FormInput = renderInput
export const FormSelect = renderSelect
export const FormAutocomplete = renderAutocomplete
export const FormImageUpload = renderImageUploader
export const FormDateTime = renderDateTime
