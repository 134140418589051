import React, { Component } from 'react'
import AlertPopup from 'react-s-alert';
import { Link, browserHistory } from 'react-router'
import connect from 'Portal/containers/connect'
import Navigation from 'Portal/components/Navigation.jsx'
import Footer from 'Portal/components/Footer.jsx'
import { LoaderPage } from 'Portal/components/Loaders'

import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';

class Members extends React.Component {

  componentDidMount() {
    const { userActions } = this.props

    userActions.isSignedIn(() => {
      // console.log('signed in');
    }, () => {
      this.logout();
    });
  }

  logout() {
    const { userActions } = this.props

    userActions.signOut()
    window.location = '/login'
  }

  render() {
    const { userState: { isSignedIn }, children } = this.props
    // return (
    //   isSignedIn ?
    //     <div className="app-portal">
    //       <div className="members">
    //         <Navigation />
    //         <div className="container">
    //           <div className="members-header">
    //             <div className="members-title">Froggers area</div>
    //             <a onClick={this.logout.bind(this)}>Logout</a>
    //           </div>
    //           <div className="members-nav panel">
    //             <Link to="/members/profile" activeClassName="active">Profile</Link>
    //             <Link to="/members/group" activeClassName="active">My group</Link>
    //             <Link to="/members/captures" activeClassName="active">My captures</Link>
    //             {/* <Link to="/members/validations" activeClassName="active">My IDs</Link> */}
    //           </div>

    //           <div className="members-stage">
    //             {children}
    //           </div>
    //         </div>
    //         <Footer />
    //       </div>
    //       <AlertPopup position="bottom-right" stack={{limit: 3, spacing: 10}} offset={10} effect="slide" />
    //     </div> : null
    // );

    return (
      isSignedIn ?
        <>
          <Navigation />
          {/* Here we insert the main content. For Profile page go to file 'client/bundles/Portal/containers/members/Profile/Show.jsx' */}
          { children }
          <AlertPopup position="bottom-right" stack={{limit: 3, spacing: 10}} offset={10} effect="slide" />
        </>
        : null
    )
  }
}

export default connect(Members)
