import { createResource, mergeReducers } from 'redux-rest-resource'
import { USER_API_URL } from 'Portal/helpers'

const resource = createResource({
  name: 'capture',
  url: `${USER_API_URL}/captures/:id`
});

const types = {
  ...resource.types,
};

const actions = {
  ...resource.actions,
};

const reducers = mergeReducers(
  resource.reducers, {
  }
);

export {types, actions, reducers};