import React, { Component } from 'react'
import { PanelGroup, Panel } from 'react-bootstrap'
import Navigation from 'Portal/components/Navigation.jsx'
import Footer from 'Portal/components/Footer.jsx'

export default class Privacy extends Component {

  render() {
    return (
      <div className="app-portal static-page">
        <Navigation />
        <div className="container">
          <div className="section hor">
            <div className="detail">
              <div className="desc">
                <h2>FrogID FAQs</h2>
                <p>We are excited to hear you’re interested in contributing to FrogID.</p>
                <p>There might be a few questions you have around the project. Review the list below to see if you can find an answer quickly so you can get started with FrogID.</p>
                <h3>GENERAL Q&amp;A</h3>
                <PanelGroup defaultActiveKey="2" accordion>
                  <Panel header="Q: What is FrogID and how does it work?" eventKey="17">
                    <p>FrogID is Australia’s first national frog identification project, created by the Australian Museum.  It is a citizen science project that enables registered users to submit audio recordings, these are then identified, and the user is notified of what species of frog they recorded.  The FrogID app also captures date, time and location data that informs Australian Museum research on frog distribution and breeding behaviour.</p>
                  </Panel>
                  <Panel header="Q: Where are the best places to hear frogs?" eventKey="18">
                    <p>Male frogs call to attract females of their own species to breed, and frogs prefer to breed near water – streams, ponds, dams, wetlands are all good places to start.</p>
                  </Panel>
                  <Panel header="Q:	When should I record frogs?" eventKey="1">
                    <p>The simple answer is – when you hear a frog calling, record it!</p>
                    <p>After rain is often the best time to hear frogs. Spring and Summer is breeding season for many frog species, while others call only in Autumn and/or Winter months, and some species call year-round. We want to hear your local frogs all year round to understand what is in your area and when they are breeding.</p>
                    <p>Many Australian frog species are nocturnal and will only call at night – the first few hours after dark is the best time to hear frogs. However, there are always exceptions to the rule and you may find some species calling during the day, particularly during wet weather.</p>
                  </Panel>
                  <Panel header="Q: How often should I send in recordings?" eventKey="2">
                    <p>We want you to send in recordings as frequently as possible. Ideally once per day in the same location is good, but if you hear a different species calling after already having made a recording in that spot, then record that one too.</p>
                  </Panel>
                  <Panel header="Q: Why is it important not to touch frogs and to wash footwear between areas?" eventKey="3">
                    <p>One of the reasons that frog populations are declining is disease, in particular a type of fungus called the amphibian chytrid fungus. This fungus attacks the skin of frogs and can cause them to die. The amphibian chytrid fungus is thought to be one of the main factors that four of Australia’s frog species are now Extinct.</p>
                    <p>The amphibian chytrid fungus can be spread by touching one frog and then another, or on your boots, clothing or equipment. To ensure that you don’t spread disease, it’s important not to touch frogs and to disinfect your boots between sites, avoid heading off track into the frog’s habitat too.</p>
                  </Panel>
                  <Panel header="Q: Should I record cane toads?" eventKey="4">
                    <p>Cane toads are a type of frogs, if a cane toad is calling, FrogID want you to record it. There are some great examples of what a cane toad sounds like on the FrogID app or website.  Cane toad records are really important to us as this helps us understand the distribution of this invasive species, so if you have cane toads in your area, regular recordings of them will help us.</p>
                  </Panel>
                  <Panel header="Q: How do I set up a FrogID account?" eventKey="5">
                    <p>There are two ways to set up an account, either in the profile section of the FrogID app or at the login page on the website (https://www.frogid.net.au/login). Once you have an account you are free to contribute frog calls as often as you like.</p>
                  </Panel>
                  <Panel header="Q: Can I delete my FrogID account?" eventKey="6">
                    <p>
                      Yes you can, you are able to delete your FrogID account from the profile section of the FrogID website (there is a link to this in the app). This will remove all identifiable information from your records, however FrogID will retain the recordings and associated data to use in our research.  Please be aware, you will not be able to get any future access to your past records once you have deleted your account.
                    </p>
                  </Panel>
                  <Panel header="Q: How do I verify my FrogID account?" eventKey="7">
                    <p>
                      When you sign up to FrogID you will need to verify the email address that you used to sign up, not doing this will prevent you from submitting calls and being able to log in to your account.  If you have not received an email in your inbox, please:
                    </p>
                    <ul>
                      <li>Check you junk/spam folder,</li>
                      <li>Try to set up another account with the same email,</li>
                      <li>Or contact calls@frogid.net.au with your user name and email address you tried to set up and we will help you out.</li>
                    </ul>
                  </Panel>
                  <Panel header="Q: Are there any data costs?" eventKey="8">
                    <p>
                      Like many apps, FrogID uses data on your device. Uploading and downloading content via a WIFI network is the fastest and cheapest option.  Anyone using FrogID should consider their own data use, so please check your personal phone plan to understand your data allocation and any potential data charges that may apply to downloading and using the FrogID app.
                    </p>
                  </Panel>
                  <Panel header="Q: Do I need to have phone reception to make a recording?" eventKey="9">
                    <p>You can record calling frogs at any time with the FrogID app, if you do not have reception simply hit the submit button after making the recording and it will be sent next time you open the app when you have reception.  If you are not logged in while the recording is made, next time you have reception and open the app, log in and then submit the recordings in the ‘Not Submitted’ folder in your profile.</p>
                  </Panel>
                  <Panel header="Q: How does the app tell what frog I have recorded?" eventKey="10">
                    <p>
                      The app doesn’t yet have an in-built identification function (we’re working on it). When you record a frog, based on the location, habitat and water body info you enter, the app filters the potential matches for you. When you submit a record to us, a member of our team of scientists will then listen to the call and identify what frogs they hear. You will then get an email telling you what species you recorded, and the info will also show up in you profile.
                    </p>
                    <p>
                      It is super important that you submit records even if you know what species of frog they are, this increases our data, and helps the research team to better understand Australia’s frogs.
                    </p>
                  </Panel>
                  <Panel header="Q: I am having trouble with the app, can you help?" eventKey="11">
                    <p>
                      Frogs like bugs, but we don’t! We work with developers to regularly update and upgrade our systems, but unfortunately it is not always perfect. Some issues that are encountered include:
                    </p>
                    <ul>
                      <li>Some users cannot see/access all of the content – this usually occurs when the offline content has not been downloaded and the app doesn’t have a secure connection to access it from the cloud, please make sure you hit yes when the app asks you to download offline content.</li>
                      <li>From time to time the app may crash (or involuntarily exit you) – this can be related to it trying to process too much information, sometimes navigating too quickly, particularly when the connection is poor will cause this to happen, please be patient as each screen loads, especially when you first open the app.</li>
                      <li>The app doesn’t work on all devices – unfortunately while we have done our best to make the app as accessible as possible, as technology changes so too does compatibility. We try to stay as up to date as possible, the app should be functional on smartphone operating systems iOS 10.2.1 and Android 4.4.4 and above.  The app is currently not compatible with tablet devices.</li>
                    </ul>
                    <p>
                      There are some simple trouble shooting options that all users can try if you encounter problems with the app, such as restarting the app, turning the phone off and back on again, or upgrading to the latest app version. 
                    </p>
                    <p>
                      DELETING AND RE-INSTALLING THE APP WILL REMOVE ALL THE NOT YET SUBMITTED RECORDING DATA ON YOUR PHONE, THIS SHOULD ONLY EVER BE DONE AS A LAST RESORT, AND ONLY IF YOU ARE PREPARED FOR DATA TO BE LOST.
                    </p>
                    <p>
                      If you continue to have issues with the app, or your problem is not listed above, please email us at <a href="mailto:calls@frogid.net.au">calls@frogid.net.au</a> with your user name, a description of the issue, what operating software is on your device, which version of the FrogID app you are using, and the make and model of the device.  We will do our best to help you from there.
                    </p>
                  </Panel>
                </PanelGroup>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );

  }
}
