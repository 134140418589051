import React from "react";
import { Route, IndexRoute, Redirect } from "react-router";

import ConfirmEmail from "Admin/containers/public/ConfirmEmail.jsx";
import ForgotPassword from "Admin/containers/public/ForgotPassword.jsx";
import ResetPassword from "Admin/containers/public/ResetPassword.jsx";

import Home from "Portal/pages/Home";
import Landing from "Portal/pages/Landing";
import FrogCount from "Portal/pages/FrogCount";
import FrogIdWeek from "Portal/pages/FrogIdWeek";
import FrogIdWeek2020 from "Portal/pages/FrogIdWeek2020";
import GetInvolved from "Portal/pages/GetInvolved";
import Learn from "Portal/pages/Learn";
import FrogGroup from "Portal/pages/FrogGroup";
import Schools from "Portal/pages/Schools";
import Explore from "Portal/pages/Explore";

import Partners from "Portal/pages/Partners";
import Privacy from "Portal/pages/Privacy";
import Terms from "Portal/pages/Terms";
import TermsApp from "Portal/pages/TermsApp";
import FAQ from "Portal/pages/FAQ";

import VerifyEmail from "Portal/pages/VerifyEmail";
import LogIn from "Portal/pages/LogIn";
import SignUp from "Portal/pages/SignUp";

import Members from "Portal/containers/members/Index";
import PublicMembers from "Portal/containers/members/PublicIndex";

import Profile from "Portal/containers/members/Profile/Master";
import ProfileShow from "Portal/containers/members/Profile/Show";
import ProfileShowPublic from "Portal/containers/members/Profile/ShowPublic";
import ProfileEdit from "Portal/containers/members/Profile/Edit";
import CapturesDetail from "Portal/containers/members/Profile/Capture";
import ProfileBadges from "Portal/containers/members/Profile/Badges";

import Group from "Portal/containers/members/Group/Show";
import GroupNew from "Portal/containers/members/Group/New";
import GroupEdit from "Portal/containers/members/Group/Edit";
import GroupDirectory from "Portal/containers/members/Group/Directory";
import GroupProfile from "Portal/containers/members/Group/GroupProfile";
import GroupProfilePublic from "Portal/containers/members/Group/GroupProfilePublic";
import GroupCaptures from "Portal/containers/members/Group/Captures";
import GroupCapturesDetail from "Portal/containers/members/Group/Capture";
import GroupMembers from "Portal/containers/members/Group/Members";
import GroupMembersPublic from "Portal/containers/members/Group/MembersPublic";

import CapturesList from "Portal/containers/members/Captures/List";

import FrogShow from "Portal/containers/frogs/show";

import Identify from "Portal/containers/members/Identify/Index";
import IdentifyPractice from "Portal/containers/members/Identify/Practice";

import IdentifyMaster from "Portal/containers/identify/master";
import IdentifyTest from "Portal/containers/identify/test";
import IdentifyShow from "Portal/containers/identify/show";

import UserValidationsList from "Portal/containers/user_validations/list";

export default (
  <div>
    <Route path="/confirm-email" component={ConfirmEmail} />
    <Route path="/forgot-password" component={ForgotPassword} />
    <Route path="/reset-password" component={ResetPassword} />

    <Route path="/" component={Home} />
    <Route path="/coming-soon" component={Landing} />

    <Route path="/partners" component={Partners} />
    <Route path="/privacy" component={Privacy} />
    <Route path="/terms" component={Terms} />
    <Route path="/terms/app" component={TermsApp} />
    <Route path="/faq" component={FAQ} />

    <Route path="/frog-count" component={FrogCount} />
    <Route path="/frog-id-week" component={FrogIdWeek} />
    <Route path="/frog-id-week-2020" component={FrogIdWeek2020} />
    <Route path="/get-involved" component={GetInvolved} />
    <Route path="/schools" component={Schools} />
    <Route path="/explore" component={Explore} />

    <Route path="/frog_groups/:slug" component={FrogGroup} />

    <Route path="/frogs" component={Learn}>
      <Route path=":id" component={FrogShow} />
    </Route>

    {/* Legacy redirects */}
    <Redirect from="/members" to="/members/profile" />
    <Redirect from="/members/captures" to="/members/profile/captures/list" />
    <Redirect from="/members/captures/list" to="/members/profile/captures/list" />
    <Redirect from="/members/profile" to="/members/profile/show" />
    <Route path="/p" component={PublicMembers}>
      <Route path="group/:slug" component={GroupProfilePublic} />
      <Route path="group/members/:slug" component={GroupMembersPublic} />
      <Route path=":slug" component={ProfileShowPublic} />
    </Route>
    <Route path="/members" component={Members}>
      {/* I'm using /profile for the private pages */}
      <Route path="profile" component={Profile}>
        <Route path="captures/list" component={CapturesList} />
        <Route path="show" component={ProfileShow} />
        <Route path="edit" component={ProfileEdit} />
        <Route path="capture/:id" component={CapturesDetail} />
        <Route path="badges" component={ProfileBadges} />
      </Route>

      <Route path="group" component={Group}>
        <Route path="new" component={GroupNew} />
        <Route path="edit/:slug" component={GroupEdit} />
      </Route>
      <Route path="group/directory" component={GroupDirectory} />
      <Route path="group/profile/:slug" component={GroupProfile} />
      <Route path="group/members/:slug" component={GroupMembers} />
      <Route path="group/captures/:slug" component={GroupCaptures} />
      <Route path="group/capture/:id" component={GroupCapturesDetail} />

      {/* This is the public end-user validation process, not completed */}
      <Redirect from="/members/identify" to="/members/identify/list" />
      <Route path="identify" component={Identify}>
        <Route path="list" component={IdentifyMaster} />
        <Route path="practice" component={IdentifyPractice} />
        <Route path="test" component={IdentifyTest} />
        <Route path="show" component={IdentifyShow} />
      </Route>

      <Route path="validations" component={UserValidationsList} />
    </Route>

    <Route path="/verify-email" component={VerifyEmail} />
    <Route path="/login" component={LogIn} />
    <Route path="/signup" component={SignUp} />
    <Route path="/signup/:group_id" component={SignUp} />

    <Route path="/*" component={PublicMembers} status={404} />
  </div>
);
