import React from 'react'
import { Link, browserHistory } from 'react-router'
import { Tabs, Tab } from 'react-bootstrap'
import MiniPlayer from 'shared/components/Audio/MiniPlayer'
import { FormInput } from 'Portal/components/FormInputs'
import Map from './map'
import Frogs from './frogs'
import Frog from './frog'
import './frogs.scss'
import './show.scss'
import connect, {Field} from '../form'

const getWithinRangeFrogs = (frogs, capture) => {
  const {filtered_frog_ids = [], matched_frog_ids = []} = capture
  const withinRangeIds = _.uniq(_.concat(filtered_frog_ids, matched_frog_ids))

  return _.filter(frogs, frog => _.includes(withinRangeIds, frog.id))
}

const getOutsideRangeFrogs = (frogs, withinRangeFrogs) => {
  const frogIds = _.map(frogs, 'id')
  const withinRangeIds = _.map(withinRangeFrogs, 'id')

  const outsideRangeIds = _.difference(frogIds, withinRangeIds)
  return _.filter(frogs, frog => _.includes(outsideRangeIds, frog.id))
}

const getValidatedFrogs = (frogs, validated_frog_ids) =>
  _.filter(frogs, frog => _.includes(validated_frog_ids, frog.id))

class IdentifyShow extends React.Component {
  componentDidMount(){
    const { captureActions, frogActions } = this.props
    frogActions.fetchFrogs('metadata')
    captureActions.getCapture({
      id: 'user_validatable'
    })
  }

  render() {
    const {
      frogs,
      capture,
      capture: {id, call_audio},
      currentValues: { validated_type, validated_frog_ids }
    } = this.props

    const withinRange = getWithinRangeFrogs(frogs, capture)
    const outsideRange = getOutsideRangeFrogs(frogs, withinRange)
    const validated = getValidatedFrogs(frogs, validated_frog_ids)

    return (
      id ?
        <div className="Panel-public-validate">
          <div className="header">
            <div>
              Capture #{id}
            </div>
            <div>
              <a href="/members/identify/show">Skip &amp; Validate Another</a>
            </div>
          </div>
          <div className="body">

            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <p className="instruction">1. Listen to user recording:</p>
                  {
                    call_audio && <MiniPlayer url={call_audio} />
                  }
                </div>

                <div className="form-group">
                  <p className="instruction">2. What is in this recording?</p>
                  <Field name="validated_type" component={ValidatedType} />
                </div>

              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <p className="instruction">Capture location</p>
                </div>
                {
                  id &&
                  <Map capture={capture} />
                }
              </div>
            </div>

            {
              validated_type === 'frog' &&
              <div>
                <div className="form-group">
                  <p className="instruction">3. Please select all the <em>Frog</em> species you hear in the call:</p>
                  <Field
                    name="validated_frog_ids"
                    component={ValidatedFrogs}
                    within_range={withinRange}
                    outside_range={outsideRange}
                  />
                </div>

                <div className="form-group">
                  <p className="instruction">4. Please confirm your selection(s):</p>
                  <div className="Frogs Frogs-Confirmation">
                    {
                      validated.map(frog => (
                        <Frog frog={{...frog, selected: true}} />
                      ))
                    }
                  </div>
                </div>

                <div className="form-group">
                  <p className="instruction">5. Unknown species calling</p>
                  <label className="checkbox-label">
                    <Field type="checkbox" name="unknown_species_calling" component={FormInput} />
                    Other frog species are calling in the user's capture, I am unsure of the additional species or if they are frogs
                  </label>
                </div>
              </div>
            }

            {
              validated_type === 'not_a_frog' &&
              <div>
                <p className="instruction">3. You said this is <em>Not a Frog</em>. Please select what you think it is:</p>
                <Field name="not_a_frog_type" component={NotAFrogType} />
              </div>
            }

          </div>

          {
            validated_type === 'skip' ?
              <div className="footer">
                <a href="/members/identify/show" className="btn btn-primary">Validate Another</a>
              </div> :
              <div className="footer">
                <button onClick={this.handleSubmit.bind(this, '/members/validations')} className="btn btn-primary">Submit and Exit</button>
                <button onClick={this.handleSubmit.bind(this, '/members/identify/show')} className="btn btn-primary">Submit and Validate Another</button>
              </div>
          }

        </div> :
        <NonePending />
    )
  }

  handleSubmit = (url) => {
    const {
      userValidationActions,
      capture: {id: capture_id},
      currentValues
    } = this.props

    userValidationActions.createUser_validations({
      ...currentValues,
      capture_id,
    }).then(() => {
      url && (window.location = url)
    })
  }
}

const ValidatedType = props => {
  const { value, onChange } = props.input
  const { name } = props
  const options = ['frog', 'not_a_frog', 'skip', 'spam']

  return (
    <RadioOptions
      name={name}
      options={options}
      onChange={onChange}
      value={value}
    />
  )
}

const ValidatedFrogs = props => {
  const { within_range, outside_range } = props
  const { value, onChange } = props.input

  const data = (frogs) =>_.map(frogs, frog => ({
    ...frog,
    selectable: true,
    selected: _.includes(value, frog.id)
  }))

  const handleChange = (frog, checked) =>
    onChange(checked ? _.union(value, [frog.id]) : _.without(value, frog.id))

  return (
    <Tabs defaultActiveKey={1} id="frogs-selection">
      <Tab eventKey={1} title="Frog species likely in this area">
        <Frogs
          data={data(within_range)}
          onChange={handleChange}
        />
      </Tab>
      <Tab eventKey={2} title="Frog species not likely from this area">
        <Frogs
          data={data(outside_range)}
          onChange={handleChange}
        />
      </Tab>
    </Tabs>
  )
}

const NotAFrogType = props => {
  const { value, onChange } = props.input
  const { name } = props
  const options = ['insect', 'bird', 'captive', 'other']

  return (
    <RadioOptions
      name={name}
      options={options}
      onChange={onChange}
      value={value}
    />
  )
}

const RadioOptions = props => {
  const { name, options, value, onChange } = props

  return (
    <div className="radio-choices">
      {
        options.map(option => (
          <div key={option}>
            <label>
              <input
                value={value}
                onChange={onChange.bind(this, option)}
                name={name}
                type="radio"
              /> {_.startCase(option)}
            </label>
          </div>
        ))
      }
    </div>
  )
}

const NonePending = props => {
  return (
    <div className="Panel-public-validate">
      <div className="header">
        <div>
          Notice
        </div>
      </div>
      <div className="body">
        <p className="instruction">There are currently no pending captures. Please check back later</p>
      </div>
    </div>
  )
}

const form = {
  name: 'public_validation',
  fields: [
    'validated_frog_ids',
    'validated_type',
    'not_a_frog_type',
    'unknown_species_calling',
  ],
  initialize: (state) => ({
    validated_frog_ids: [],
  })
};

export default connect(IdentifyShow, form)