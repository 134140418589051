import React, { Component } from 'react'
import { browserHistory } from 'react-router'

import Fields from 'Admin/containers/groups/_Fields'
import { LoaderButton } from 'Portal/components/Loaders'
import connect, { Field, Resolve } from 'Portal/containers/form'

class GroupNew extends Component {
  constructor(props) {
    super(props);

    this.state = {
      full_messages: [],
    };
  }

  render() {
    const { currentValues: { is_school }, groupsState: { isCreating } } = this.props
    const { full_messages } = this.state
    return (
      <div className="wrapper">
        {
          !_.isEmpty(full_messages) &&
          <div className="alert alert-warning">
            {
              full_messages.map((message, index) => (
                <p key={index}>{message}</p>
              ))
            }
          </div>
        }
        <Fields isSchool={is_school} />
        <div className="login__container">
          <LoaderButton onClick={this.onSubmit} loading={isCreating} className="button button--green button--w-full" type="submit">Create Group</LoaderButton>
        </div>
      </div>
    )
  }

  onSubmit = () => {
    const { groupActions, userActions, currentValues } = this.props

    groupActions.createGroup({
      ...currentValues
    }).then(({ body: group }) => {
      userActions.updateAccount({
        group_id: group.id
      })
      browserHistory.push('members/group/profile/' + group.slug)
    }).catch((error) => {
      this.setState({full_messages: [error.body.message]})
    })
  }
}

const form = {
  name: 'group_new',
  fields: [
    'name',
    'avatar',
    'desc',
    'postcode',
    'is_school',
    'school_name',
    'private',
  ]
};

export default connect(GroupNew, form);
