import React, {Component} from 'react'
import { withGoogleMap, GoogleMap, Marker } from 'react-google-maps'

const GoogleMapHOC = withGoogleMap(props => (
  <GoogleMap
    ref={props.onMapLoad}
    zoom={props.zoom}
    defaultZoom={10}
    defaultCenter={props.defaultCenter}
    options={{
      scrollwheel: false,
      navigationControl: false,
      streetViewControl: false,
      fullscreenControl: false,
      disableDoubleClickZoom: props.zoomDisabled,
      panControl: !props.zoomDisabled,
    }}
    onClick={props.onMapClick}
  >
    {props.children}
  </GoogleMap>
))

class CaptureMap extends Component {
  render() {
    return (
      <GoogleMapHOC
        {...this.props}
        containerElement={
          <div style={{ height: `100%` }} />
        }
        mapElement={
          <div style={{ height: `100%` }} />
        }
        onMapLoad={_.noop}
        onMapClick={_.noop}
      >
        <Marker
          {...this.props.marker}
          onRightClick={_.noop}
        />
      </GoogleMapHOC>
    )
  }
}

export default CaptureMap