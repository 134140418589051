import React, {Component} from 'react';
import c from "classnames";
import _ from "lodash";
import Spinner from "./Spinner";

class Pagination extends Component {
  getPageNumber() {
    // Prevent issues where strings and integers are used interchangeably
    return parseInt(this.props.page);
  }
  getPageCount() {
    // Prevent issues where strings and integers are used interchangeably
    return parseInt(this.props.pageCount);
  }
  handlePageSelection(selectedPage) {
    const {onPageChange} = this.props;
    if (selectedPage !== this.props.page) {
      onPageChange(selectedPage);
    }
  }
  handlePrevPage() {
    const page = this.getPageNumber();
    if (page > 1) {
      this.handlePageSelection(page - 1);
    }
  }
  handleNextPage() {
    const page = this.getPageNumber();
    const pageCount = this.getPageCount();
    if (page < pageCount) {
      this.handlePageSelection(page + 1);
    }
  }
  render() {
    const {loading} = this.props;
    let firstPage, lastPage;
    const page = this.getPageNumber();
    const pageCount = this.getPageCount();
    const pageWindow = 3;
    const pagesToDisplay = Math.min(pageCount, pageWindow);
    const showFirstPageToggle = (page + 1 > pageWindow) && (pageCount > pageWindow)
    const showLastPageToggle = (page + 1 < pageCount) && (pageCount > pageWindow)
    if (pagesToDisplay < pageWindow){
      firstPage = 1;
      lastPage = pagesToDisplay;
    }else if(page <= 2){
      // then we're at the start
      firstPage = 1;
      lastPage = 3;
    }else if(page == 3){
      firstPage = 2;
      lastPage = 4;
    }else{
      // then we're near the end
      lastPage = Math.min(page + 2, pageCount + 1);
      firstPage = lastPage - pageWindow;
    }

    return (
      <div>
        <ul className="paging">
          <li
            className={c("paging__item", {
              "is-disabled": page === 1
            })}
            onClick={() => this.handlePrevPage()}
          >←</li>
          { showFirstPageToggle && 
            <>
              <li className="paging__item" onClick={() => this.handlePageSelection(1)}>1</li>
              <li className="paging__spacer">...</li>
            </>
          }
          {_.times(pagesToDisplay, index => {
            const pageNumber = firstPage + index  ;
            return (
              <li
                key={index}
                className={c("paging__item", {
                  "is-active": pageNumber === page,
                })}
                onClick={() => this.handlePageSelection(pageNumber)}
              >
                {pageNumber}
              </li>
            );
          })}
          { showLastPageToggle &&
            <>
              <li className="paging__spacer">...</li>
              <li className="paging__item" onClick={() => this.handlePageSelection(pageCount)}>{ pageCount }</li>
            </>
          }
          <li
            className={c("paging__item", {
              "is-disabled": page === pageCount
            })}
            onClick={() => this.handleNextPage()}
          >→</li>
          {loading
            ? (
              <div className="paging__loading">
                <Spinner />
              </div>
            )
            : null
          }
        </ul>
      </div>
    );
  }
}

export default Pagination;
