import React, { Component } from 'react'
import Navigation from 'Portal/components/Navigation.jsx'
import Footer from 'Portal/components/Footer.jsx'
import FrogSearch from 'Portal/containers/frogs/search'

export default class Page extends Component {

  render() {
    return (
      <div className="app-portal">
        <Navigation />
        <div className="home-feature">
          <div className="container">
            <div className="feature-inner hor">
              <div className="detail">
                <h1>Learn</h1>
                <p>
                  More than 240 species of frog have been discovered in Australia.<br />
                  Explore our frog profiles or watch our expert videos to discover<br />
                  all kinds of facts about frogs.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="frogs">
          <div className="section-gray">
            <div className="container">
              <div className="section">
                <div className="detail">
                  <div className="desc text-center">
                    <h2>Explore frog profiles</h2>
                  </div>
                </div>
              </div>
            </div>

            <FrogSearch />

          </div>
        </div>

        <div id="videos" className="videos">
          <div className="container">
            <div className="section">
              <div className="detail">
                <div className="desc text-center">
                  <h2>Instructional Videos</h2>
                </div>
              </div>
            </div>
          </div>

          <div className="container video-container">
            <div className="section hor">
              <div className="detail">
                <div className="desc">
                  <h2>How to use the FrogID mobile app</h2>
                </div>
              </div>
              <div className="video">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/sl73oSP1MjE" frameBorder="0" allowFullScreen />
              </div>
            </div>
          </div>

          <div className="container video-container">
            <div className="section hor">
              <div className="detail">
                <div className="desc">
                  <h2>Tips for using FrogID in the field</h2>
                </div>
              </div>
              <div className="video">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/GQDF7uCjsTY" frameBorder="0" allowFullScreen />
              </div>
            </div>
          </div>

          <div className="container video-container">
            <div className="section hor">
              <div className="detail">
                <div className="desc">
                  <h2>Why frogs are important</h2>
                </div>
              </div>
              <div className="video">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/KKzPq-gOQro" frameBorder="0" allowFullScreen />
              </div>
            </div>
          </div>
        </div>

        <div id="media_contact" className="container">
          <div className="section hor">
            <div className="detail">
              <div className="desc">
                <h2>Contact Media</h2>
                <p>
                  <strong>Claire Vince</strong><br />
                  Media and Communications Advisor | Marketing & Communications<br />
                  <strong>Australian Museum</strong> 1 William Street Sydney NSW 2010 Australia<br />
                  <strong>T</strong> <a href="tel:61 2 9320 6181">61 2 9320 6181</a> <strong>M</strong> <a href="tel:61 468 726 910">61 468 726 910</a>
                </p>
              </div>
              <a className="btn btn-primary" href="https://foto.australianmuseum.net.au/fotoweb/albums/WfwJ1Ni2X2FePK0wvvRr969kpnjW-rerXV7dHg/">Download Media Kit</a>
            </div>
          </div>
        </div>

        {this.props.children}

        <Footer />
      </div>
    );

  }
}
