import React, {Component} from 'react'
import Sound from 'react-sound'

class CaptureSound extends Component {

  state = {
    playStatus: Sound.status.STOPPED
  }

  componentDidMount(){
    soundManager.setup({debugMode: false});
  }

  render() {
    const { url } = this.props
    const { playStatus } = this.state

    return (
      <div className="player">
        {
          playStatus === Sound.status.STOPPED ?
            <a onClick={this.handlePlay}><i className="fa sound-button fa-play-circle" /></a> :
            <a onClick={this.handleStop}><i className="fa sound-button fa-stop-circle" /></a>
        }
        <Sound
          url={url}pla
          playStatus={playStatus}
          onLoading={this.onLoading}
          onPlaying={this.onPlaying}
          onFinishedPlaying={this.onFinishedPlaying}
        />
      </div>
    )
  }

  handlePlay = () => {
    this.setState({
      playStatus: Sound.status.PLAYING
    })
  }

  handleStop = () => {
    this.setState({
      playStatus: Sound.status.STOPPED
    })
  }

  onLoading = () => {

  }

  onPlaying = (data) => {
    const { duration, position } = data
  }

  onFinishedPlaying = () => {
    this.setState({
      playStatus: Sound.status.STOPPED
    })
  }
}

export const toWaveForm = (audio_url, width = 160, height = 50) =>
  audio_url.replace('aac', 'png')
    .replace('m4a', 'png')
    .replace('wav', 'png')
    .replace('upload', `upload/fl_waveform,w_${width},h_${height},co_rgb:888888,b_transparent`)

export default CaptureSound