import React from 'react'
import connect from 'Portal/containers/connect'
import Pagination from "Portal/components/Pagination";
import PaginatedList from "Portal/components/PaginatedList";
import AlertPopup from "react-s-alert";
import { browserHistory } from "react-router";
import { FormControl } from "react-bootstrap";


class GroupDirectory extends PaginatedList {
  state = {
    joinGroupAlertOpen: false,
    leaveGroupAlertOpen: false,
    groupID: null,
  }

  constructor() {
    super();
    this.state.q = '';
  }

  componentDidMount() {
    const { query } = this.props.location;
    this.setState({ q: query.q });
    this.updateListData();
  }

  handleQueryChanged() {
    this.updateListData();
  }

  fetchData(query) {
    return this.props.groupActions.fetchGroups({}, { query: query });
  }

  handleSearchQueryChange(e) {
    const q = e.target.value;
    if (q) {
      this.setState({
        q: q,
        page: 1
      });
    } else {
      this.setState({
        q: "",
        page: 1,
      });
    }
    const { location } = this.props;
    location.query.q = q;
    location.query.page = 1;
    browserHistory.push(location);
    this.handleQueryChanged();
  }

  handleJoin(group_id) {
    const { groups, groupActions, userActions } = this.props
    const group = _.find(groups, { id: group_id }) || {}
    userActions.updateAccount({
      group_id: group_id,
    }, () => {
      AlertPopup.success('Successfully joined group')
      groupActions.getGroup({
        id: group_id
      });
      location.replace('/members/group/profile/' + group.slug);
    });

  }

  handleLeave() {
    const { userActions, groups, user } = this.props
    const group = _.find(groups, { id: user.group_id }) || {}
      userActions.updateAccount({
        group_id: null,
      }, () => {
        AlertPopup.success('Successfully left group');
      });
      this.closeAlert()
  }

  openJoinGroupAlert(groupId) {
    this.setState({
      groupID: groupId,
      joinGroupAlertOpen: true,
    })
  }

  openLeaveGroupAlert(groupId) {
    this.setState({
      groupID: groupId,
      leaveGroupAlertOpen: true,
    })
  }

  closeAlert() {
    this.setState({
      joinGroupAlertOpen: false,
      leaveGroupAlertOpen: false,
    })
  }

  render() {
    const { total_pages, page, q } = this.state;
    const { groups, user } = this.props;

    return (
      <div className="wrapper">
        <h1 className="page-heading">Group Directory</h1>
        <button className="button button--green"><a href="/members/group/new">Create group +</a></button>
        <div className="edit-form">
          <label htmlFor="groups-search-query">Search:</label>
          <FormControl
            id="groups-search-query"
            value={q}
            onChange={e => this.handleSearchQueryChange(e)}
          />
        </div>
        <Pagination
          page={page}
          pageCount={total_pages}
          onPageChange={page => this.handlePageChange(page)}
        />
        <table>
          <thead>
            <tr>
              <th className="group-table__cell group-table__cell--w-8/12">Group name and description</th>
              <th className="group-table__cell  group-table__cell--w-4/12 group-table__cell--text-right">#users</th>
            </tr>
          </thead>
          <tbody>
            {
              groups.map(group => (
                <tr key={group.id}>
                  <td className="group-table__cell" >
                    <h2><a href={"/members/group/profile/" + group.slug}>{group.is_school ? group.school_name : group.name}</a></h2>
                    <p>{group.desc ? group.desc : " "}</p>
                    {group.id === user.group_id
                      ? (
                        <button
                          className="button button--green"
                          onClick={() => this.openLeaveGroupAlert(group.id)}
                        >Leave group</button>
                      )
                      : (
                        <button
                          className="button button--green"
                          onClick={() => this.openJoinGroupAlert(group.id)}
                        >Join group +</button>
                      )
                    }
                    {/* Join Group alert (hidden by default) */}
                    <div id={group.id} className={this.state.joinGroupAlertOpen && this.state.groupID===group.id ? "alert--open" : "alert--closed"}>
                      <div className="alert__background"></div>
                      <div className="alert__dialog-box">
                        <p>Would you like to join {group.name}?</p>
                        <button className="button button--green" onClick={() => this.handleJoin(group.id)}>Yes</button>
                        <button className="button button--green" onClick={() => this.closeAlert()}>Cancel</button>
                      </div>
                    </div>
                    {/* Leave Group alert (hidden by default) */}
                    <div className={this.state.leaveGroupAlertOpen && this.state.groupID===group.id ? "alert--opened" : "alert--closed"}>
                      <div className="alert__background"></div>
                      <div className="alert__dialog-box">
                        <p>Would you like to leave {group.name}?</p>
                        <button className="button button--green" onClick={() => this.handleLeave(group.id)}>Yes</button>
                        <button className="button button--green" onClick={() => this.closeAlert()}>Cancel</button>
                      </div>
                    </div>
                  </td>
                  <td className="group-table__cell group-table__cell--text-right">
                    <div>{group.users_count}</div>
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    )
  }
}

export default connect(GroupDirectory)
