import React, {Component} from 'react'
import { thisMonth, dateFormat } from 'shared/components/DateRange/options.js'
import Map from 'Portal/components/Map'
import DatePicker from 'shared/components/DateRange/Picker.jsx'
import connect from '../connect'

class FrogMap extends Component {

  loadCaptures(dateRange){
    const { mapCaptureActions } = this.props

    mapCaptureActions.fetchMapCaptures({}, {
      query: dateRange
    })
  }

  componentDidMount(){
    this.loadCaptures({
      from: thisMonth.from.format(dateFormat),
      to: thisMonth.to.format(dateFormat),
    })
  }

  render(){
    const { map_captures } = this.props
    const markers = _.map(map_captures, (capture) => (
      {
        key: capture.id,
        // position: {
        //   lat: capture.lat,
        //   lng: capture.lng,
        //   radius: 3000
        // },
        position: {
          lat: capture.rand_lat,
          lng: capture.rand_lng,
          radius: capture.rand_radius
        },
      }
    ))

    return (
      <div>
        <DatePicker
          defaultRange={thisMonth}
          onChange={this.handleDateChanged}
        />

        <br />

        <div>
          Use date filter to display verified submissions within selected range.
        </div>

        <br />

        <Map markers={markers} />
      </div>
    )
  }

  handleDateChanged = (dateRange) => {
    this.loadCaptures(dateRange)
  }
}

export default connect(FrogMap)