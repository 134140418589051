import React, {Component} from 'react'
import { browserHistory, Link } from 'react-router'
import { Modal } from 'react-bootstrap'
import connect from '../connect'
import { toWaveForm } from 'shared/components/captures/validate'
import Sound from 'shared/components/captures/sound'
import moment from 'moment'
import { convertToHttps } from 'Admin/helpers'

const sortedBreedingSeasons = (breedingSeasons) => {
  const months = moment.monthsShort()
  let seasons = {}

  _.map(months, month => {
    const season = breedingSeasons[month]
    if(!_.isEmpty(season)){
      seasons[month] = season
    }
  })

  return seasons
}

const months = moment.monthsShort()

class FrogShow extends Component {

  componentDidMount(){
    const { params: { id }, frogActions } = this.props

    frogActions.getFrog({
      id: id,
    })

  }

  render() {
    const { frog, frog: {
      conservation_status = {},
      breeding_season = {},
      common_names = [],
      habitats = [],
      images = [],
      audios = [],
    } } = this.props
    
    return (
      <Modal className="factsheet" bsSize="lg" onHide={this.onHide} show={true}>
        <Modal.Body>
          <div className="row">
            <div className="col-md-5">
              <div className="name"><em>{frog.scientific_name}</em></div>
              <div className="caption">{common_names.join(', ')}</div>
              <hr />
              <div className="data">
                <div className="title">Conservation Status</div>
                <div className="value">
                  {
                    _.map(conservation_status, (val, key) => (
                      <div className="attrs">
                        <div className="key">
                          <a target="new" href={`https://australianmuseum.net.au/conservation-status-what-does-it-mean#${key}`}><i className="fa fa-info-circle" /></a> {_.upperCase(key)}:&nbsp;
                          </div>
                        <div className="val">{val}</div>
                      </div>
                    ))
                  }
                </div>
              </div>
              <div className="data">
                <div className="title">Calling Period</div>
                <div className="value">
                  {
                    _.map(months, month => (
                      !_.isEmpty(breeding_season[month]) &&
                      <div className="attrs">
                        <div className="key">{month}</div>
                        <div className="val">{_.startCase(breeding_season[month])}</div>
                      </div>
                    ))
                  }
                </div>
              </div>
              <div className="data">
                <div className="title">Description</div>
                <div className="value" dangerouslySetInnerHTML={{__html:frog.description}} />
              </div>
              <div className="data">
                <div className="title">Breeding Biology</div>
                <div className="value" dangerouslySetInnerHTML={{__html:frog.breeding_biology}} />
              </div>
              <div className="data">
                <div className="title">Similar Species</div>
                <div className="value" dangerouslySetInnerHTML={{__html:frog.similar_species}} />
              </div>
            </div>
            <div className="col-md-7">
              <div className="text-right">
                <Link to="/frogs"><i className="fa fa-close" /></Link>
              </div>
              <div className="data">
                <div className="title">Images</div>
                <div className="images">
                  {
                    images.map(({id, card, photographer}) =>
                      <div>
                        <img key={id} src={convertToHttps(card)} />
                        <div className="credits">Photo: {photographer}</div>
                      </div>
                    )
                  }
                </div>
              </div>
              <div className="data">
                <div className="title">Calls</div>
                <div className="audios">
                  {
                    _.isEmpty(audios) && 'No audio available'
                  }
                  {
                    _.map(audios, (audio) => (
                      <div className="frog-call">
                        <div className="player">
                          <Sound key={audio.id} url={audio.url} />
                          <img src={convertToHttps(toWaveForm(audio.url))} />
                        </div>
                        <div className="credits">by {audio.credits}</div>
                      </div>
                    ))
                  }
                </div>
              </div>
              <div className="data">
                <div className="title">Distribution</div>
                <p>{frog.distribution}</p>
                <img className="img-responsive" src={convertToHttps(frog.distribution_image, 'show frogs 1')} />
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    )
  }

  onHide = () => {
    browserHistory.goBack()
  }
}

export default connect(FrogShow)