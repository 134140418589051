import React, { Component } from 'react';
import { Modal, ButtonGroup, Button, FormGroup, FormControl } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form'
import Auth from 'j-toker';
import configureAuth from '../../../../lib/configureAuth.js';

import Alert from 'react-s-alert';
import SelectInput from '../../components/SelectInput.jsx';
import LoaderPage from '../../components/LoaderPage.jsx';
import LoaderButton from '../../components/LoaderButton.jsx';

import Navigation from 'Portal/components/Navigation.jsx'
import Footer from 'Portal/components/Footer.jsx';

class ForgotPassword extends React.Component {

  state = {
    email: '',
    message: '',
    submitting: false,
  }

  componentDidMount() {
    configureAuth();
  }

  setIsSubmitting() {
    this.setState({
      message: '',
      submitting: true
    });
  }

  handleSubmit() {

    this.setIsSubmitting();

    Auth.requestPasswordReset({
      email: this.state.email
    }).then((res) => {

      this.setState({
        submitting: false,
        message: res.message
      });

    }).fail((res) => {

      this.setState({
        submitting: false,
        message: res.data.errors.join(', ')
      });

    })
  }

  render() {
    return (
      <div className="app-portal">
        <Navigation />


        <div className="login__container">
          <h1 className="login__header">Forgot Password</h1>
          <p>Enter your email address to reset your password</p>
          <br />
          <form className="login__form">
            {
              this.state.message.length > 0 && <div className="alert alert-warning">{this.state.message}</div>
            }
            <FormGroup>
              <FormControl value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} placeholder="john@doe.com" />
            </FormGroup>
            <Button
              onClick={this.handleSubmit.bind(this)}
              className="button login__button button--green button--w-full"
              disabled={this.state.submitting}
            >
              {
                this.state.submitting ?
                  'Submitting...' :
                  'Submit'
              }
            </Button>
          </form>
        </div>
        <Footer />
      </div>
    )
  }
}

export default connect(
  // mapStateToProps
  state => ({
  }),
  // mapDispatchToProps
  dispatch => ({
  })
)(ForgotPassword);