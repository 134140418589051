import React, {Component} from 'react'
import { Link } from 'react-router'
import connect from 'Portal/containers/connect'

class Identify extends Component {
  render(){
    const { user, children } = this.props
    const { first_name, last_name, email, passed_test } = user

    return (
      <div className="panel blank-state">
        <div className="title">Hi {first_name}!</div>
        {
          passed_test ?
            <Passed /> :
            <TakeTest />
        }
      </div>
    )
  }
}

const TakeTest = props =>
  <div>
    <p>To become a frog call identifier, you’ll need to complete a quick test to see if you’re able to identify which species are calling in a recording. Note for this test, we’ve selected very clear recordings of a single species.</p>
    <p>Most recordings actually capture the calls of more than one species (sometimes more than ten!). The recordings submitted to FrogID may also be very faint, or there may be a very noisy species and other quiet frogs. For this reason, we recommend you wearing earphones and turning the volume up (to a safe volume!) so you can be sure you hear all the frog species calling. We need to make sure that we identify all the calling frog species, not just the loud ones.</p>
    <p>There are also recordings that are not of frogs- insects, birds and other critters- so listen hard! Occasionally, we also get recordings of captive (or pet) frogs- if you suspect this, please mark as “Captive” and we’ll double-check.</p>
    <p>Thank you so much for taking the test and becoming a member of the FrogID call validator team. Every call identified helps us better understand, and conserve, Australia’s unique frog species.</p>
    <Link to="/members/identify/test" className="btn-primary">Take the test</Link>
  </div>

const Passed = props =>
  <div>
    <p>You are a qualified IDer</p>
    <div className="alert alert-warning">
      <p><strong>Public Validation Terms of Use</strong></p>
      <p>Thank you for being part of FrogID. You are about to start a public validation workflow that will involve listening to publically submitted recordings from the FrogID Mobile App.
Please note the FrogID project has terms of use which users have agreed to before taking part in the project.
However, there is still a risk that while reviewing recording submitted by other members of the public, you may encounter inappropriate content. This may include the use of strong and explicit language and of content offensive to sections of the community.
By agreeing to take part in the public validation you are agreeing to listen to unverified and uncensored public call submissions from users across Australia.</p>
      <p>To read the project Terms and conditions please go to the <Link to="/terms">Terms of Use page</Link>.
        Any content you hear which is inappropriate, you can report as abusive or spam.
      </p>
      <p>Thank you for making a contribution towards conserving Australia’s unique frog species.</p>
    </div>
    <a disabled className="btn-primary">Agree &amp; Continue (Coming Soon)</a>
  </div>

export default connect(Identify)