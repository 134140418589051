import React, { Component } from 'react'
import Navigation from 'Portal/components/Navigation.jsx'
import Footer from 'Portal/components/Footer.jsx'

export default class Privacy extends Component {

  render() {
    return (
      <div className="app-portal">
        <Navigation />
        <div className="container">
          <div className="section">
            <div className="legal">
              <h1>Privacy Policy</h1>
              <p>The Australian Museum (AM) is committed to protecting your personal information, in accordance with the NSW Privacy and Personal Information Protection Act 1998 (Privacy Act) and other applicable Privacy legislation (Privacy Laws).'
This Privacy Statement outlines how AM deals with your personal information when you use AM's FrogID website, app and software (FrogID). This Privacy Statement is governed by the AM's Privacy Management Plan (PMP) which is the overarching document which outlines how AM complies with the Privacy Act and the standards outlined in the Privacy Act&#39;s Information Protection Principles. In the event that there is any inconsistency between this Privacy Statement and AM's Privacy Management Plan, the Privacy Management Plan will prevail to the extent of any inconsistency.  </p>
<ol>
<li><p>How is personal information collected?
Personal Information is any information that identifies you as an individual.  In the course of conducting its citizen scientist projects, including FrogID, AM may collect personal information from you via:</p>
<ul>
<li>AM's websites and mobile applications (apps) when you interact with them</li>
<li>you sending personal information to AM via any form of communication.
These channels reflect the main methods through which AM may collect personal information about you when you're involved with a citizen scientist project. We may also collect information about you at other times you engage with us (for example, when you attend an exhibition or an event presented by us, make a donation, visit our site, or use our services ' please refer to AM's PMP).
AM may also collect information from third parties, for example from joint collaborators (refer to section 4 below) who collect personal information from you when you engage with citizen science projects hosted by collaborators.
Collection of personal information will occur at the time you provide details to AM or its authorised agents or, in the case of electronic services, when you use our electronic services to obtain information or transact with us.</li>
</ul>
</li>
<li><p>The kinds of personal information that AM collects
If you browse AM's FrogID we may use cookies to track your browsing activities (see section 9 below).
If you browse FrogID, register as a FrogID user, update or add information to your page, provide content to FrogID through your computer, mobile device, or otherwise communicate with the Australian Museum about FrogID, we may request or require you to provide information including your name, email address, location information, photographs and other personal identifying information. 
When you submit a recording or observation to FrogID (Observation) the Australian Museum may collect your User ID, latitude and longitude of the site of the Observation, the place name of the site of the Observation, the date and time of the Observation and the time zone of the site of the Observation.  In each case, AM collects such information only insofar as is necessary or appropriate to fulfil the purpose of your interaction with FrogID and to use your Observation in a meaningful way.
The primary reason we request or require this information is so that you can establish a user profile, use the full range of functions of FrogID, contact you regarding content you upload, use the Observation data for research, educational and scientific purposes and to acknowledge your contribution to AM's citizen scientist projects. 
When you visit or use FrogID, our web servers make a record of your visit and log the following information:</p>
<ul>
<li>your computer&#39;s unique internet address.</li>
<li>the date and time of your visit.</li>
<li>the pages you accessed and documents downloaded.</li>
<li>the previous website you visited to find our website - via search engine or a link on another website.</li>
<li>the type of browser and operating system you are using.</li>
<li>the amount of time spent on our website.</li>
<li>your geographic location, type of device used, repeat visits.
This provides us with information about how FrogID is used and navigated, including number of visits, and the frequency and duration of visits. We may combine this information with information you have already provided to us to help us understand more about your preferences and interests, so that we can make future improvements to FrogID.
We may collect information about you from third parties, such as a parent or guardian authorised to act on your behalf or joint collaborators of AM. 
If you do not provide the required data with respect to a particular activity, you may not be able to use all of the FrogID functionality, we may not be able to communicate with you when necessary or we may be prevented from providing you with the relevant service. </li>
</ul>
</li>
<li><p>The purposes for which your personal information is collected, held and used by AM
AM may use your personal information to:
a. undertake citizen scientist projects, including using the information collected and submitted by users (such as location information and Observational information) for research, education, species identification and scientific purposes and your user profile information to contact you about content uploaded and acknowledge your contribution to the citizen scientist projects;
b. conduct business communications, for example:</p>
<ul>
<li>providing you with the services you have requested;</li>
<li>contacting you if we have difficulties providing FrogID;</li>
<li>sending you electronic status messages about FrogID</li>
<li>sending you notices about updates to our Terms of Use or this Privacy Statement;</li>
<li>responding to requests or enquiries made by you;
c. compile user profiles to enable AM to improve its products and services, including demonstrating FrogID;
d. conduct direct marketing (either electronically or by mail) by sending you news and updates (provided you have not opted out from receiving this material under section 5 below) including, without limitation, the following: </li>
<li>sending you marketing material (including, without limitation, requests for feedback and material promoting third party products or services);</li>
<li>conducting research or surveys;</li>
<li>for special event and fundraising purposes;
e. undertake observations and analysis of user choices, preferences, behaviour and characteristics both individually and in aggregate;
f. for system administration, protection and maintenance including identifying and controlling system abuse and preventing cyber attacks.
g. invite you to participate in user groups or research projects to improve or enhance FrogID.</li>
</ul>
</li>
<li><p>How is your personal information provided to and used by third parties?
User Profile, contact information and other Observational information is shared with and used by third party researchers, government and organisations for education and recording species information, collaborators on the project, sponsors and other entities related to the citizen science project (FrogID Related Parties).
This enables the FrogID Related Parties to use the data collected and acknowledge your contribution. FrogID Related Parties may send you marketing or research material. FrogID Related Parties may be located overseas. 
We may also share unique identifiers allocated to you with AM's media agencies, publishers and partners such as Facebook, Google and Twitter. The unique identifier may be associated with information collected when you posted information to us, called us, visited our websites or ticketing counters, used our approved agencies, used our apps, referred to us on social media, emailed or otherwise communicated with us. This is so that we can together, provide you with customised content when you visit certain websites and so we can together provide other visitors to those websites with customised advertising.
If AM provides your personal information to FrogID Related Parties, agencies, publishers or partners, these entities are responsible for complying with applicable laws governing how personal information is to be handled. Where AM in its absolute discretion decides that a new partner is required to enhance or expand FrogID, we will notify you and the act of you continuing to use FrogID will be your express consent to AM sharing your personal information with that new partner, subject to their compliance with Privacy Laws. </p>
</li>
<li><p>When and how can you opt out of receiving marketing from us?
You may request that you stop receiving marketing communications from AM and that AM stops sharing your personal information with FrogID Related Parties. We will comply with your request but you may also need to opt out from marketing from the FrogID Related Parties directly. You should also be aware that opting out of certain communications may impact our ability to provide the FrogID services to you. 
If you wish to exercise the opt out, you can do so by contacting AM on (02) 9320 6000, at calls@frogid.com.au or, if you have received a marketing email from us, by selecting the 'Unsubscribe' link at the bottom of the email.</p>
</li>
<li><p>Security of personal information
AM takes reasonable steps to protect all the personal information it receives. Information collected is stored in an appropriately secure format retained in accordance with AM's PMP. We take reasonable steps to ensure that your personal information is relevant, accurate and up to date, complete and not misleading. We aim to keep your information for no longer than is necessary for the purposes for which it can be lawfully used.  Only authorised personnel have access to our user's personal information, and handle the information in accordance with the Privacy Act.  We require that each of the FrogID Related Parties have standards and data handling protocols which also comply with Privacy Laws. </p>
</li>
<li><p>Are there other circumstances where personal information will be given to anyone else?
There are some circumstances where AM will need to or be required to disclose personal information about you. We may need to provide third party contractors and service providers with access to your personal information in order to assist us to operate FrogID or to provide a service to you (e.g. service providers that assist us to send out our mail/email to customers, provide IT services including support of our IT systems, undertake data analysis and analytics, undertake research). If AM engages such contractors and service providers to handle your personal information, we require these organisations and service providers to agree to keep your information secure, use it only for the purpose for which it has been provided, handle it in accordance with our directions and return it to us and/or destroy all copies of it when they have finished, unless they are required by law to retain it. 
There may be other cases where we advise you at the time your personal information is collected that a third party will receive that information. 
We may disclose your personal information to law enforcement bodies or to meet a legal requirement such as a subpoena.
Otherwise, AM will only disclose personal information if this is required by law, for example under the Government Information (Public Access) Act 2009 (NSW), as permitted under this Privacy Statement or AM's PMP, or permitted under the Privacy Act or the relevant Privacy Law.</p>
</li>
<li><p>How can you check and update your personal details?
You have the right to request access and correction of your personal information at any time. If you would like to update or correct your personal details, please update your FrogID user profile or contact AM via:
Email: calls@frogid.net.au (if you contact us via email, we will confirm your request has been processed)
Online: existing users may update their details online through the FrogID website
There are some circumstances under the Privacy Act when we are not obliged to provide access to the personal information we hold. If one of these situations applies to your request we will let you know. </p>
</li>
<li><p>Does AM use cookies and web beacons?
When you visit an AM website, we may store some information commonly known as a 'cookie' on your computer. A &quot;cookie&quot; is a small file containing a string of characters that is sent to your computer, tablet, mobile phone or other electronic device when you visit a website. Cookies help AM provide a more customised service (e.g. by storing user preferences in cookies) and helps you get the best out of our websites. They are also used to better understand how people use our websites (e.g. by tracking user trends and patterns of how people navigate our sites). These cookies may be used to collect visitor activity information, manage user log-ins, personal preferences, and provide you with relevant information. Depending on the browser you use, some of the cookies we use are necessary to enable us to provide services.
The AM websites use both &quot;first party&quot; cookies (cookies used by AM websites only) and &quot;third party&quot; cookies (cookies originating from a third party website). We use first party cookies for storing preferences and data needed throughout your visit to our websites (e.g. managing user log-ins, personal preferences, contents of your shopping cart) and to provide you with customised advertising. We use third party cookies for tracking user trends and patterns with the help of third party web statistics providers. These third party cookies are used exclusively by AM websites and the web statistics provider and are not shared with any other third party. 
In addition, third parties with which AM has relationships, may use cookies to provide you with advertisements that we or they consider are relevant and of interest to you. These third parties include advertisers, advertising networks and technology platforms (e.g. Google AdWords). These third parties may use cookies over which we have no control in order to provide you with customized advertising. For more information, please review the third party's privacy policy. You can contact AM's Privacy Contact Officer for a list of current advertising networks and technology platforms used by us.
We also use web beacons (also known as web bugs or clear GIFs) to record the behaviour of users visiting our websites. You can find more detailed information about cookies and web beacons and how they work at All About Cookies.
If you have downloaded an AM app onto a device, beacons may be used to collect location data about you whilst you are onsite at the AM. This data will allow us to send you notifications about how to find your way around the AM (e.g. to the closest amenities) and keep you informed of promotions running on site at that time. Your permission will be sought before this location data is collected.</p>
</li>
<li><p>Whether we are likely to disclose your information overseas
We may disclose your information to FrogID Related Parties. FrogID Related Parties may be located anywhere in the world. 
We may also permit third parties located overseas to use your information in order to provide services to us. When this happens we will put in place terms and conditions that ensure that your personal information is protected in accordance with the relevant Privacy Law.</p>
</li>
<li><p>Changes to this FrogID Privacy Statement
This FrogID Privacy Statement may change from time to time. Please regularly review this page for updates. We may also send you an electronic notification when you next log on to FrogID that changes have been made.</p>
</li>
<li><p>How to complain if you are concerned about a breach of privacy by the Australian Museum
If you have any queries or concerns about the way AM has collected, stored, used or disclosed your personal information you should write to us as soon as possible. We will investigate the matters you raise. If you are unhappy with the response you receive from us in response to a complaint you can raise the matter with the NSW Information and Privacy Commission by writing to ipcinfo@ipc.nsw.gov.au.
Address your correspondence to:
FrogID Co-ordinator
Australian Museum 
1 William St, 
Sydney NSW 2010
Or email: <a href="mailto:privacy@frogid.net.au">privacy@frogid.net.au</a></p>
</li>
</ol>
<p>Date last updated: 8 July 2019</p>

            </div>
          </div>
        </div>
        <Footer />
      </div>
    );

  }
}
