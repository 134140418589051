import React from 'react'
import { Link } from 'react-router'
import { convertToHttps } from 'Admin/helpers'

class FrogsList extends React.Component {
  render() {
    const { frogs } = this.props

    return (
      <div className="frogs-container">
        {
          frogs.map((frog, idx) => (
            <Link to={`/frogs/${frog.slug}`} key={idx} className="frog panel">
              {
                _.isEmpty(frog.cover_photo) ?
                  <div className="img-placeholder">
                    <i className="fa fa-image" />
                  </div> :
                  <img src={convertToHttps(frog.cover_photo)} />
              }
              <div className="detail">
                <div className="title"><em>{frog.scientific_name}</em></div>
                <div className="desc">
                  {_.join(frog.common_names, ', ')}
                </div>
              </div>
            </Link>
          ))
        }
      </div>
    )
  }
}

export default FrogsList