import React, { Component } from "react";

export default class TermsContent extends Component {
  render() {
    return (
      <div className="terms-content">
        <h1>Terms of use</h1>
        <p>
        These Terms of Use govern your use of the FrogID software whether through the mobile app or website (www.frogid.net.au) (FrogID). By using FrogID through any of these means you accept these Terms of Use. “You”/”your” means the FrogID user and “we”, “us” or “our” means the Australian Museum Trust trading as Australian Museum (ABN 85 407 224 698) of 1 William St, Sydney, NSW 2010 Australia (AM), as the owner and operator of FrogID throughout the world.
        </p>
        <p>
        FrogID has been developed and designed by AM to facilitate your participation in our research aimed at better understanding where Australia’s frogs are, and how a changing environment impacts them. FrogID allows users to share observations and identity frogs in Australia (Content, described more fully in section 3 below). 
        </p>
        <p>
          <strong>1. Using FrogID </strong>
        </p>
        <p>
        1.1. You may only use FrogID if: a. you agree with these Terms of Use and the Privacy Policy; b. you are at least 13. If you are under 18 years old, your legal guardian accepts these Terms of Use and the Privacy Policy on your behalf; and c. we have not previously terminated your account for breaches of our Terms of Use. 
        </p>
        <p>
        1.2. If you breach these Terms of Use, we may terminate your account without further reference to you. We retain the right to modify or delete any of your Content without further notification. 
        </p>
        <p>
          <strong>2. Registration </strong>
        </p>
        <p>
        2.1 In order to use FrogID and contribute your Content you will need to register a user account with us. 
        </p>
        <p>
        2.2 By registering an account, you acknowledge that AM collects, uses and discloses personal information about you so that AM can provide FrogID to you and administer it. More information about how your personal information is used and kept is set out in our Privacy Policy. 
        </p>
        <p>
        2.3 You represent that you own the content you submit to be displayed on your user profile page, including all text and the user avatar (profile pictures) and grant us a worldwide, irrevocable, non-exclusive, sub-licensable, royalty-free licence to reproduce, perform, communicate, publish and adapt the content submitted for your profile page in any media and format. This enables us to display your user information along with your Content in FrogID and lets others identify You and credit You for Your Content (defined below in Section 3). 
        </p>
        <p>
        2.4 The user profiles consist of basic information and optional information, which are displayed in FrogID. The basic information is mandatory in order to register and only your name and email address is displayed when you contribute your Content. 
        </p>
        <p>
        2.5 By creating an account with FrogID, you give AM your express consent to receive communications from AM by post or electronically (to the address you have nominated) regarding: (a) Operational notices regarding FrogID, your account, validation emails, updates to our Terms of Use or Privacy Policy; and (b) newsletters, other programs, events and offers related to FrogID or more generally from AM and its business partners. 
        </p>
        <p>
        2.6 You may opt-out of receiving the things set out in clause 2.4(b) by using the unsubscribe function on any electronic communications you receive from AM or by contacting calls@frogid.net.au to change your preferences. Opting out of these communications may impact AM’s ability to provide the FrogID service to you. 
        </p>
        <p>
        2.7 You may terminate your account at any time through FrogID or by contacting us at calls@frogid.net.au. You acknowledge that your Content may continue to be available (on an anonymous basis - please note that this action is permanent) online after termination. 
        </p>
        <p>
        2.8 We reserve the right to close any user account which has not been active for a period of 12 months or longer (Dormant Account) without further reference to you. If we close a Dormant Account, the Content will remain public but we will make it anonymous.
        </p>
        <p>
          <strong>3. Contributing Content </strong>
        </p>
        <p>
        3.1. You are responsible for all content you contribute and submit to FrogID (your Content), which includes, but is not limited to, text, photos, videos, audio recordings, biodiversity related information, annotations, occurrence/sighting data, geospatial/location data and records. 
        </p>
        <p>
        3.2. Your Content contributed to FrogID is displayed publicly and may be accessible via FrogID, along with the authorship information linked to your user profile and the location details at the time of recording and submitting your Content. 
        </p>
        <p>
        3.3. You will make reasonable efforts to ensure Your Content is accurate at the time you collected, created it and contributed it to FrogID. 
        </p>
        <p>
        3.4. Your Content must: i. not be defamatory, malicious, threatening, offensive, abusive, obscene, lewd, in bad taste or otherwise unlawful or violate any laws; ii. not be intentionally false or misleading; iii. not infringe the intellectual property rights of any third party; and iv. not compromise the privacy of any person or contain inappropriate personal information. 
        </p>
        <p>
        3.5. AM reserves the right in its absolute discretion to determine whether your Content should be removed, or modified with regard to section 3.4 above
        </p>
        <p>
        3.6. You retain copyright and any other rights you hold in your Content and can continue to share and commercialise your Content as you wish. 
        </p>
        <p>
        3.7. You confirm you own or have permission to use any material included in your Content and that you have the express permission of anyone appearing or performing in your Content and that you are not infringing any person’s rights by submitting the Content to us. Accordingly, you indemnify AM in the event that a third party claims that your Content infringes their intellectual property rights. 
        </p>
        <p>
        3.8. You confirm you have, where appropriate, sought the consent of the parent or guardian of any person under the age of 18 who is featured in your Content.
        </p>
        <p>
        3.9. By contributing Content to FrogID, you grant us a royalty-free, irrevocable, non-exclusive licence (including the right to sub-license to any party) to reproduce, perform, communicate, publish, adapt and otherwise use and exploit your Content (including commercially) in any manner and in any media worldwide without restriction. Such uses may include, but are not limited to, the reproduction of your photos or audio recordings on FrogID, making available your Content and derivatives of your Content, permitting use of your Content by researchers and educational institutions, use within publications, education and promotional materials (for FrogID and AM more generally), and any other exploitation without restriction. You expressly waive any right of integrity to your Content when we use it in this way. 
        </p>
        <p>
        3.10. We will endeavour to provide you with appropriate credit when using your Content, though you understand and agree this may not always be possible due to considerations of space or type of use. You acknowledge that your Content may be anonymized and used within datasets and research, and in such situations, you will not receive a credit and you expressly waive such rights to be attributed as the author. 
        </p>
        <p>
        3.11. In the interests of species preservation and protection, we reserve the right in our sole discretion not to make public certain locations of identified species from your Content. 
        </p>
        <p>
        3.12. Please be aware that your Content may remain online indefinitely, even though you may terminate your account in accordance with Section 2.4. Please ensure you keep your own copies of your Content as we may not archive, store or back-up your Content nor continue to make your Content accessible online. 
        </p>
        <p>
        3.13. If you inadvertently uploaded Content or you are concerned that your Content contained on FrogID is in breach of the Terms of Use, please email calls@frogid.net.au and detail your concerns. Please note that species data submitted by you may be retained by us in our absolute discretion and tagged as a private record (the record will not be anonymous, just not available publicly) if deemed by us that the species recorded or location of the species is important to the FrogID project. 
        </p>
        <p>
        3.14. We do not support, endorse or guarantee the accuracy, truthfulness or reliability of any content posted on or by other users of FrogID, nor do we endorse any opinions or thoughts expressed on FrogID by any users. Any comments or opinions you express on the FrogID platform are your own and AM is not responsible or liable for them. 
        </p>
        <p>
          <strong>4. Our Content </strong>          
        </p>
        <p>
        4.1. All intellectual property rights in the content, software and systems owned by or licensed to us, including logos, images, names, designs, trademarks and copyright (our Content) are reserved to us and our licensees. 
        </p>
        <p>
        4.2. Our Content is provided for your personal, non-commercial use only. Unless you have our written permission, you may not otherwise reproduce, republish, modify, adapt, translate, prepare derivative works (including datasets) from, reverse engineer or disassemble our Content. 
        </p>
        <p>
        4.3. You agree not to remove, obscure, or alter any copyright notice or trade mark on any content you access and use. You also agree not to use our Content in a way that implies endorsement by us or any person included in the materials. 
        </p>
        <p>
        4.4. If you are interested in using our Content or dataset other than for personal, non-commercial use, please contact research@frogid.net.au with details of your proposal. 
        </p>
        <p>
          <strong>5. Privacy and Data Protection </strong>
        </p>
        <p>
        5.1. Any personal information acquired by us from your participation in FrogID will be used and stored in accordance with our Privacy Policy.
        </p>
        <p>
        5.2. Our Privacy Policy forms part of these FrogID Terms of Use and it describes how we use your personal information when you use FrogID. Whenever you give us personal information you are expressly consenting to its collection and use in accordance with our Privacy Policy.
        </p>
        <p>
          <strong>6. Disclaimer </strong>
        </p>
        <p>
        6.1. FrogID, linked websites, our Content and third party submissions (including your Content) are intended primarily to provide information for general and scientific use, to assist research and public knowledge, discussion and policy development. 
        </p>
        <p>
        6.2. We do not endorse the views or material contained in the contributions of members of the public or third party sites. We are not responsible for the content, availability or performance of external sites linked to or hosting FrogID.
        </p>
        <p>
        6.3. FrogID may be terminated, suspended, limited or modified at any time without notice to you. AM does not warrant that FrogID will be available, uninterrupted, secure or error-free or that FrogID will be compatible with all devices or software which you use to access FrogID. 
        </p>
        <p>
        6.4. FrogID is provided on an “as is” and “as available” basis. AM has no obligation to resolve faults in FrogID. You understand and agree that you use FrogID at your own risk and that you will be solely responsible for any damage or loss whatsoever that results from such use to you, your devices and systems. We disclaim to the fullest extent permitted by law all guarantees, warranties or representations of merchantability, acceptable quality and fitness for any purpose, security, reliability, accuracy, timeliness, and performance of FrogID and any content contained therein. 
        </p>
        <p>
        6.5. Under no circumstances will we accept any liability to you or any other person for any loss, damage, costs and expenses that you or anyone else may suffer or incur from reliance on FrogID or the use of FrogID. This disclaimer is subject to any statutory guarantee we may be subject to at law and which in the case of services provided to consumers cannot be excluded or modified.
        </p>
        <p>
        6.6. Please contact us if you find any inaccurate, out of date or incomplete content on FrogID. If you suspect that there is content which infringes someone’s intellectual property rights, you must let us know immediately by contacting calls@frogid.net.au. 
        </p>
        <p>
          <strong>7. General </strong>
        </p>
        <p>
        7.1. These Terms of Use will be governed by and construed in accordance with the laws of the State of New South Wales which shall have exclusive jurisdiction over any disputes. If for any reason a court of competent jurisdiction finds any provision or portion of these Terms of Use to be unenforceable, the remainder of these Terms of Use will continue in full force and effect. 
        </p>
        <p>
        7.2. The presence of email addresses on FrogID does not infer consent to send unsolicited commercial electronic messages (“spam”) to those addresses. 
        </p>
        <p>
        7.3. You must not try to circumvent the security features of the FrogID website or app, or tamper with, hack into, or in some other way disrupt any computer system, server, website, router or other device used to host FrogID or make it available. 
        </p>
        <p>
        7.4. We may change these Terms of Use at any time by updating this page and by notifying you when you next log into FrogID. By continuing to use this Service or associated services indicates your acceptance of these Terms of Use. If you do not accept these Terms of Use, please do not continue to use this Service or associated services. 
        </p>
        <p>
        Terms of Use last updated <strong>8 July 2019</strong>.
        </p>
        <p>Terms of Use last updated 8 July 2019. </p>
      </div>
    );
  }
}
