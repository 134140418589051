import React, {Component} from 'react'
import { Link } from 'react-router'
import { convertToHttps } from 'Admin/helpers'

class GroupCard extends Component {
  render() {
    const { id, name, desc, avatar, isOwner, handleLeaveGroup } = this.props
    return (
      <div className="group-card">
        <div className="group-avatar">
          <img src={convertToHttps(avatar)} />
        </div>
        <div>
          <div className="name">
            {name} &nbsp;&nbsp;{ isOwner && <Link to="/members/group/edit" className="pull-right"><i className="fa fa-pencil" /></Link>}
          </div>
          <div className="desc">{desc}</div>
          <div className="link"><a onClick={handleLeaveGroup}>Leave group</a></div>
        </div>
      </div>
    )
  }
}

export default GroupCard