import React, { Component } from 'react'
import { Link } from 'react-router'
import connect from 'Portal/containers/connect'
import { InputGroupButton } from 'react-bootstrap';
import { isNil } from 'lodash';
import ProfileSharing from 'Portal/components/ProfileSharing'
import AlertPopup from 'react-s-alert';
import { convertToHttps } from 'Admin/helpers'


class GroupProfile extends Component {
    state = {
        joinGroupAlertOpen: false,
        leaveGroupAlertOpen: false,
    }

    componentDidMount() {
        this.fetchData();
    }


    fetchData = () => {
        const { usergroupActions, params: { slug } } = this.props;
        const { query } = location;

        usergroupActions.getUsergroup({ slug })
    }

    openJoinGroupAlert() {
        this.setState({
            joinGroupAlertOpen: true,
        })
    }

    openLeaveGroupAlert() {
        this.setState({
            leaveGroupAlertOpen: true,
        })
    }

    closeAlert() {
        this.setState({
            joinGroupAlertOpen: false,
            leaveGroupAlertOpen: false,
        })
    }

    render() {
        const { usergroup: group, user } = this.props;
        return (
            <>
                <div className="desktop-double-column">
                    <section className="wrapper">
                        <div className="profile-info">
                            {group.avatar &&
                                <img src={convertToHttps(group.avatar)} alt="Profile picture" className="profile-info__avatar" />
                            }
                            <h1 className='profile-info__name'>{group.name}</h1>
                            <div>
                                {group.owner_id === user.id &&
                                    <div>
                                        <Link to={"/members/group/edit/" + group.slug}>Edit group</Link>
                                    </div>
                                }
                                {/* This leave group button is now below the sharing links
                                {
                                    group.id == user.group_id &&
                                    <p><a onClick={this.handleLeave}>Leave group</a></p>
                                } */}
                            </div>
                            {group.profile_url &&
                                <div className="profile-info__sharing">
                                    <ProfileSharing profile_url={group.profile_url} display_name={group.name} />
                                </div>
                            }
                            {group.id === user.group_id
                                ? (
                                    <button
                                        className="button button--green"
                                        onClick={() => this.openLeaveGroupAlert()}
                                    >Leave group</button>
                                )
                                : (
                                    <button
                                        className="button button--green"
                                        onClick={() => this.openJoinGroupAlert()}
                                    >Join group +</button>
                                )
                            }
                        </div>
                        {/* Join Group alert (hidden by default) */}
                        <div id={group.id} className={this.state.joinGroupAlertOpen ? "alert--open" : "alert--closed"}>
                            <div className="alert__background"></div>
                            <div className="alert__dialog-box">
                                <p>Would you like to join {group.name}?</p>
                                <button className="button button--green" onClick={() => this.handleJoin(group)}>Yes</button>
                                <button className="button button--green" onClick={() => this.closeAlert()}>Cancel</button>
                            </div>
                        </div>
                        {/* Leave Group alert (hidden by default) */}
                        <div className={this.state.leaveGroupAlertOpen ? "alert--opened" : "alert--closed"}>
                            <div className="alert__background"></div>
                            <div className="alert__dialog-box">
                                <p>Would you like to leave {group.name}?</p>
                                <button className="button button--green" onClick={() => this.handleLeave()}>Yes</button>
                                <button className="button button--green" onClick={() => this.closeAlert()}>Cancel</button>
                            </div>
                        </div>
                        <div className="group-profile__descriptor">
                            <p>{group.desc}</p>
                        </div>
                    </section>
                    <section className="highlights">
                        <ul>
                            <li className="highlights__card highlights__card--yellow highlights__card--yellow-hover">
                                <div className="wrapper">
                                    <a href={"/members/group/members/" + group.slug} className="highlights__card-inner">
                                        <div className="highlights__figure">{group.users_count}</div>
                                        <div className="highlights__descriptor">
                                            Group<br />
                                            Members
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20.414" height="10.828" viewBox="0 0 20.414 10.828" className="link-arrow">
                                                <path id="Path_9" data-name="Path 9" d="M17,8l4,4m0,0-4,4m4-4H3" transform="translate(-2 -6.586)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                            </svg>
                                        </div>
                                    </a>
                                </div>
                            </li>
                            <li className="highlights__card highlights__card--orange highlights__card--orange-hover">
                                <div className="wrapper">
                                    <a href={"/members/group/captures/" + group.slug} className="highlights__card-inner">
                                        <div className="highlights__figure">{group.captures_count}</div>
                                        <div className="highlights__descriptor">
                                            Calls<br />
                                            Submitted
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20.414" height="10.828" viewBox="0 0 20.414 10.828" className="link-arrow">
                                                <path id="Path_9" data-name="Path 9" d="M17,8l4,4m0,0-4,4m4-4H3" transform="translate(-2 -6.586)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                            </svg>
                                        </div>
                                    </a>
                                </div>
                            </li>
                            <li className="highlights__card highlights__card--green">
                                <div className="wrapper">
                                    <div className="highlights__card-inner">
                                        <div className="highlights__figure">{group.published_captures_count}</div>
                                        <div className="highlights__descriptor">
                                            Verified<br />
                                            Frogs
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="highlights__card highlights__card--yellow">
                                <div className="wrapper">
                                    <div className="highlights__card-inner">
                                        <div className="highlights__figure">{group.validated_frog_species_count}</div>
                                        <div className="highlights__descriptor">
                                            Species<br />
                                            Found
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </section>
                </div>
            </>
        )
    }

    handleLeave = () => {
        const { userActions, user } = this.props;
        const group_name = user.group_name;

        userActions.updateAccount({
            group_id: null,
        }, () => {
            // Success
            AlertPopup.success('Successfully left group')
            this.closeAlert();
        })
    }

    handleJoin(group) {
      const { userActions } = this.props
      userActions.updateAccount({
        group_id: group.id,
      }, () => {
        AlertPopup.success('Successfully joined group')
        location.replace('/members/group/profile/' + group.slug);
      });
    }
}



export default connect(GroupProfile)
