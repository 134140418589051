import React from 'react'
import moment from 'moment'
import DayPicker from 'react-day-picker'
import 'react-day-picker/lib/style.css'
import './Picker.scss'
import { Dropdown, MenuItem } from 'react-bootstrap'
import { now, dateDisplayFormat, dateFormat, rangeOptions } from './options'

const begin = moment().subtract('year', 2)

class DatePicker extends React.Component {
  state = {
    from: now.clone().startOf('week'),
    to: now.clone().endOf('week'),
  }

  componentDidMount(){
    const { defaultRange } = this.props

    defaultRange && this.setDate(defaultRange)
  }

  render() {
    const { from, to } = this.state
    const dates = { fromDate: from.toDate(), toDate: to.toDate() }
    const modifiers = { start: from.toDate(), end: to.toDate() }
    const selectedDays = [dates.fromDate, { from: dates.fromDate, to: dates.toDate }]

    return (
      <div>

        <div className="btn-group">

          <Dropdown>
            <Dropdown.Toggle noCaret>
              {`${from.format(dateDisplayFormat)}`}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <li>
                <DayPicker
                  className="Selectable"
                  onDayClick={this.setFrom}
                  modifiers={modifiers}
                  selectedDays={selectedDays}
                  disabledDays={{after: dates.toDate}}
                />
              </li>
            </Dropdown.Menu>
          </Dropdown>

          <button disabled className="btn btn-default">-</button>

          <Dropdown>
            <Dropdown.Toggle noCaret>
              {`${to.format(dateDisplayFormat)}`}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <li>
                <DayPicker
                  className="Selectable"
                  onDayClick={this.setTo}
                  modifiers={modifiers}
                  selectedDays={selectedDays}
                  disabledDays={{before: dates.fromDate}}
                />
              </li>
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown>
            <Dropdown.Toggle noCaret>
              <i className="fa fa-bars" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {
                _.map(rangeOptions(begin), (val, key) => (
                  <MenuItem onClick={this.setDate.bind(this, val)}>{key}</MenuItem>
                ))
              }
            </Dropdown.Menu>
          </Dropdown>
        </div>

      </div>
    )
  }

  setFrom = (day, {disabled}) => {
    if(disabled){ return }
    this.setDate({from: moment(day)})
  }

  setTo = (day, {disabled}) => {
    if(disabled){ return }
    this.setDate({to: moment(day)})
  }

  setDate = ({from, to}) => {
    const { onChange } = this.props
    const { from: fromState, to: toState } = this.state

    const dateRange = {
      from: from || fromState,
      to: to || toState,
    }

    this.setState(dateRange)
    onChange({
      from: dateRange.from.format(dateFormat),
      to: dateRange.to.format(dateFormat),
    })
  }


}

export default DatePicker