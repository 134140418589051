import React from 'react'
import connect from 'Portal/containers/connect'
import GroupCaptureList from 'Portal/components/Captures/GroupCaptureList'
import Pagination from "Portal/components/Pagination";
import PaginatedList from "Portal/components/PaginatedList";


class GroupCaptures extends PaginatedList {

  state = {
    total_pages: null,
    total_count: null,
    rpp: null,
    page: null,
    captures_exporting: null,
    alertClosed: true,
  };

  componentDidMount() {
    this.updateListData();
  }

  handleQueryChanged() {
    this.updateListData();
  }

  fetchData(query) {
    const { usergroupActions, groupCaptureActions, params: { slug } } = this.props

    usergroupActions.getUsergroup({ slug })

    return groupCaptureActions.fetchGroupcaptures(
      { slug },
      { query: query }
    )
      .then(res => {
        this.setState({
          group_name: slug
        });
        return res;
      });
  }

  requestCaptureExport = (e) => {
    fetch("/api/user/groups/" + this.props.usergroup.slug + "/export_captures", { method: "POST" })
      .then(res => res.json())
      .then(
        (result) => {
          // display the popup for a short while.
          this.setState({ captures_exporting: true });
          setTimeout(() => {
            this.setState({ captures_exporting: false });
          }, 2000);
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
    this.setState({ alertClosed: !this.state.alertClosed })
  }

  closeDialog() {
    this.setState({ alertClosed: true })
  }

  render() {
    const { total_pages, page, group_name } = this.state;

    const { groupCapturesState, groupCaptures, usergroup: group, user } = this.props;
    const pagination = <Pagination
      page={page}
      pageCount={total_pages}
      onPageChange={page => this.handlePageChange(page)}
      loading={groupCapturesState.isFetching}
    />;

    return (
      <>
        {/* Export alert box */}
        <div className={this.state.alertClosed ? "alert--closed" : "alert--opened"}>
          <div className="alert__background">
          </div>
          <div className="alert__dialog-box">
            <button className="alert__close-button" onClick={this.closeDialog.bind(this)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="10.599" height="10.599" viewBox="0 0 10.599 10.599">
                <path id="Path_75" data-name="Path 75" d="M6,13.77,13.77,6M6,6l7.77,7.77" transform="translate(-4.586 -4.586)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
              </svg>
            </button>
            <p>Your captures are being exported and will be sent to your email shortly.</p>
            <p className="alert__footnote">
              This represents "live" FrogID data which should be treated as unpublished preliminary data as it has not yet undergone our annual data cleaning process. For the latest published FrogID dataset, please refer to www.frogid.net.au/explore or consult your state’s wildlife atlas. FrogID data is exported to the Atlas of Living Australia and state wildlife atlases following extensive annual data cleaning. To prevent duplication, please do not upload FrogID data to these platforms.
            </p>
          </div>
        </div>

        <div className="wrapper">
          <a href={"/members/group/profile/" + group.slug} className="return-link">← Return to group page</a>
          <h1 className="page-heading">{`Group Captures: ${group?.name}`}</h1>
          {group.owner_id === user.id &&
            <div>
              <button className="captures-table__export">
                <a href="#" onClick={this.requestCaptureExport.bind(this)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="15.558" height="15.649" viewBox="0 0 15.558 15.649" className="export-icon">
                    <g id="Group_4" data-name="Group 4" transform="translate(-14.424 -191.037)">
                      <g id="Group_3" data-name="Group 3" transform="translate(15.424 192.204)">
                        <path id="Path_55" data-name="Path 55" d="M275.457,78.378l-7.234,7.32" transform="translate(-262.313 -78.131)" fill="none" stroke="#418338" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2" />
                        <path id="Path_56" data-name="Path 56" d="M358.277,70.974h4.365v4.336" transform="translate(-349.364 -70.974)" fill="none" stroke="#418338" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2" />
                        <path id="Path_57" data-name="Path 57" d="M96.953,129.024h-6V140.57H102.5v-6" transform="translate(-90.955 -127.089)" fill="none" stroke="#418338" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2" />
                      </g>
                    </g>
                  </svg>
                  Export group captures
                </a>
                {
                  this.state.captures_exporting && <span>Your captures are being exported and will be emailed to you soon</span>
                }
              </button>
            </div>
          }
          {pagination}
          <GroupCaptureList captures={groupCaptures} base_url='/members/group/capture/' />
          {pagination}
        </div>
      </>
    )
  }
}

export default connect(GroupCaptures)
