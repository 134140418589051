import React from 'react'
import { withGoogleMap, GoogleMap, Circle } from 'react-google-maps'
import './map.scss'

class IdentifyMap extends React.Component {
  render() {
    const { rand_lat: lat, rand_lng: lng, rand_radius } = this.props.capture

    return (
      <GoogleMapHOC
        defaultCenter={{lat, lng}}
        containerElement={
          <div className="Identify-map-container" />
        }
        mapElement={
          <div className="Identify-map" />
        }
      >
        <Circle
          center={{lat, lng}}
          radius={100000}
          options={{
            fillColor: '#2ed573',
            fillOpacity: 0.35,
            strokeColor: '#2ed573',
            strokeOpacity: 1.0,
            strokeWeight: 1.0,
          }}
        />
      </GoogleMapHOC>
    )
  }
}

export default IdentifyMap

const GoogleMapHOC = withGoogleMap(props => {
  const { defaultCenter } = props

  return (
    <GoogleMap
      defaultZoom={5}
      defaultCenter={defaultCenter}
      options={{
        scrollwheel: false,
        navigationControl: false,
        streetViewControl: false,
        fullscreenControl: false,
        disableDoubleClickZoom: true,
        disableDefaultUI: true,
      }}
    >
      {props.children}
    </GoogleMap>
  )
})