import React, { Component } from 'react'
import { Link } from 'react-router'
import BadgesGrid from 'Portal/components/Badges/Grid'
import ProfileSharing from 'Portal/components/ProfileSharing'
import Footer from 'Portal/components/Footer'
import connect from 'Portal/containers/connect'
import moment from 'moment'
import { convertToHttps } from 'Admin/helpers'

const dateFormat = 'MMMM Do YYYY'


class Profile extends Component {
  componentDidMount() {
    window.smuCreateUserEmbed("#userRecordsEmbed");
  }

  render() {
    const { user } = this.props
    const { avatar, email, first_name, last_name, created_at, total_capture_count, validated_captures_count, frog_species_count, badge_statistics, group_id, group_name, group_slug, owned_groups, profile_url } = user
    const display_name = first_name ? `${first_name} ${last_name}` : email
    const joined_date = moment(created_at).format(dateFormat)
    const { badge_details, user_recent_badges } = user.badge_statistics
    const recent_badge_count = user.badge_statistics.user_recent_badges.length

    return (
      <>
        <div className="desktop-double-column">
          <section className="wrapper">
            <div className="profile-info">
              <div className="profile-info__header">
                <img src={convertToHttps(avatar)} alt="Profile picture" className="profile-info__avatar" />
                <div className="profile-info__header-right">
                  <h1 className='profile-info__name'>{display_name}</h1>
                  <Link to="/members/profile/edit">Edit profile</Link>
                  {recent_badge_count > 0 &&
                    <ul className="profile-info__new-badges-list">
                      {_.times(recent_badge_count, idx => {
                        let badge_name = user_recent_badges[idx]
                        let { image_suffix } = badge_details[badge_name]
                        return <li className="profile-info__new-badges-item">
                          <img
                            key={idx}
                            src={"/images/badges/FrogID-badge-new-" + image_suffix + ".png"}
                            alt={badge_name + " badge"} className="profile-info__new-badges" />
                        </li>
                      })}
                    </ul>
                  }
                </div>
              </div>
              <div className="profile-info__sharing">
                <p>Use the links below to share your public profile, including your FrogID data and achievements.</p>
                <ProfileSharing profile_url={profile_url} display_name={display_name} />
              </div>
              <div>
                <h2 className="profile-info__groups-heading">Group memberships</h2>
                <ul>
                  {
                    owned_groups.map(owned_group => (
                      <li key={owned_group.id}>
                        <p>
                          <Link to={"/members/group/profile/" + owned_group.slug} className="profile-info__sharing-button">{owned_group.name}</Link>
                          {owned_group.slug == group_slug ?
                            "(Owner and Member)" : "(Owner)"
                          }
                        </p>
                      </li>
                    ))
                  }
                  {
                    group_slug && !owned_groups.map(owned_group => owned_group.slug).includes(group_slug) && // group not listed above
                    <li>
                      <p><Link to={"/members/group/profile/" + group_slug} className="profile-info__sharing-button">{group_name}</Link> (Member)</p>
                    </li>
                  }
                </ul>
              </div>
            </div>
          </section>
          <section className="highlights">
            <ul>
              <li className="highlights__card highlights__card--orange highlights__card--orange-hover">
                <div className="wrapper">
                  <a href="/members/profile/captures/list" className="highlights__card-inner">
                    <div className="highlights__figure">{total_capture_count}</div>
                    <div className="highlights__descriptor">
                      Captures<br />
                      Submitted
                      <svg xmlns="http://www.w3.org/2000/svg" width="20.414" height="10.828" viewBox="0 0 20.414 10.828" className="link-arrow">
                        <path id="Path_9" data-name="Path 9" d="M17,8l4,4m0,0-4,4m4-4H3" transform="translate(-2 -6.586)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                      </svg>
                    </div>
                  </a>
                </div>
              </li>
              <li className="highlights__card highlights__card--green">
                <div className="wrapper">
                  <div className="highlights__card-inner">
                    <div className="highlights__figure">{validated_captures_count}</div>
                    <div className="highlights__descriptor">
                      Verified<br />
                      Frogs
                    </div>
                  </div>
                </div>
              </li>
              <li className="highlights__card highlights__card--yellow">
                <div className="wrapper">
                  <div className="highlights__card-inner">
                    <div className="highlights__figure">{frog_species_count}</div>
                    <div className="highlights__descriptor">
                      Species<br />
                      Found
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </section>
        </div>

        <section className="map">
          <div className="wrapper">
            <h2 className="section-heading">
              <a href="/members/profile/captures/list">Map of your captures</a>
              <a href="/members/profile/captures/list">
                <svg xmlns="http://www.w3.org/2000/svg" width="20.414" height="10.828" viewBox="0 0 20.414 10.828" className="link-arrow">
                  <path x="Path_9" data-name="Path 9" d="M17,8l4,4m0,0-4,4m4-4H3" transform="translate(-2 -6.586)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                </svg>
              </a>
            </h2>
            <div className="embed" id="userRecordsEmbed"></div>
            <a href="/explore">View the map of all users' captures</a>
          </div>
        </section>
        <section>
          <div className="wrapper">
            <h2 className="section-heading">
              <a href="/members/profile/badges">My badges</a>
              <a href="/members/profile/badges">
                <svg xmlns="http://www.w3.org/2000/svg" width="20.414" height="10.828" viewBox="0 0 20.414 10.828" className="link-arrow">
                  <path id="Path_9" data-name="Path 9" d="M17,8l4,4m0,0-4,4m4-4H3" transform="translate(-2 -6.586)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                </svg>
              </a>
            </h2>
          </div>
          <div className="wrapper">
            <BadgesGrid badge_statistics={badge_statistics} />
          </div>
        </section>
        <Footer />
      </>
    )
  }
}

export default connect(Profile)
