import React, { Component } from "react";
import Navigation from "Portal/components/Navigation.jsx";
import Footer from "Portal/components/Footer.jsx";
import Content from './TermsContent';

export default class Terms extends Component {
  render() {
    return (
      <div className="app-portal">
        <Navigation />
        <div className="container">
          <div className="section">
            <div className="legal">
              <Content />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
