import React, {Component} from 'react'
import connect from '../connect'
import { normalizedGroups } from './TopGroupsList'

class TopGroups extends Component {
  componentDidMount(){
    const { leaderboardActions } = this.props
    leaderboardActions.fetchLeaderboards()
  }

  render() {
    const { leaderboard } = this.props

    return (
      <div className="groups-top">
        <div className="group">
          <div className="title">Calls Submitted</div>
          <div className="count">{leaderboard.captures_count}</div>
        </div>
        <div className="group">
          <div className="title">Verified Frogs</div>
          <div className="count">{leaderboard.published_frogs_count}</div>
        </div>
        <div className="group">
          <div className="title">Species Found</div>
          <div className="count">{leaderboard.published_species_count}</div>
        </div>
      </div>
    )
  }
}

export default connect(TopGroups)