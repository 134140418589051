let hostUrl = "https://stage.frogid.com.au/api/user"; // Change to production when ready

switch (process.env.NODE_ENV) {
  case "production":
    hostUrl = "https://stage.frogid.net.au/api/user";
    break;
  case "staging":
    hostUrl = "https://stage.frogid.net.au/api/user";
    break;
  case "development":
    if (window.location.origin.includes("127.0.0.1")) {
      hostUrl = "http://127.0.0.1:3000/api/user";
    } else {
      hostUrl = "http://localhost:3000/api/user";
    }
    break;
}

export default hostUrl;
