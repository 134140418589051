import React from 'react';
import Navigation from 'Portal/components/Navigation.jsx';
import Footer from 'Portal/components/Footer.jsx';

class ExplorePage extends React.Component {
  componentDidMount() {
    window.smuCreateEmbed("#recordsEmbed");
  }

  render() {
    return (
      <div className="app-portal app-explore">
        <Navigation />
        <div className="container">
          <div className="section">
            <div className="detail text-center">
              <div className="embed" id="recordsEmbed"></div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}

export default ExplorePage