import React, { Component } from 'react'
import connect from 'Portal/containers/connect'
import ProfileSharing from 'Portal/components/ProfileSharing'
import { convertToHttps } from 'Admin/helpers'


class GroupProfilePublic extends Component {

  state = {
    public_group : {}
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    const { groupActions, location } = this.props
    const { pathname } = location;

    const path_parts = pathname.split("/");
    const group_slug = path_parts[path_parts.length - 1];

    fetch("/api/user/groups/" + group_slug)
    .then(res => res.json())
    .then(
      (result) => {
        this.setState({public_group: result});
        // window.smuCreateUserEmbed("#userRecordsEmbed", {
          // userId: user_id,
        // });
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        this.setState({
          isLoaded: true,
          error
        });
      }
    )
  }


    render() {
        const { public_group } = this.state;
        return (
            <>
                <div className="desktop-double-column">
                    <section className="wrapper">
                        <div className="profile-info">
                            { public_group.avatar &&
                                <img src={ convertToHttps(public_group.avatar) } alt="Profile picture" className="profile-info__avatar" />
                            }
                            <h1 className='profile-info__name'>{ public_group.name }</h1>
                            {
                              public_group.profile_url &&
                              <div className="profile-info__sharing">
                                <ProfileSharing profile_url={public_group.profile_url} display_name={public_group.name} />
                              </div>
                            }
                        </div>
                        <div className="group-profile__descriptor">
                            <p>{ public_group.desc }</p>
                        </div>
                    </section>
                    <section className="highlights">
                    <ul>
                        <li className="highlights__card highlights__card--yellow highlights__card--yellow-hover">
                          <div className="wrapper">
                            <a href={"/p/group/members/" + public_group.slug} className="highlights__card-inner">
                                <div className="highlights__figure">{ public_group.users_count }</div>
                                <div className="highlights__descriptor">
                                    Group<br />
                                    Members
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20.414" height="10.828" viewBox="0 0 20.414 10.828" className="link-arrow">
                                        <path id="Path_9" data-name="Path 9" d="M17,8l4,4m0,0-4,4m4-4H3" transform="translate(-2 -6.586)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                    </svg>
                                </div>
                            </a>
                          </div>
                        </li>
                        <li className="highlights__card highlights__card--orange highlights__card--orange-hover">
                            <div className="wrapper">
                                <div className="highlights__card-inner">
                                  <div className="highlights__figure">{ public_group.captures_count }</div>
                                  <div className="highlights__descriptor">
                                      Calls<br />
                                      Submitted
                                  </div>
                                </div>
                            </div>
                        </li>
                        <li className="highlights__card highlights__card--green">
                            <div className="wrapper">
                                <div className="highlights__card-inner">
                                    <div className="highlights__figure">{ public_group.published_captures_count }</div>
                                    <div className="highlights__descriptor">
                                        Verified<br />
                                        Frogs
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="highlights__card highlights__card--yellow">
                            <div className="wrapper">
                                <div className="highlights__card-inner">
                                    <div className="highlights__figure">{ public_group.validated_frog_species_count }</div>
                                    <div className="highlights__descriptor">
                                        Species<br />
                                        Found
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </section>
                </div>
            </>
        )
    }

}


export default connect(GroupProfilePublic)
