import React, { Component } from 'react'
import { Link, browserHistory } from 'react-router'
import Navigation from 'Portal/components/Navigation.jsx'
import Footer from 'Portal/components/Footer.jsx'
import { FormInput, FormAutocomplete } from 'Portal/components/FormInputs'
import connect, { Field, Resolve } from 'Portal/containers/form'

class SignUp extends Component {

  componentDidMount() {
    const { groupActions, params: { group_id }, change } = this.props
    change('group_id', parseInt(group_id))
    groupActions.fetchGroups()

    grecaptcha.ready(() => {
      grecaptcha.render(this.recaptcha)
    })
    if (!document.getElementById("subscribe_checkbox").checked){
      // check the checkbox on load if not already checked
      document.getElementById("subscribe_checkbox").click();
    }
  }

  render() {
    const {
      currentValues: { accepted_terms },
      userState: { full_messages = [], isFetching },
      groupsState, groups,
    } = this.props
    const group_options = _.map(groups, (group) => ({ label: group.name, value: group.id }))

    return (
        <div className="app-portal">
          <Navigation />
          <div className="wrapper">
            <h1 className="login__header">Sign up for an account</h1>
            <br />
            {
              !_.isEmpty(full_messages) &&
              <div className="alert alert-warning">
                {
                  full_messages.map((message) => (
                    <p>{message}</p>
                  ))
                }
              </div>
            }
            <div className="login__container">
              <form onSubmit={this.onSubmit} className="login__form">
                <div className="form-group">
                  <label>Email</label>
                  <Field autoFocus name="email" component={FormInput} />
                </div>
                <div className="form-group">
                  <label>Password</label>
                  <Field type="password" name="password" component={FormInput} />
                </div>
                <div className="form-group">
                  <label>First Name</label>
                  <Field name="first_name" component={FormInput} />
                </div>
                <div className="form-group">
                  <label>Last Name</label>
                  <Field name="last_name" component={FormInput} />
                </div>
                <div className="form-group">
                  <label>Age Group</label>
                  <Field
                    name="age_group"
                    options={[{ label: 'Under 18', value: 'under_18' }, { label: '18 & over', value: 'over_18' }]}
                    component={FormAutocomplete}
                  />
                </div>
                <div className="form-group">
                  <label>Postcode</label>
                  <Field name="postcode" component={FormInput} />
                </div>
                <div className="form-group">
                  <label>Join an existing group</label>
                  <Field
                    isLoading={groupsState.isFetching}
                    options={group_options}
                    name="group_id"
                    placeholder="Start typing to search for a group..."
                    component={FormAutocomplete} />
                </div>
                <div className="form-group">
                  <label className="checkbox-label">
                    <Field id='subscribe_checkbox' type="checkbox" name="subscribe_to_email" component={FormInput} />
                    Yes, subscribe me to the monthly FrogID newsletter?</label>
                </div>
                <hr />
                <div className="form-group">
                  <label className="checkbox-label">
                    <Field type="checkbox" name="private" component={FormInput} />
                    Make my profile private?</label>
                </div>
                <hr />
                <div className="form-group">
                  <label className="checkbox-label">
                    <Field type="checkbox" name="accepted_terms" component={FormInput} /> I agree to&nbsp;<Link to="/terms">terms and conditions</Link>
                  </label>
                </div>
                <div style={{ marginBottom: '15px' }} class="g-recaptcha" ref={ref => this.recaptcha = ref} data-sitekey="6LcXeVwUAAAAANfeq3Tzu6sasvvgh5UdhhklYnDT"></div>
                <button type="submit" disabled={!accepted_terms || isFetching} className="login__button button button--green button--w-full">
                  {isFetching ? 'Loading...' : 'Sign up'}
                </button>
              </form>
              <Link to="/login">Want to login instead?</Link>
            </div>
          </div>
          <Footer />
        </div>
    )
  }

  onSubmit = (e) => {
    const { currentValues, userActions } = this.props
    
    e.preventDefault()
    userActions.signUp({
      ...currentValues,
      grecaptcha_token: grecaptcha.getResponse(),
      success: (res) => {
        if ( res.data.confirmed_email ){
          window.location = '/members'
        } else {
          window.location = '/verify-email'
        }
      }
    })
  }
}

const form = {
  name: 'signup',
  fields: [
    'email',
    'password',
    'first_name',
    'last_name',
    'accepted_terms',
    'age_group',
    'postcode',
    'private',
    'group_id',
    'subscribe_to_email'
  ]
};

export default connect(SignUp, form);
