import React from "react";
import { IndexLink, Link, browserHistory } from "react-router";
import Navigation from "Portal/components/Navigation";
import Footer from "Portal/components/Footer";
import TopSchoolsList from "Portal/containers/leaderboards/TopSchoolsList";
import { scrollTo } from "shared/scrollTo";

export default class Page extends React.Component {
  componentDidMount() {
    const cl = cloudinary.Cloudinary.new({
      cloud_name: "ausmus",
      secure: true
    });
    cl.videoPlayer("video-player");
  }

  render() {
    return (
      <div className="app-portal schools">
        <Navigation />

        <div className="home-feature">
          <div className="container">
            <div className="feature-inner hor">
              <div className="detail">
                <h1>Schools &amp; Education</h1>
                <p>
                  Teachers can register a class of students and use the FrogID
                  classroom resources to contribute to FrogID.
                </p>
                <div className="steps get-involved">
                  <div className="step">
                    <p className="title">School Class Sign Up Steps</p>
                    <img className="img-icon" src="/images/04.png" />
                    <p>
                      To get your class and students involved in FrogID follow
                      the steps outlined below.
                    </p>
                    <a
                      onClick={() => scrollTo("#signup_steps")}
                      className="btn btn-primary"
                    >
                      See Steps
                    </a>
                  </div>
                  <div className="step">
                    <p className="title">Classroom Resources</p>
                    <img className="img-icon" src="/images/05.png" />
                    <p>
                      We have also created a number of education assets for use
                      in your classroom and outside.
                    </p>
                    <a
                      onClick={() => scrollTo("#resources")}
                      className="btn btn-primary"
                    >
                      Discover
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section videos schools-video text-center">
          <div className="container">
            <div className="section">
              <div className="detail">
                <h2 className="video-title">
                  Join the National Frog Pond Building Project
                </h2>
                <p className="video-caption">
                  The Australian Museum and Bunnings have partnered together on the National Frog Pond Building Project. 
                  Together we want to engage schools across Australia by assisting them to build a ‘frog-friendly’ pond to help Australia’s frog populations thrive. 
                </p>
                <p className="video-caption">
                  Students can get involved by learning about their local biodiversity, monitoring the frog pond and recording frog calls using the FrogID app. 
                  The project is also supported by educational materials and digital resources to highlight the importance of frogs in the environment. 
                  Bunnings can help your school create a frog-friendly pond! 
                </p>
                <p className="video-caption">
                  Contact the Activities Organiser at your <a href="https://www.bunnings.com.au/stores">local store</a> for more information.
                </p>
                <br />
                <div className="video-player-container">
                  <video
                    id="video-player"
                    controls
                    autoplay
                    data-cld-public-id="Frog_Habitat_v2_zf04vw"
                    className="video-player"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section-gray">
          <div id="resources" className="container">
            <div className="section">
              <div className="desc text-center">
                <h2>Classroom Resources</h2>
              </div>

              <div className="section-columns">
                <div className="row">
                  <div className="col-md-4">
                    <h2>Australian Frogs</h2>
                    <img
                      className="img-responsive"
                      src="https://res.cloudinary.com/beaconmaker/image/upload/c_fit,h_450,w_680/v1509831012/education_02_tyzpab.jpg"
                    />
                    <p>
                      Introduce students to the diversity of Australian Frogs
                    </p>
                    <a
                      target="new"
                      href="http://res.cloudinary.com/ausmus/raw/upload/v1525813956/australian_frogs_2018_dsdp9v.PDF"
                    >
                      Download PDF
                    </a>
                  </div>

                  <div className="col-md-4">
                    <h2>What is a Frog?</h2>
                    <img
                      className="img-responsive"
                      src="https://res.cloudinary.com/beaconmaker/image/upload/c_fit,h_450,w_680/v1509831012/education_03_ajc6fh.jpg"
                    />
                    <p>
                      Introduce students to the classification of frogs and
                      toads
                    </p>
                    <a
                      target="new"
                      href="http://res.cloudinary.com/ausmus/raw/upload/v1525813959/what_is_a_frog_2018_ao8f2g.PDF"
                    >
                      Download PDF
                    </a>
                  </div>

                  <div className="col-md-4">
                    <h2>Frog Habitats and Adaptation</h2>
                    <img
                      className="img-responsive"
                      src="https://res.cloudinary.com/beaconmaker/image/upload/c_fit,h_450,w_680/v1509831012/education_04_faom5t.jpg"
                    />
                    <p>
                      Introduce students to the concepts of habitats,
                      adaptations and food webs
                    </p>
                    <a
                      target="new"
                      href="http://res.cloudinary.com/ausmus/raw/upload/v1525813963/frog_habitat_adaptation_2018_gtpkei.PDF"
                    >
                      Download PDF
                    </a>
                  </div>
                </div>

                <br />
                <br />

                <div className="row">
                  <div className="col-md-4">
                    <h2>What is a Frog?</h2>
                    <img
                      className="img-responsive"
                      src="/images/education-6.jpg"
                    />
                    <p>Powerpoint presentations slides to learn about Frogs</p>
                    <a target="new" href="/images/what-are-frogs-slides.pptx">
                      Download Slides
                    </a>
                  </div>

                  <div className="col-md-4">
                    <h2>How to Make a Frog Pond</h2>
                    <img
                      className="img-responsive"
                      src="/images/education-5.jpg"
                    />
                    <p>
                      Powerpoint presentations slides to learn how to build a
                      frog pond
                    </p>
                    <a target="new" href="/images/frog-pond-workshop.ppt">
                      Download Slides
                    </a>
                  </div>

                  <div className="col-md-4">
                    <h2>Introduction to Habitats</h2>
                    <img
                      className="img-responsive"
                      src="/images/education-4.jpg"
                    />
                    <p>
                      Powerpoint presentations slides to learn about frog
                      habitats
                    </p>
                    <a
                      target="new"
                      href="/images/introducation-to-habitiats-slides.pptx"
                    >
                      Download Slides
                    </a>
                  </div>
                </div>
              
                <br />
                <br />

                <div className="row">
                  <div className="col-md-4">
                    <h2>Museum in a Box <br />education kit</h2>
                    <img
                      className="img-responsive"
                      src="/images/miab.png"
                    />
                    <p>Download our information sheets all about frogs!</p>
                    <a target="new" href="/images/_MIAB_frogID_panels.pdf">
                      Download PDF
                    </a>
                  </div>

                  <div className="col-md-4">
                    <h2>FrogID activity sheet <br />(A3)</h2>
                    <img
                      className="img-responsive"
                      src="/images/activity_sheet.png"
                    />
                    <p>
                      Download our fun activity sheet!
                    </p>
                    <a target="new" href="/images/FrogID-activity_Sheet_A3v6_FINAL.pdf">
                      Download PDF
                    </a>
                  </div>

                  <div className="col-md-4">
                    <h2>Create a Frog Habitat information sheet</h2>
                    <img
                      className="img-responsive"
                      src="/images/habitat.png"
                    />
                    <p>
                      Download our information sheets all about habitats for frogs
                    </p>
                    <a
                      target="new"
                      href="/images/J4921_FrogID_Habitat_poster_07.pdf"
                    >
                      Download PDF
                    </a>
                  </div>
                </div>
              
              </div>
            </div>
          </div>
        </div>

        <div className="frog-count">
          <div className="container">
            <div className="section">
              <div className="section-inner">
                <div className="detail hor text-center">
                  <h2>Schools Leaderboard</h2>
                  <p>by number of captures submitted</p>
                  <TopSchoolsList />
                  <br />
                  <div>
                    <p>
                      <Link to="/signup">Create a Class Group</Link> to
                      contribute to FrogID.
                    </p>
                  </div>
                  <br />
                  <br />
                </div>
              </div>
            </div>
          </div>

          <div className="section-gray">
            <div id="signup_steps" className="container">
              <div className="section">
                <div className="section-inner">
                  <div className="detail hor text-center">
                    <h2>School Class Sign Up Steps</h2>
                  </div>
                </div>
              </div>

              <div className="schools-step">
                <div className="row">
                  <div className="col-md-3">
                    <img className="img-responsive" src="/images/no-1.jpg" />
                  </div>
                  <div className="col-md-9">
                    <h2>Create account &amp; group</h2>
                    <ul>
                      <li>
                        Go to the <Link to="/signup">Create a new account</Link>{" "}
                        page and sign up
                      </li>
                      <li>
                        Login with your new account and go to the{" "}
                        <Link to="/members/group">My Group</Link> section within
                        the <Link to="/members/profile/show">Profile Area</Link>
                      </li>
                      <li>
                        Create a new Class Group, ensuring to include the full
                        Class name
                      </li>
                      <li>
                        Check the "Is this a School Group" option and enter the
                        School name
                      </li>
                      <li>
                        Ensure you select "Create Group" button for the group to
                        be saved
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="schools-step">
                <div className="row">
                  <div className="col-md-3">
                    <img className="img-responsive" src="/images/no-2.jpg" />
                  </div>
                  <div className="col-md-9">
                    <h2>
                      Instruct Students to{" "}
                      <Link to="/signup">create account</Link> &amp; join class
                    </h2>
                    <ul>
                      <li>
                        Instruct students to{" "}
                        <Link to="/signup">Create an account</Link> on the
                        FrogID website
                      </li>
                      <li>
                        When creating their accounts students can search the
                        list of class groups and join the newly created group
                      </li>
                      <li>
                        Students can also join their class group from their{" "}
                        <Link to="/members/profile">profile</Link> page on the
                        FrogID website
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="schools-step">
                <div className="row">
                  <div className="col-md-3">
                    <img className="img-responsive" src="/images/no-3.jpg" />
                  </div>
                  <div className="col-md-9">
                    <h2>Download the FrogID App, Sign In &amp; Record</h2>
                    <ul>
                      <li>
                        Student can download the FrogID app for{" "}
                        <a target="new" href="http://bit.ly/Frogid-ios">
                          iOS
                        </a>{" "}
                        or{" "}
                        <a
                          target="new"
                          href="
https://play.google.com/store/apps/details?id=au.net.australianmuseum.frogid"
                        >
                          Android
                        </a>
                      </li>
                      <li>
                        Students should sign in to the app using the new account
                        created
                      </li>
                      <li>
                        Go out into the field to start recording frogs,
                        following the safetly guidelines outlined at the
                        beginning of the app
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="schools-step">
                <div className="row">
                  <div className="col-md-3">
                    <img className="img-responsive" src="/images/no-4.jpg" />
                  </div>
                  <div className="col-md-9">
                    <h2>Check Leaderboard for Results</h2>
                    <ul>
                      <li>
                        Check the <a>Schools Leaderboard</a> for the latest
                        submission activity and to track your class group
                        progress on the project.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="section">
                <div className="section-inner">
                  <div className="detail hor text-center">
                    <p>
                      Download the detailed{" "}
                      <a href="http://res.cloudinary.com/beaconmaker/image/upload/v1510122043/How-To-Use-FrogId_vrg0gm.pdf">
                        How to guide.
                      </a>
                    </p>
                    <p>
                      Have a question? See our{" "}
                      <Link to="/faq">FAQs section</Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}
