import React from 'react'
import './Player.scss'
import 'wavesurfer.js'
import Wavesurfer from 'react-wavesurfer'

class Player extends React.Component {
  state = {
    playing: false,
    ready: false,
  }

  render() {
    const { url } = this.props
    const { playing, ready } = this.state
    const mp3 = url ?
      url.replace('http', 'https')
        .replace('.m4a', '.mp3')
        .replace('.aac', '.mp3') :
      null

    return (
      mp3 ? <div className="AudioPlayer Mini">
        <div className="AudioPlayer-Player">
          {!ready && <div className="Player-loading">Loading...</div>}
          <Wavesurfer
            audioFile={mp3}
            onReady={this.onReady}
            playing={playing}
          />
        </div>
        <div className="AudioPlayer-Actions">
          <div>
            {
              playing ?
                <button onClick={this.onStop} className="btn btn-primary"><i className="fa fa-stop" /></button> :
                <button onClick={this.onPlay} className="btn btn-primary"><i className="fa fa-play" /></button>
            }
          </div>
        </div>
      </div> : null
    )
  }

  onPlay = (e) => {
    e.preventDefault()

    this.setState({
      playing: true
    })
  }

  onStop = (e) => {
    e.preventDefault()

    this.setState({
      playing: false
    })
  }

  onReady = () => {
    this.setState({ready: true})
  }
}

export default Player