import React from 'react'
import data from './data'
import MiniPlayer from 'shared/components/Audio/MiniPlayer'
import connect from 'Portal/containers/connect'

const correctAnswers = (selectedAnswers) => _.filter(_.values(selectedAnswers), {correct: true})
const passed = (correctAnswers) => correctAnswers.length === data.length

class IdentifyTest extends React.Component {
  state = {
    qIdx: 0,
    selectedAnswers: {},
    message: null,
    finished: false,
  }

  render() {
    const { qIdx, message, finished, selectedAnswers } = this.state
    const question = data[qIdx] || {}
    const answers = question.answers || []

    console.log(question)

    return (
      finished ?
        <Finished
          correctAnswers={correctAnswers(selectedAnswers)}
        /> :
        <div className="panel panel-validate">
          <div className="panel-header">
            <div>Identifier Test: Question {qIdx+1} of {data.length}</div>
            <div className="actions">
              <a onClick={this.handleNext} className="btn-secondary">Save & Next Question</a>
            </div>
          </div>
          <div className="panel-body">
            <div>
              <div>
                <h4><strong>What frog species can you identify in this call?</strong></h4>
              </div>
              <div>
                <MiniPlayer key={question.audio} url={question.audio} />
              </div>
            </div>
            <div className="validate-answers">
              <div className="hint">Select one of the following and click 'Save & Next Question' above.</div>

              <hr />

              <form ref={el => this.answerForm = el}>
                {
                  message && <div className="alert alert-warning">{message}</div>
                }
                {
                  answers.map((answer, idx) => (
                    <div key={idx} className="answer">
                      <label>
                        <input
                          onChange={this.handleAnswer.bind(this, qIdx, answer)}
                          name={`answer_${qIdx}`}
                          type="radio" />
                        <div>
                          <div>
                            {answer.label}
                          </div>
                          <MiniPlayer key={answer.audio} url={answer.audio} />
                        </div>
                      </label>
                      <hr />
                    </div>
                  ))
                }
              </form>
            </div>
          </div>
        </div>
    )
  }

  handleAnswer = (qIdx, answer) => {
    const { selectedAnswers } = this.state
    selectedAnswers[qIdx] = answer
    this.setState({
      selectedAnswers
    })
  }

  handleNext = () => {
    const { qIdx, selectedAnswers } = this.state
    const { userActions } = this.props
    const nextIdx = qIdx + 1
    const answer = selectedAnswers[qIdx]

    this.setState({message: null})

    if(_.isNil(answer)){
      return this.setState({
        message: 'Please select an option to continue'
      })
    }

    if(nextIdx < data.length){
      this.setState({
        qIdx: nextIdx
      })
    } else {
      this.setState({finished: true})

      if(passed(correctAnswers(selectedAnswers))){
        userActions.updateAccount({
          passed_test: true
        })
      }
    }

    this.answerForm.reset()
  }
}

const Finished = props => {
  const { correctAnswers } = props

  return (
    <div className="panel panel-validate">
      <div className="panel-header">
        <div>
          FrogID Identification Test Results
        </div>
      </div>
      <div className="panel-body">
        <h4><strong>Thank you for completing the FrogID Identification Test.</strong></h4>
        <div className="hint">
          You have answered <strong>{correctAnswers.length}</strong> out of <strong>{data.length}</strong> questions correctly.
        </div>
        <br />
        <div>
          {
            passed(correctAnswers) ?
              <a href="/members/identify" className="btn btn-primary">Start Identifying</a> :
              <a href="/members/identify/test" className="btn btn-primary">Retry Test</a>
          }
        </div>
      </div>
    </div>
  )
}

export default connect(IdentifyTest)