import { Alert } from '../components/Alerts'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as userActions from '../auth/userActions'
import { actions as captureActions } from 'Portal/containers/members/Captures/store'
import { actions as groupCaptureActions } from 'Portal/containers/members/Group/store'
import { actions as mapCaptureActions } from 'Portal/containers/map_captures/store'
import { actions as frogActions } from 'Portal/containers/frogs/store'
import { actions as frogGroupActions } from 'Portal/containers/frog_groups/store'
import { actions as groupActions } from 'Portal/containers/groups/store/index'
import { actions as usergroupActions } from 'Portal/containers/groups/store/private'
import { actions as leaderboardActions } from 'Portal/containers/leaderboards/store'
import { actions as userValidationActions } from 'Portal/containers/user_validations/store'

export const mapStateToProps = (state) => {

  const collection = (type) => {
    return _.get(state, `${type}.items`) || []
  }

  const resource = (type) => {
    return _.get(state, `${type}.item`) || {}
  }

  const userState = state.user.toJS()
  const frogsState = state.frogs
  const groupsState = state.groups
  const usergroupsState = state.usergroups

  return {
    userState: userState,
    capturesState: state.captures,
    groupCapturesState: state.groupCaptures,
    frogsState: frogsState,
    groupsState: groupsState,
    usergroupsState: usergroupsState,
    leaderboardsState: state.leaderboards,

    user: userState.item || {},

    groups: collection('groups'),
    group: resource('groups'),
    usergroups: collection('usergroups'),
    usergroup: resource('usergroups'),
    captures: collection('captures'),
    capture: resource('captures'),
    groupCaptures: collection('groupCaptures'),
    groupCapture: resource('groupCaptures'),
    map_captures: collection('mapCaptures'),
    frogs: collection('frogs'),
    frog: resource('frogs'),

    frogGroups: collection('frogGroups'),
    frogGroup: resource('frogGroups'),

    userValidations: collection('userValidations'),
    userValidation: resource('userValidations'),

    leaderboard: collection('leaderboards')
  }
};

export const mapDispatchToProps = (dispatch) => {
  return {
    userActions: bindActionCreators({...userActions}, dispatch),
    groupActions: bindActionCreators({...groupActions}, dispatch),
    usergroupActions: bindActionCreators({...usergroupActions}, dispatch),
    captureActions: bindActionCreators({...captureActions}, dispatch),
    groupCaptureActions: bindActionCreators({...groupCaptureActions}, dispatch),
    mapCaptureActions: bindActionCreators({...mapCaptureActions}, dispatch),
    frogActions: bindActionCreators({...frogActions}, dispatch),
    frogGroupActions: bindActionCreators({...frogGroupActions}, dispatch),
    leaderboardActions: bindActionCreators({...leaderboardActions}, dispatch),
    userValidationActions: bindActionCreators({...userValidationActions}, dispatch),
  }
};

export default (component) => connect(
  state => mapStateToProps(state),
  dispatch => mapDispatchToProps(dispatch)
)(component)

export const Resolve = ({action, success, fail}) => {

  const handlePromise = (promise) => {
    switch(promise.status) {
      case "resolved":
        success ? success(promise) : Alert.success('Saved changes successfully')
        break;
      case "rejected":
        if(fail){
          fail(promise.body)
        } else {
          if(promise.body.full_messages){
            promise.body.full_messages.map((message) => (
              Alert.error(message)
            ));
          } else {
            Alert.error(promise.body.error);
          }
        }
        break;
      default:
        break
    }
  }

  return action.then(handlePromise)

}