import React from "react";
import { IndexLink, Link, browserHistory } from "react-router";
import { FormGroup, FormControl, Button } from "react-bootstrap";
import Map from "../containers/map_captures/map";
import Partners from "Portal/components/Partners.jsx";
import Navigation from "Portal/components/Navigation";
import Footer from "Portal/components/Footer";
import TopGroups from "Portal/containers/leaderboards/TopGroups";
import TopGroupsList from "Portal/containers/leaderboards/TopGroupsList";
import TopUsers from "Portal/containers/leaderboards/TopUsers";

export default class Page extends React.Component {
  componentWillMount() {}

  render() {
    return (
      <div className="app-portal frog-count">
        <Navigation />

        <div className="section-gray">
          <div className="container">
            <div className="section">
              <div className="section-inner">
                <div className="detail hor text-center">
                  <h2>FrogID project highlights</h2>
                  <p>FrogID project highlights</p>
                  <TopGroups />
                  <br />
                  <p>
                    <Link to="/signup">Create a profile</Link> to contribute to
                    FrogID.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="section">
            <div className="detail">
              <div className="desc">
                <h2>Research findings</h2>
                <p>
                  FrogID is a citizen-based science project aimed at
                  establishing a nation-wide database of frog calls to document
                  the true species diversity, distribution and breeding habitats
                  of Australian frogs with high geographic coverage and spatial,
                  temporal and taxonomic accuracy never before possible.
                </p>
                <p>
                  Monitoring frog distributions over time will help us
                  understand how frogs and their ecosystems are responding to a
                  changing planet. Our aim is for this valuable data to
                  ultimately inform conservation prioritisation and land-use
                  planning decisions in Australia.
                </p>
                <p>
                  Below you can find links to the research papers published by
                  the FrogID team to date:
                </p>
                <br />
                <ul>
                  <li>
                    <p>
                      JOURNAL PUBLICATION 18th January:{" "}
                      <a href="https://link.springer.com/epdf/10.1007/s10531-020-01937-3?author_access_token=eYnJdMmY4UwmA7rqtL2Ikve4RwlQNchNByi7wbcMAY63ZCZhlggW2KWXCjX837y21vsx4d04U0RfTbt1i_Mh0rtbreF0oT-KOL13JInjCwgrPQNJVlcsCqRNFJv4PLe9eC43tj3dsPnjZ9rEhch9fg%3D%3D">
                        "Citizen science data accurately predicts expert-derived
                        species richness at a continental scale when sampling
                        thresholds are met"
                      </a>{" "}
                      Biodiversity and Conservation - Corey Callaghan et al.
                      2020
                    </p>
                  </li>
                  <li>
                    <p>
                      JOURNAL PUBLICATION 4th February:{" "}
                      <a href="https://academic.oup.com/jue/article/6/1/juaa002/5722291">
                        "Continental-scale citizen science data reveal no
                        changes in acoustic responses of a widespread tree frog
                        to an urbanisation gradient"
                      </a>{" "}
                      Journal of Urban Ecology - Brittany Mitchell et al. 2020
                    </p>
                  </li>
                  <li>
                    <p>
                      JOURNAL PUBLICATION 17th February:{" "}
                      <a href="https://zookeys.pensoft.net/article/38253/">
                        "The FrogID dataset: expert-validated occurrence records
                        of Australia’s frogs collected by citizen scientists"
                      </a>{" "}
                      Zookeys - Jodi Rowley and Corey Callaghan 2020
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="section-gray">
          <div className="container">
            <div className="section">
              <div className="section-inner">
                <div className="detail hor text-center">
                  <h2>Top groups</h2>
                  <p>by number of verified frogs</p>
                  <TopGroupsList />
                  <br />
                  <p>
                    <Link to="/signup">Create a group</Link> to contribute to
                    FrogID.
                  </p>
                  <br />
                  <br />
                  <h2>Top froggers</h2>
                  <p>by number of verified frogs</p>
                  <TopUsers />
                  <br />
                  <p>
                    Want to get on the leader board?{" "}
                    <a href="/get-involved#record_steps">Download FrogID</a> to
                    be in the running.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div id="map" className="section">
            <div className="section-inner">
              <div className="detail hor text-center">
                <h2>Interactive map</h2>
                <p>See the latest verified submissions to FrogID</p>
                <br />
              </div>
              <Map />
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}
