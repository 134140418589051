import React, {Component} from 'react'
import Select from 'react-select'
import 'react-select/dist/react-select.css';

class SelectInput extends Component {
  onChange(event) {
    if (this.props.input.onChange) {
      if (this.props.multi == true) {
        this.props.input.onChange(_.map(event, (e) => e.value));
      } else {
        this.props.input.onChange(event.value);
      }
    }
  }

  render() {
    return (
      <Select
        {...this.props}
        value={this.props.input.value || ''}
        onBlur={() => this.props.input.onBlur(this.props.input.value)}
        onChange={this.onChange.bind(this)}
        options={this.props.options} // <-- Receive options from the form
      />
    );
  }
}

export default SelectInput