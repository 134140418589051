import React, { Component } from 'react'
import Navigation from 'Portal/components/Navigation.jsx'
import Footer from 'Portal/components/Footer.jsx'
import List from 'Portal/components/Frogs/List'
import connect from 'Portal/containers/connect'

class Page extends Component {

  componentDidMount(){
    const { frogActions, frogGroupActions, params: { slug } } = this.props
    frogActions.fetchFrogs('list', {
      query: {
        frog_group_slug: slug,
      }
    })

    frogGroupActions.getFrogGroup({
      id: slug,
    })
  }

  render() {
    const { frogGroup, frogs, params: { slug } } = this.props

    return (
      <div className="app-portal">
        <div className="home-feature">
          <div className="container">
            <div className="feature-inner hor">
              <div className="detail">
                <h1>{frogGroup.title}</h1>
                <p>{frogGroup.description}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="frogs">
          <div className="section-gray">
            <div className="container">
              <div className="section">

              </div>
            </div>

            <List frogs={frogs} />

          </div>
        </div>

        <div id="videos" className="videos">
          <div className="container">
            <div className="section">
              <div className="detail">
                <div className="desc text-center">
                  <h2>Instructional Videos</h2>
                </div>
              </div>
            </div>
          </div>

          <div className="container video-container">
            <div className="section hor">
              <div className="detail">
                <div className="desc">
                  <h2>How to use the FrogID mobile app</h2>
                </div>
              </div>
              <div className="video">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/sl73oSP1MjE" frameBorder="0" allowFullScreen />
              </div>
            </div>
          </div>

          <div className="container video-container">
            <div className="section hor">
              <div className="detail">
                <div className="desc">
                  <h2>Tips for using FrogID in the field</h2>
                </div>
              </div>
              <div className="video">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/GQDF7uCjsTY" frameBorder="0" allowFullScreen />
              </div>
            </div>
          </div>

          <div className="container video-container">
            <div className="section hor">
              <div className="detail">
                <div className="desc">
                  <h2>Why frogs are important</h2>
                </div>
              </div>
              <div className="video">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/KKzPq-gOQro" frameBorder="0" allowFullScreen />
              </div>
            </div>
          </div>
        </div>

        <div id="education" className="container">
          <div className="section">
            <div className="desc text-center">
              <h2>Education</h2>
            </div>

            <div className="section-columns">
              <div className="row">
                <div className="col-md-4">
                  <h2>Australian Frogs</h2>
                  <img className="img-responsive" src="https://res.cloudinary.com/beaconmaker/image/upload/c_fit,h_450,w_680/v1509831012/education_02_tyzpab.jpg" />
                  <p>Introduce students to the diversity of Australian Frogs</p>
                  <a target="new" href="http://res.cloudinary.com/beaconmaker/image/upload/v1510122144/Australian-Frogs_1_gwoi8k.pdf">Download PDF</a>
                </div>

                <div className="col-md-4">
                  <h2>What is a Frog?</h2>
                  <img className="img-responsive" src="https://res.cloudinary.com/beaconmaker/image/upload/c_fit,h_450,w_680/v1509831012/education_03_ajc6fh.jpg" />
                  <p>
                    Introduce students to the classification of frogs and toads
                  </p>
                  <a target="new" href="http://res.cloudinary.com/beaconmaker/image/upload/v1510122159/What-is-a-Frog_1_foyilo.pdf">Download PDF</a>
                </div>

                <div className="col-md-4">
                  <h2>Frog Habitats and Adaptation</h2>
                  <img className="img-responsive" src="https://res.cloudinary.com/beaconmaker/image/upload/c_fit,h_450,w_680/v1509831012/education_04_faom5t.jpg" />
                  <p>
                    Introduce students to the concepts of habitats, adaptations and food webs
                  </p>
                  <a target="new" href="http://res.cloudinary.com/beaconmaker/image/upload/v1510122147/Frog-Habitat-and_Adaptation_1_demsqz.pdf">Download PDF</a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="media_resources" className="container">
          <div className="section hor">
            <div className="detail">
              <div className="desc">
                <h2>Media Resources</h2>
                <p>
                  Please find here information on FrogID Project, including, press releases, images and video for use by media outlets.
                </p>
              </div>
              <a className="btn btn-primary" href="https://foto.australianmuseum.net.au/fotoweb/albums/WfwJ1Ni2X2FePK0wvvRr969kpnjW-rerXV7dHg/">Download Media Kit</a>
            </div>
          </div>
        </div>

        <div id="media_contact" className="container">
          <div className="section hor">
            <div className="detail">
              <div className="desc">
                <h2>Contact Media</h2>
                <div className="row">
                  <div className="col-md-6">
                    <p>
                      <strong>Claire Vince</strong><br />
                      Media and Communications Advisor | Marketing & Communications<br />
                      <strong>Australian Museum</strong> 1 William Street Sydney NSW 2010 Australia<br />
                      <strong>T</strong> <a href="tel:61 2 9320 6181">61 2 9320 6181</a> <strong>M</strong> <a href="tel:61 468 726 910">61 468 726 910</a>
                    </p>
                  </div>
                </div>
              </div>
              <a className="btn btn-primary" href="https://foto.australianmuseum.net.au/fotoweb/albums/WfwJ1Ni2X2FePK0wvvRr969kpnjW-rerXV7dHg/">Download Media Kit</a>
            </div>
          </div>
        </div>

        {this.props.children}

        <Footer />
      </div>
    );

  }
}

export default connect(Page)
