import React, { Component } from 'react';

export default class Alert extends Component {
  render() {
    return (
      <div>
        {
          this.props.full_messages.length > 0 &&
          <div className={`alert alert-${this.props.type}`}>
            {
              this.props.full_messages.map( (message, idx) => (
                <p key={idx}>{message}</p>
              ))
            }
          </div>
        }
      </div>
    );
  }
}