import { connect } from 'react-redux'
import { Field as ReduxFormField, reduxForm, formValueSelector } from 'redux-form'
import { mapStateToProps, mapDispatchToProps } from './connect'
import { Resolve as ResolveAction } from './connect'

export const Resolve = ResolveAction;
export const Field = ReduxFormField;

export default (component, {name, fields, initialize}) => {

  component = reduxForm({
    enableReinitialize: true,
    form: name,
    fields: fields,
  })(component);

  return connect(
    state => {
      return {
        initialValues: initialize ? initialize(state) : {},
        currentValues: formValueSelector(name)(state, ...fields),
        ...mapStateToProps(state)
      }
    },
    dispatch => mapDispatchToProps(dispatch),
  )(component)
};