import React, {Component} from 'react'
import { Button } from 'react-bootstrap'

export class LoaderButton extends Component {
  render() {
    const { type, className, onClick, loading, children } = this.props;

    return (
      <Button type={type || 'submit'} onClick={onClick} className={className} disabled={loading} >
        {
          loading ?
            <span>Loading...</span>
            :
            children
        }
      </Button>
    );
  }
}

export class LoaderPage extends Component {
  render() {
    const { loading, children } = this.props

    return (
      <div className="load">
        {
          loading ?
            <div style={{textAlign: 'center', padding: '100px 0'}}>
              Loading...
            </div> :
            children
        }
      </div>
    );
  }
}
