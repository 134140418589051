import React from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { IndexLink, Link, browserHistory } from 'react-router';
import { FormGroup, FormControl, Button } from 'react-bootstrap';
import Partners from 'Portal/components/Partners.jsx'
import Navigation from 'Portal/components/Navigation.jsx';
import Footer from 'Portal/components/Footer.jsx';
import moment from 'moment';
import Countdown from 'react-countdown-now';
import { actions as leaderboardActions } from 'Portal/containers/leaderboards/store'

class Home extends React.Component {
  componentDidMount(){
    const { leaderboardActions } = this.props
    leaderboardActions.getLeaderboard('frogid_week')
  }

  render() {
    const { loading, stats } = this.props
    
    return (
      <div className="app-portal">
        <Navigation />
        <div className="home-feature">
          <div className="container">
            <div className="feature-inner">
              <div className="detail">
                <h1>What's that sound?</h1>
                <p>Croaks, whistles, bleats and barks - every frog species makes a different sound! By recording a frog call with our new app, FrogID, you can discover which frogs live around you and help us count Australia's frogs!</p>
                <div className="feature-links">
                  <Link to="/get-involved" className="btn-cta">Get Involved</Link>
                  <a className="icon-google-play" href="https://play.google.com/store/apps/details?id=au.net.australianmuseum.frogid"><img src="/images/icon-google-play.png" /></a>
                  <a className="icon-appstore" href="https://apps.apple.com/au/app/frogid/id1176329797"><img src="/images/icon-appstore.png" /></a>
                </div>
              </div>
              <div className="feature-phone">
                <img src="https://res.cloudinary.com/beaconmaker/image/upload/c_scale,q_74,w_1100/v1510024824/afeature_phone_o4xapa.png" />
              </div>
            </div>
          </div>
        </div>

        <div className="section">
          <div className="container">
            <div className="section text-center">
              <h2>Get Involved</h2>
              <p>There are two ways to get involved in FrogID count and help save Australia’s frogs.</p>
              <div className="steps">
                <div className="step">
                  <p className="title">Out in the field</p>
                  <img className="img-responsive" src="/images/litoria_bella_call_600x600.png" />
                  <p>
                    Download the FrogID app. Using the app, you can then create a FrogID account to find, record and help validate frog calls.
                  </p>
                  <a href="/get-involved#record_steps" className="btn btn-primary">Discover & Download</a>
                </div>
                <div className="step">
                  <p className="title">Explore FrogID records</p>
                  <img className="img-responsive" src="/images/litoria_bella_laptop600x600.png" />
                  <p>
                    Discover citizen scientist submissions by species and location, with both first year results and all records, revealed on an interactive map!
                  </p>
                  <a href="/explore" className="btn btn-primary">View data</a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section-gray">
          <div className="container">
            <div className="section text-center">
              <h2>Connect with the FrogID team!</h2>
              <div className="SocialMedia">
                <a href="https://www.facebook.com/FrogIDAus"><i className="fa fa-facebook-official" /></a>
                <a href="https://twitter.com/FrogIDAus"><i className="fa fa-twitter" /></a>
                <a href="https://instagram.com/FrogIDAus"><i className="fa fa-instagram" /></a>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="section hor">
            <div className="image">
              <img src="/images/frog-hp1.png" />
            </div>
            <div className="detail">
              <div className="desc">
                <h2>Frogs count!</h2>
                <p>Frogs are a sign of a healthy environment, but around Australia frogs are declining and many are endangered. By counting Australia's frogs we can learn more about where they are and how they're doing.</p>
              </div>
              <a href="#why">Learn more &nbsp;<i className="fa fa-long-arrow-right" /></a>
            </div>
          </div>
        </div>

        <div className="section-gray">
          <div className="container">
            <div className="section hor">
              <div className="detail">
                <div className="desc">
                  <h2>Help our scientists</h2>
                  <p>There’s no way scientists can count Australia’s frogs on their own. The country’s too big and there’s too many frogs! That’s where you come in. With FrogID, citizen scientists just like you can help us put frogs on the map!</p>
                </div>
                <Link to="/frog-count">Get involved &nbsp;<i className="fa fa-long-arrow-right" /></Link>
              </div>
              <div className="image">
                <img src="/images/frog-hp2.png" />
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="home-frogs-count">
            <div className="section">
              <h2 id="why">Why Frogs Count</h2>
              <p>Australia has over 240 known species of frog, almost all of which are found nowhere else in the world. Some species are flourishing, like the Striped Marsh Frog. But others have declined dramatically since the 1980s, and four have become extinct.</p>
            </div>
            <div className="section hor">
              <div className="detail">
                <div className="desc">
                  <p>FrogID is a national citizen science project that is helping us learn more about what is happening to Australia’s frogs. All around the country, people are recording frog calls with nothing more than a smartphone. </p>

                  <p>With the data obtained through FrogID we are able to track the Cane Toad and identify where frogs are thriving and where they aren’t. And by matching calls to weather and habitat, we are learning more about how different frog species are responding to a changing environment. </p>

                  <p>This information could be crucial in saving Australia’s frogs.</p>

                  <p>Explore the interactive map to see where frog calls have been recorded and view Australia’s top froggers on our FrogID leaderboard.</p>

                </div>
                <a href="/explore">Explore map &nbsp;<i className="fa fa-long-arrow-right" /></a>
              </div>
              <div className="image">
                <img src="/images/frog-hp3.png" />
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="section">
            <div className="detail">
              <div className="sponsors">
                <Link to="/partners"><img className="img-responsive center-block" src="/images/OurPartners-Logos-Website-v2.jpg" /></Link>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );

  }
}

export default connect(
  state => {
    const stats = state.leaderboards
    return {
      loading: stats.isFetchingItem,
      stats: stats.item || {},
    }
  }, 
  dispatch => {
    return {
      leaderboardActions: bindActionCreators({...leaderboardActions}, dispatch),
    }
  }
)(Home)

const StatCount = ({loading, count}) => (
  <div className="StatCount">{loading ? '...' : count}</div>
) 

const CountdownContainer = props => {
  return (
    <div className="countdown-container">
      <h2>Time until FrogID Week</h2>
      <div>
        {props.children}
      </div>
    </div>
  )
}

const renderer = ({ total, days, hours, minutes, seconds, completed }) => {
  if (completed) {
    return (
      <CountdownContainer>
        <span className="countdown">ON NOW</span>
      </CountdownContainer>
    )
  } if (days < 1) {
    return (
      <CountdownContainer>
        <span className="countdown">{hours} hours {minutes} minutes</span>
      </CountdownContainer>
    )
  } else {
    return (
      <CountdownContainer>
        <span className="countdown">{days} days {hours} hours {minutes} minutes</span>
      </CountdownContainer>
    )
  }
};