import React from 'react'
import connect from 'Portal/containers/connect'
import Navigation from 'Portal/components/Navigation.jsx'
import Footer from 'Portal/components/Footer'

class VerifyEmail extends React.Component {
  render() {
    return (
      <div className="app-portal">
        <Navigation />
        <div className="wrapper">
          <h1 className="login__header">Please check your email for a link to verify your account.</h1>
          <a href="/login" className="col-xs-12 text-center">Login</a>
        </div>
        <div className="navbar navbar-default navbar-fixed-bottom" style={{zIndex: -1}}>
            <Footer />
        </div>
      </div>
    );
  }
}

export default connect(VerifyEmail)