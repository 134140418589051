import React, { Component } from 'react'
import { browserHistory } from "react-router";


class PaginatedList extends Component {
  // Basic state utility to manage boilerplate between paginated endpoint responses,
  // the client-side router, and the <Pagination> component.

  state = {
    total_pages: null,
    total_count: null,
    rpp: null,
    page: null,
  };

  handleQueryChanged() {
    // Inheritors should implement this to handle changes to the "page"
    // property in the browser's location query
  }

  componentDidUpdate(prevProps) {
    const prevQuery = prevProps.location.query;
    const { query } = this.props.location;

    if (!_.isEqual(prevQuery, query)) {
      this.handleQueryChanged();
    }
  }

  fetchData(query) {
    const { groupActions } = this.props
    return groupActions.fetchGroups(
      {},
      { query: query }
    );
  }

  updateListData = () => {
    const { query } = this.props.location;

    return this.fetchData(query)
      .then(res => {
        const { total_pages, total_count, rpp, page } = res;
        this.setState({
          total_pages,
          total_count,
          rpp,
          page
        });
      });
  }

  handlePageChange(page) {
    this.setState({page});
    const { location } = this.props;
    location.query.page = page;
    browserHistory.push(location);
    this.handleQueryChanged();
  }
}

export default PaginatedList;
