export const generateUUID = () => {
  const s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
    s4() + '-' + s4() + s4() + s4();
}

export const cl_cloudname = "ausmus"
export const cl_preset = "mkwtgnyj"
export const upload_types = {
  image: {
    accept: "image/*",
    file_types: ['image/png', 'image/jpg', 'image/jpeg'],
    message: 'Only .png or .jpg files are supported',
    max_size: 2000000,
  },
  audio: {
    accept: "audio/*",
    file_types: ['audio/mp3', 'audio/wav'],
    message: 'Only .mp3 or .wav files are supported',
    max_size: 10000000,
  }
}