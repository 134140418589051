import React from 'react'
import './Player.scss'
import 'wavesurfer.js'
import Wavesurfer from 'react-wavesurfer'

class Player extends React.Component {
  state = {
    playing: false,
    ready: false,
  }

  render() {
    const { url, barHeight, forAdmin } = this.props
    const { playing, ready } = this.state
    const mp3 = url ?
      url.replace('http', 'https').replace('.aac', '.mp3') :
      null

    const options = { barHeight: barHeight };

    return (
      mp3 ?
        forAdmin ?
          // admin player has orange buttons
          <div className="AudioPlayer admin">
            <div className="AudioPlayer-Player">
              {!ready && <div className="Player-loading">Loading...</div>}
              <Wavesurfer
                audioFile={mp3}
                onReady={this.onReady}
                onFinish={() => {
                  this.onStop();
                }}
                playing={playing}
                options={options}
              />
            </div>
            <div className="AudioPlayer-Actions">
              <div>
                {
                  playing ?
                    <button onClick={this.onStop} className="btn btn-primary"><i className="fa fa-stop" /></button> :
                    <button onClick={this.onPlay} className="btn btn-primary"><i className="fa fa-play" /></button>
                }
                <a download className="btn btn-link" href={url}><i className="fa fa-download" /></a>
                <div className="AudioPlayer-hint">
                  Click on waveform to play from a different location
                </div>
              </div>
            </div>
          </div>
        :
          // user player is green
          <div className="AudioPlayer">
            <div className="AudioPlayer-Player">
              {!ready && <div className="Player-loading">Loading...</div>}
              <Wavesurfer
                audioFile={mp3}
                onReady={this.onReady}
                onFinish={() => {
                  this.onStop();
                }}
                playing={playing}
                options={options}
              />
            </div>
            <div className="AudioPlayer-Actions">
              <div>
                {
                  playing ?
                    <button onClick={this.onStop} className="audio-button"><i className="fa fa-stop" /></button> :
                    <button onClick={this.onPlay} className="audio-button"><i className="fa fa-play" /></button>
                }
                <a download className="audio-button" href={url}><i className="fa fa-download" /></a>
              </div>
              <div className="AudioPlayer-hint">
                Click on waveform to play from a different location
              </div>
            </div>
          </div> : null
    )
  }

  onPlay = () => {
    this.setState({
      playing: true
    })
  }

  onStop = () => {
    this.setState({
      playing: false
    })
  }

  onReady = () => {
    this.setState({ ready: true })
  }
}

export default Player
