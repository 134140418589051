import React, { Component } from 'react'
import { Link } from 'react-router'
import connect from 'Portal/containers/connect'
import Pagination from "Portal/components/Pagination";
import { dateTimeToString } from 'Admin/components/captures/List'
import { convertToHttps } from 'Admin/helpers'

import moment from 'moment'

const dateFormat = 'MMMM Do YYYY'

class GroupMembers extends Component {

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    const { usergroupActions, params: { slug } } = this.props;
    const { query } = location;

    usergroupActions.getUsergroup({ slug })
  }

  render() {
    const { usergroup: group, user } = this.props;
    return (
      <>
        <div className="wrapper">
          <a href={"/members/group/profile/" + group.slug} className="return-link">← Return to group page</a>
          <h1 className="page-heading">{group.name} Members</h1>
          {/* {pagination} */}
          <ul className="group-members__list">
            {
              group && group.public_users && group.public_users.map(member => (
                <li className="group-members__item" key={member.id}>
                  <div className="group-members__info">
                    {
                      member.avatar &&
                      <img src={convertToHttps(member.avatar)} alt={"avatar of " + member.full_name} className="group-members__profile-pic" />
                    }
                      <h2><a href={member.profile_url}>{member.full_name}</a></h2>
                      {
                      group.owner_id == user.id && // only show details to owners
                      <dl>
                        <div>
                          <dt>ID</dt>
                          <dd>{member.id}</dd>
                        </div>
                        <div>
                          <dt>Joined</dt>
                          <dd>{moment(member.created_at).format(dateFormat)}</dd>
                        </div>
                        <div>
                          <dt>Frogs identified</dt>
                          <dd>{member.captured_frog_count}</dd>
                        </div>
                        <div>
                          <dt>Validated</dt>
                          <dd>{member.validated_captures_count}</dd>
                        </div>
                        <div>
                          <dt>Published</dt>
                          <dd>{member.published_captures_count}</dd>
                        </div>
                      </dl>
                      }
                    </div>

                </li>
              ))
            }
          </ul>
          {
            parseInt(group.anonymous_users_count) > 0 &&
            <p>and {group.anonymous_users_count} Anonymous Members</p>
          }
          {/* {pagination} */}
        </div>
      </>
    )
  }
}

export default connect(GroupMembers)
