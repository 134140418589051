import React, { Component } from 'react'
import connect from 'Portal/containers/connect'
import { convertToHttps } from 'Admin/helpers'

class GroupMembersPublic extends Component {


  state = {
    group : {}
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    const { location } = this.props
    const { pathname } = location;

    const path_parts = pathname.split("/");
    const group_slug = path_parts[path_parts.length - 1];

    fetch("/api/user/groups/" + group_slug)
    .then(res => res.json())
    .then(
      (result) => {
        this.setState({group: result});
        // window.smuCreateUserEmbed("#userRecordsEmbed", {
          // userId: user_id,
        // });
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        this.setState({
          isLoaded: true,
          error
        });
      }
    )
  }


  render() {
    const { group } = this.state;
    return (
      <>
        <div className="wrapper">
          <a href={"/p/group/" + group.slug} className="return-link">← Return to group page</a>
          <h1 className="page-heading">{group.name} Members</h1>
          {/* {pagination} */}
          <ul className="group-members__list">
            {
              group && group.public_users && group.public_users.map(member => (
                <li className="group-members__item">
                  <div className="group-members__info">
                    <h2><a href={member.profile_url}>{member.full_name}</a></h2>
                  </div>
                  {
                    member.avatar &&
                    <img src={convertToHttps(member.avatar)} alt={"avatar of " + member.full_name} className="image group-members__profile-pic" />
                  }
                </li>
              ))
            }
          </ul>
          {
            parseInt(group.anonymous_users_count) > 0 &&
            <p>and {group.anonymous_users_count} Anonymous Members</p>
          }
          {/* {pagination} */}
        </div>
      </>
    )
  }
}

export default connect(GroupMembersPublic)
