import React from "react";
import { IndexLink, Link } from "react-router";
import connect from 'Portal/containers/connect'
import { isNil } from "lodash";
import { isSignedIn } from "../auth/userActions";

class Navigation extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      navClosed: true
    }
  }


  logout = () => {
    const { userActions, isSignedIn } = this.props

    userActions.signOut(() => {
      window.location.href = '/'
    })
  }

  toggleNav = () => {
    this.setState({navClosed: !this.state.navClosed})
  }

  render() {

    const {userState} = this.props;

    return (
      <>
        <nav className="site-navigation">
          <ul className="wrapper site-navigation__list">
            {
              userState.isSignedIn ?
              <li><a onClick={this.logout.bind(this)}>Logout</a></li>
              :
              <li></li> // empty li to push the logo to the centre
            }
            <li>
              <a href="/">
                <svg xmlns="http://www.w3.org/2000/svg" width="73.524" height="83.217" viewBox="0 0 73.524 83.217" className="site-navigation__logo">
                  <g id="Group_26" data-name="Group 26" transform="translate(0.001 -0.002)">
                    <path id="Path_1" data-name="Path 1" d="M24.495,321.43v2.72H16.277v4.737H22.9v2.722h-6.62v8.136H13.4V321.43Z" transform="translate(-10.926 -262.072)" fill="#69b32d" />
                    <g id="Group_1" data-name="Group 1" transform="translate(14.536 63.544)">
                      <path id="Path_2" data-name="Path 2" d="M78.72,361.466V348.279h2.722v2.2a4.664,4.664,0,0,1,3.9-2.406,4.547,4.547,0,0,1,1.675.314l-.471,2.486A3.026,3.026,0,0,0,85,350.476c-1.754,0-3.558,1.935-3.558,6.1v4.894H78.72Z" transform="translate(-78.72 -347.337)" fill="#69b32d" />
                      <path id="Path_3" data-name="Path 3" d="M131.829,348.07c4.083,0,6.489,3.087,6.489,6.8s-2.406,6.8-6.489,6.8-6.489-3.087-6.489-6.8,2.406-6.8,6.489-6.8m0,11.2c2.38,0,3.612-2.172,3.612-4.4s-1.23-4.4-3.612-4.4-3.612,2.172-3.612,4.4,1.23,4.4,3.612,4.4" transform="translate(-116.731 -347.337)" fill="#69b32d" />
                      <path id="Path_4" data-name="Path 4" d="M207.072,344.831a5.191,5.191,0,0,1,2.406.576l2.434-1.307,1.1,1.963-1.78,1a4.291,4.291,0,0,1,.576,2.2c0,2.772-2.066,4.421-4.735,4.421a5.868,5.868,0,0,1-2.12-.393,1.256,1.256,0,0,0-.707,1.151c0,.707.445,1.021,1.361,1.021h2.9c2.591,0,4.369,1.282,4.369,3.767,0,3.27-2.825,4.552-5.784,4.552-3.322,0-5.808-1.466-5.808-4.343a3.555,3.555,0,0,1,1.125-2.668,2.813,2.813,0,0,1-.759-2.068,3.007,3.007,0,0,1,1.6-2.668,4.293,4.293,0,0,1-.89-2.772c0-2.746,2.04-4.423,4.709-4.423m-1.256,12.926a10.9,10.9,0,0,1-1.335-.079,2.178,2.178,0,0,0-.576,1.6c0,1.307,1.178,2.144,3.193,2.144,1.885,0,3.01-.705,3.01-1.987,0-1.258-.89-1.675-2.172-1.675Zm1.256-6.358a2.146,2.146,0,1,0-2.066-2.144,2.006,2.006,0,0,0,2.066,2.144" transform="translate(-178.656 -344.1)" fill="#69b32d" />
                    </g>
                    <rect id="Rectangle_2" data-name="Rectangle 2" width="2.879" height="18.315" transform="translate(51.796 59.358)" fill="#69b32d" />
                    <g id="Group_2" data-name="Group 2" transform="translate(-0.001 0.002)">
                      <path id="Path_5" data-name="Path 5" d="M321.879,321.43c4.4,0,8.426,2.982,8.426,9.157s-4.03,9.157-8.426,9.157H316.49V321.43Zm-2.511,2.72v12.875h1.675c3.193,0,6.332-1.439,6.332-6.436s-3.141-6.437-6.332-6.437h-1.675Z" transform="translate(-258.046 -262.074)" fill="#69b32d" />
                      <path id="Path_6" data-name="Path 6" d="M6.091,38.445C3.021,37.54-.4,39.763.038,45.2c.491,6.007,3.926,7.56,8.026,5.883,2.759-1.075,4.312-3.041,8.264-2.726,3.232.259,6.144,1.967,10.06,4.251s8.24,4.445,12.659,4.445,7.034-3.189,9.294-5.826,7.008-5.985,11.279-5.056c2.489.543,2.556,1.08,5.023,1.315a8.046,8.046,0,0,0,8.439-5.852c1.158-3.865.223-8.182-5.073-8.592a8.121,8.121,0,0,0-8.088,5.051c-.87,1.885-1.431,2.888-3.968,3.666s-8.419,1.8-8.79-3.717c-.319-4.722.275-10.341,2.685-18.145,1.254-4.059,3.06-3.808,5.529-4.461s5.878-1.784,6.1-6.33S59.3,1.8,55.026.768s-8.238.8-9.268,4.871,1.813,5.577,1.732,8.038c-.15,4.62-3.992,12.784-5.023,15.975S38.171,40,35.283,39.876s-5.324-4.321-7.209-9.069A68.327,68.327,0,0,1,24.532,13.8c-.074-3.289,2.166-3.625,2.589-7.987.43-4.484-4.351-6.3-8.67-5.7s-7.785,4.22-6.833,8.465,4.9,4.319,7.385,7.66,5.752,17.885,6.179,23.135.151,7.435-4.973,6.38S14.575,44.489,13.3,43.97c-4.271-1.732-4.14-4.622-7.209-5.525" transform="translate(0.001 -0.002)" fill="#ffd13a" />
                    </g>
                  </g>
                </svg>
              </a>
            </li>
            <li>
              <button aria-label="Menu" onClick={this.toggleNav.bind(this)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="50.12" height="38.59" viewBox="0 0 50.12 38.59" className="site-navigation__menu-icon">
                  <path id="Path_7" data-name="Path 7" d="M4,6H50.12M4,23.295H50.12M29.942,40.59H50.12" transform="translate(-2 -4)" fill="none" stroke="#ffd13a" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
                </svg>
              </button>
            </li>
          </ul>
        </nav>

        {/* Opened menu */}
        <nav className={`site-navigation__dropdown-menu ${this.state.navClosed && "site-navigation__dropdown-menu--hidden"}`}>
          <ul className={`wrapper site-navigation__dropdown-menu-list ${this.state.navClosed && "site-navigation__dropdown-menu-list--hidden"}`}>
            <li className="site-navigation__dropdown-menu-item"><a href="/about-frogid">About</a></li>
            <li className="site-navigation__dropdown-menu-item"><a href="/about-frogid">FrogID Week</a></li>
            <li className="site-navigation__dropdown-menu-item"><a href="/explore">FrogID Map</a></li>
            <li className="site-navigation__dropdown-menu-item"><a href="/science">Science</a></li>
            <li className="site-navigation__dropdown-menu-item"><a href="/schools">Schools</a></li>
            <li className="site-navigation__dropdown-menu-item"><a href="/frogs">Australia's Frogs</a></li>
            {/* <li className="site-navigation__dropdown-menu-item site-navigation__dropdown-menu-item--box"><a href="">Donate to FrogID</a></li> */}
          </ul>
        </nav>
        {
          userState.isSignedIn ?
          <nav className="portal-navigation">
            <ul className="wrapper portal-navigation__list">
              <li><a href="/members/profile">Profile</a></li>
              <li><a href="/members/profile/captures/list">Captures</a></li>
              <li><a href="/members/profile/badges">My Badges</a></li>
              <li><a href="/members/group/directory">Groups</a></li>
              {/* <li><a href="">Events</a></li> */}
            </ul>
          </nav>
          : ""
        }
      </>
    );
  }
}

export default connect(Navigation);
