import React, { Component } from 'react'
import AlertPopup from 'react-s-alert';
// import { Link, browserHistory } from 'react-router'
import connect from 'Portal/containers/connect'
import Navigation from 'Portal/components/Navigation.jsx'
import Footer from 'Portal/components/Footer.jsx'
// import { LoaderPage } from 'Portal/components/Loaders'

import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';

class PublicMembers extends React.Component {

  componentDidMount() {
    const { userActions } = this.props

    // userActions.isSignedIn(() => {
    //   // console.log('signed in');
    // }, () => {
    //   this.logout();
    // });
  }

  // logout() {
  //   const { userActions } = this.props

  //   userActions.signOut()
  //   window.location = '/login'
  // }

  render() {
    const { children } = this.props

    return (
      <>
        {/* do something about the navigation here, to remove profile etc */}
        <Navigation />
        {/* Here we insert the main content. For Profile page go to file 'client/bundles/Portal/containers/members/Profile/Show.jsx' */}
        { children ?
        children :
        // render the 404 content
        <div className="wrapper">
          <h1 className="login__header">404 - Page not found</h1>
          <p>
            Thanks for visiting the FrogID website! The page you are looking for may have been unpublished or moved to a new location.
          </p>
          <p>
            Please try visiting another page.
          </p>
          </div>
        }
        <Footer />
        <AlertPopup position="bottom-right" stack={{limit: 3, spacing: 10}} offset={10} effect="slide" />
      </>
    )
  }
}

export default connect(PublicMembers)
