import React from 'react'
import moment from 'moment'
import { Link } from 'react-router'
import connect from '../connect'

class UserValidationsList extends React.Component {
  componentDidMount(){
    const { userValidationActions } = this.props
    userValidationActions.fetchUser_validationss()

  }
  render() {
    const { userValidations } = this.props

    return (
      <div className="panel">
        <Link to="/members/identify/list" className="btn btn-primary">Start IDing</Link>
        <hr />
        <table className="table table-striped">
          <thead>
            <tr>
              <th width="60">ID</th>
              <th width="60">Capture ID</th>
              <th width="150">Date Submitted</th>
              <th width="100">Status</th>
              <th width="380">Your ID</th>
              <th width="380">Confirmed ID</th>
            </tr>
          </thead>
          <tbody>
            {
              userValidations.map(validation => (
                <tr>
                  <td>{validation.id}</td>
                  <td>{validation.capture_id}</td>
                  <td>{date(validation.created_at)}</td>
                  <td>{_.startCase(validation.validated_status)}</td>
                  <td>
                    <YourID validation={validation} />
                  </td>
                  <td>{validation.confirmed_message}</td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    )
  }
}

export const YourID = props => {
  const { validation: {
    validated_frogs,
    validated_type,
    not_a_frog_type
  } } = props

  return (
    <div>
      <strong>{_.startCase(validated_type)}</strong>
      {
        validated_type === 'not_a_frog' &&
          <div>
            {_.startCase(not_a_frog_type)}
          </div>
      }

      {
        validated_type === 'frog' &&
        <div>
          {
            validated_frogs.map(frog => (
              <a target="new" href={`/frogs/${_.kebabCase(frog.scientific_name)}`}>{frog.scientific_name}, </a>
            ))
          }
        </div>
      }
    </div>
  )
}
export const date = (utc) => moment(utc).format("DD-MM-YYYY HH:mm a")

export default connect(UserValidationsList)