import React, {Component} from 'react'

class Identify extends Component {
  render(){
    return (
      <div className="identify">
        {this.props.children}
      </div>
    )
  }
}

export default Identify