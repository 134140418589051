export const habitats = [
  'None selected',
  'Natural area',
  'Rural',
  'Backyard/Suburbia',
  'City',
  'Other',
]

export const water_bodies = [
  'None selected',
  'Small pond',
  'Large pond',
  'Stream or creek',
  'River',
  'Flooded area',
  'No visible water bodies',
]

export const filter_labels = {
  habitat: habitats,
  water_body: water_bodies,
}