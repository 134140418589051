import React, { Component } from 'react'
import Player from "shared/components/Audio/Player";
import Sound, { toWaveForm } from 'shared/components/captures/sound'

class CaptureAudio extends Component {
  render() {
    const { id, call_audio, user_full_name } = this.props

    return (
      <>
        {
          !_.isEmpty(call_audio) &&
          <div className="audio__container">
            <div className="audio__player">
              <Player url={call_audio} barHeight={15} forAdmin={false} />
            </div>
          </div>
        }
      </>
    )
  }
}

export default CaptureAudio
