import React from 'react';
import axios from 'axios';
import Navigation from 'Portal/components/Navigation.jsx'
import Footer from 'Portal/components/Footer'

class ConfirmEmail extends React.Component {

  state = {
    success: false,
    fail: false,
    loading: true,
  }

  componentDidMount() {
    const { location: { query: { token } } } = this.props
    if (token) {
      axios.patch('/api/user/emails/confirm', { token }).then((res) => {
        this.setState({
          success: true,
          fail: false,
          loading: false,
        })
      }).catch(() => {
        this.setState({
          fail: true,
          success: false,
          loading: false,
        })
      })
    }
  }

  render() {
    const { success, fail, loading } = this.state;

    return (
      <div className="app-portal">
        <Navigation />
        <div className="wrapper">
          {
            loading &&
            <div>Loading...</div>
          }
          {
            success &&
            <div>
              <h1 className="login__header">Email address confirmed</h1>
              <div className="login__container">
                <p className="mb-8">Your email address is now confirmed. You can now submit captures to the FrogID community</p>
                <button className="login__button button button--green button--w-full"><a href="/members">Continue</a></button>
              </div>
            </div>
          }
          {
            fail &&
            <div>
              <h1 className="login__header">Failed to confirm your email address</h1>
              <div className="login__container">
                <p className="mb-8">Please contact support for more information.</p>
              </div>
            </div>
          }
        </div>
        <Footer />
      </div >
    )
  }
}

export default ConfirmEmail;