import _ from 'lodash'
import React from 'react'
import moment from 'moment'

class List extends React.Component {
  render() {
    const { captures, onProcess, onDelete } = this.props

    return (
      <div className="captures-list">
        <table className="table table-captures">
          <thead>
          <tr>
            <th width="80">ID</th>
            <th width="150">User</th>
            <th width="150">Capture date</th>
            <th width="80">State</th>
            <th>Status</th>
            <th width="80">Duplicate</th>
            <th width="80">Images</th>
            <th width="80">Guessed</th>
            <th width="80">Filtered</th>
            <th width="100">Actions</th>
          </tr>
          </thead>
          <tbody>
          {
            captures.map((capture, idx) => (
              <tr key={capture.id}>
                <td>{capture.id}</td>
                <td>
                  <div><strong>{capture.user_full_name}</strong></div>
                  <div>{capture.user_email}</div>
                  <div>ID: {capture.user_id}</div>
                </td>
                <td>
                  <div>{dateTimeToString(capture.capture_time)}</div>
                </td>
                <td>{capture.state}</td>
                <td>
                  <div className={`status-${capture.validated_status}`}>
                    {_.startCase(capture.validated_status)} - {validatedStatusLabel(capture)}
                  </div>
                  <div>
                    <em>{validatedTypeLabel(capture)}</em>
                  </div>
                </td>
                <td>{capture.is_duplicate ? 'yes' : 'no' }</td>
                <td>{capture.images_count > 0 ? capture.images_count : '-'}</td>
                <td>{capture.guessed_count > 0 ? capture.guessed_count : '-'}</td>
                <td>{capture.filtered_count > 0 ? capture.filtered_count : '-'}</td>
                <td>
                  {
                    onProcess &&
                    <a onClick={onProcess.bind(this, capture)} className="btn-process">
                      Process &nbsp;
                      <i className="fa fa-arrow-right" />
                    </a>
                  }
                </td>
                <td>
                  {onDelete && <a onClick={onDelete.bind(this, capture)}><i className="fa fa-trash" /></a>}
                </td>
              </tr>
            ))
          }
          </tbody>
        </table>
      </div>
    )
  }
}

export const dateTimeToString = (time) => moment(time).format('DD/MM/YYYY hh:mm a')

export const validatedStatusLabel = (capture) => {
  switch(capture.validated_status){
    case 'pending':
      return _.startCase(capture.pending_status)
    case 'spam':
      return ''
    default:
      return _.startCase(capture.validated_type)
  }
}
export const validatedTypeLabel = (capture) => {

  if(capture.validated_status === 'pending'){
    return capture.validated_frog_names.join(', ')
  }

  switch(capture.validated_type){
    case 'frogs':
      return capture.validated_frog_names.join(', ')
    case 'not_a_frog':
      return _.startCase(capture.not_a_frog_type)
    case 'unidentified':
      return capture.frog_group_title
    default:
      return null
  }
}


export default List