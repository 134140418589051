import React, {Component} from 'react'

class Partners extends Component {
  render(){
    return (
      <div className="partners">
        <h2>Our Partners</h2>
        <h3>Major Partner</h3>
        <a href="#">
          <img src="images/OurPartners-Logos-Website_03.gif" width="305" height="142" alt="IBM" />
        </a>
        <h3>Museum Partners</h3>
        <p>
          For the first time Australia’s leading natural history museums have come together for a national citizen science research project.
          Together we can identify frog species by their call and help to save Australia’s frogs.
        </p>
        <div className="partners-icons">
          <a href="https://www.bunnings.com.au/">
            <img src="images/OurPartners-bunnings-logo.jpg" width="225" height="101" alt="Bunnings"/>
          </a>
          <a href="http://www.fyna.com.au/">
            <img src="images/OurPartners-fyna-logo.jpg" width="185" height="101" alt="Fyner"/>
          </a>
        </div>    
        <div className="partners-icons">
          <a href="http://www.magnt.net.au">
            <img src="images/OurPartners-Logos-Website_06.gif" width="153" height="130" alt="Museum &amp Art Gallery NT"/>
          </a>
          <a href="https://museumsvictoria.com.au">
            <img src="images/OurPartners-Logos-Website_07.gif" width="272" height="130" alt="Museum Victoria"/>
          </a>
          <a href="http://www.qm.qld.gov.au">
            <img src="images/OurPartners-Logos-Website_08.gif" width="275" height="130" alt="QLD Museum"/>
          </a>
          <a href="http://www.samuseum.sa.gov.au">
            <img src="images/OurPartners-Logos-Website_10.gif" width="305" height="128" alt="SA Museum"/>
          </a>
          <a href="http://www.tmag.tas.gov.au">
            <img src="images/OurPartners-Logos-Website_11.jpg" width="201" height="128" alt="TAS Museum &amp; Art Gallery"/>
          </a>
          <a href="http://museum.wa.gov.au">
            <img src="images/OurPartners-Logos-Website_12.gif" width="461" height="128" alt="WA Museum"/>
          </a>
          <a href="#">
            <img src="images/OurPartners-Logos-Website_09.gif" width="267" height="130" alt="QLD Government"/>
          </a>
        </div>

        <h3>Supported by</h3>
        <p><strong>THIS PROJECT RECEIVED GRANT FUNDING FROM THE AUSTRALIAN GOVERNMENT</strong></p>

        <div className="partners-icons">
          <a href="#">
            <img src="images/OurPartners-Logos-Website_04.gif" width="662" height="142" alt="AustGov Inspiring Australia"/>
          </a>
        </div>

        <h3>Project Contributors</h3>
        <p>The FrogID Project was also made possible by the large number of contributors:</p>
        <ul>
          <li>Frog species information produced by Christopher Portway</li>
          <li>Distribution maps created by Christopher Portway, Blake Danis and Claude Moelan</li>
          <li>Frog calls edited by Tim Cutajar</li>
          <li>iOS &amp; Android Apps developed by IBM</li>
          <li>Website &amp; API developed by Beaconmaker</li>
        </ul>
        <p>Special thanks for contributing content to the FrogID project:</p>
        <ul>
          <li>Aaron Payne</li>
          <li>Ace Frawley</li>
          <li>Adam Brice</li>
          <li>Adam Parsons</li>
          <li>Akash Samuel</li>
          <li>Alex Anderson</li>
          <li>Alexander Dudley</li>
          <li>Alexandre Roux</li>
          <li>Anders Zimny</li>
          <li>Angus McNab</li>
          <li>Ben Parkhurst</li>
          <li>Ben Revell</li>
          <li>Bruce Thomson</li>
          <li>Cameron de Jong</li>
          <li>Chad Beranek</li>
          <li>Chris Jolly</li>
          <li>Chris Sanderson</li>
          <li>Christopher J Jolly</li>
          <li>Crystal Kelehear</li>
          <li>Dale Roberts</li>
          <li>Damian Lettoof</li>
          <li>Dan Lynch</li>
          <li>Dan O'Brien</li>
          <li>Dane Trembath</li>
          <li>Daniel O'Brien</li>
          <li>Dave Stewart</li>
          <li>David Nelson</li>
          <li>Dianne Clarke</li>
          <li>Eduard Galoyan</li>
          <li>Eric Vanderduys</li>
          <li>Evan Pickett</li>
          <li>Frank Lemckert</li>
          <li>Fred Jean Hort</li>
          <li>G A Hoye</li>
          <li>G. Little</li>
          <li>George Madani</li>
          <li>Gill Basnett</li>
          <li>Grant Webster</li>
          <li>Greg Clarke</li>
          <li>Greg Little</li>
          <li>Gunther Schmida</li>
          <li>H.B. Hines QPWS</li>
          <li>Hal Cogger</li>
          <li>Hans and Judy Beste</li>
          <li>J P Lawrence</li>
          <li>Jack Gamble</li>
          <li>Jan Anderson</li>
          <li>Jasmine Vink</li>
          <li>Jean and Fred Hort</li>
          <li>Jeremy Storey</li>
          <li>Jessica O’Donnell </li>
          <li>Joanne Ocock</li>
          <li>Jodi Rowley</li>
          <li>John Anderson</li>
          <li>John Wolf</li>
          <li>Jono Hooper</li>
          <li>Jordan Mulder</li>
          <li>Jordan Vos</li>
          <li>Jules Farquhar</li>
          <li>Jurgen Haider</li>
          <li>Keith McDonald</li>
          <li>Kim Williams</li>
          <li>Kris Bell</li>
          <li>Kristen Spyrdz</li>
          <li>Lockie Gilding</li>
          <li>Marion Anstis</li>
          <li>Matt Summerville</li>
          <li>Matthew Frawley</li>
          <li>Michael J Barritt</li>
          <li>Michael Mahony</li>
          <li>Michael McFadden</li>
          <li>Neil Zoglauer</li>
          <li>Paul Doughty</li>
          <li>Pete Reid</li>
          <li>Peter street</li>
          <li>R. and A. Williams</li>
          <li>Rob Davis</li>
          <li>Rob Valentic</li>
          <li>Robert Whyte</li>
          <li>Ruchira Somaweera</li>
          <li>Ryan Francis</li>
          <li>Scott Eipper</li>
          <li>Scott Goodwin</li>
          <li>Sean Severs</li>
          <li>Shane Black</li>
          <li>Simon Cherriman</li>
          <li>Stephen Mahony</li>
          <li>Stephen Richards</li>
          <li>Tim Bawden</li>
          <li>Tim Cutajar</li>
          <li>Tim Rudman</li>
          <li>Todd Burrows</li>
          <li>Troy Bell</li>
          <li>Wendy Eiby </li>
        </ul>
      </div>
    )
  }
}

export default Partners