import hostUrl from 'Portal/constants/api.jsx';
import { createResource, mergeReducers } from 'redux-rest-resource';

const frogResource = createResource({
  name: 'frog',
  url: `${hostUrl}/frogs/:id`
});

const types = {
  ...frogResource.types,
};

const actions = {
  ...frogResource.actions,
}

const reducers = mergeReducers(
  frogResource.reducers, {
  });

export {types, actions, reducers};