import React from 'react'
import { dateTimeToString } from 'Admin/components/captures/List'
import { convertToHttps } from 'Admin/helpers'

const formatCoord = (coord) => Number(coord).toFixed(3);
/// this is similar to components/captures/List except
// group gaptures: do not link to the detail page
// do not show notes.
// I'm just keeping it as a separate component in case we need to switch back
// to the with-links and with-notes style.

class GroupCaptureList extends React.Component {
  render() {
    const { base_url, captures, onProcess, onDelete } = this.props

    const removeUnderscore = (str) => {
      if (!str) return "";
      return str.replace(/_/g, " ");
    }

    const capitaliseFirstLetter = (str) => {
      if (!str) return "";
      return str.charAt(0).toUpperCase() + str.slice(1);
    }

    return (
      <>
        {
          captures.map(capture => (
            <dl key={capture.id} className="captures-table">
              <div className="captures-table__cell captures-table__header">
                <div>
                  <dt className="captures-table__term">Capture ID</dt>
                  <dd><h2>{capture.id}</h2></dd>
                </div>
                  { capture.images.length > 0 &&// no more than 1 image
                    <img src={ convertToHttps(capture.images[0].thumb) } alt={capture.images[0].caption} className="captures-table__thumbnail" />
                  }
              </div>
              <div className="captures-table__cell">
                <dt className="captures-table__term">Capture date</dt>
                <dd>{dateTimeToString(capture.capture_time)}</dd>
              </div>
              <div className="captures-table__cell">
                <dt className="captures-table__term">Media</dt>
                <dd>Audio, images</dd>
              </div>
              <div className="captures-table__cell">
                <dt className="captures-table__term">Location</dt>
                <dd>{capture.state}<br />{capture.lat && capture.lng && `Lat: ${formatCoord(capture.lat)}, Lng: ${formatCoord(capture.lng)}`}</dd>
              </div>
              <div className="captures-table__cell">
                <dt className="captures-table__term">Status</dt>
                <dd><svg xmlns="http://www.w3.org/2000/svg" width="14.311" height="14.309" viewBox="0 0 14.311 14.309" className="captures-table__verification-icon">
                  <path id="Path_58" data-name="Path 58" d="M112.234,36.043a2.385,2.385,0,0,1,0-1.812l.08-.191a2.391,2.391,0,0,0-1.287-3.115l-.175-.079a2.327,2.327,0,0,1-1.287-1.287L109.5,29.4a2.423,2.423,0,0,0-3.131-1.287l-.159.064a2.425,2.425,0,0,1-1.828,0l-.143-.064a2.4,2.4,0,0,0-3.1,1.3l-.064.127a2.327,2.327,0,0,1-1.287,1.287l-.143.064a2.4,2.4,0,0,0-1.272,3.116l.064.143a2.385,2.385,0,0,1,0,1.812l-.064.175a2.355,2.355,0,0,0,1.287,3.1l.159.064a2.327,2.327,0,0,1,1.287,1.287l.079.175a2.375,2.375,0,0,0,3.1,1.3l.175-.08a2.425,2.425,0,0,1,1.828,0l.143.064a2.391,2.391,0,0,0,3.115-1.287l.064-.127a2.327,2.327,0,0,1,1.287-1.287l.127-.048a2.372,2.372,0,0,0,1.287-3.115Zm-7.566,2.146-3.1-2.607.954-1.129,1.971,1.669,3.481-4.133,1.129.954Z" transform="translate(-98.188 -27.935)" />
                </svg>
                  {capitaliseFirstLetter(capture.validated_status)} – {removeUnderscore(capture.validated_type)}<br />
                  <i>{capture.validated_frog_names.join(", ")}</i></dd>
              </div>
              <div className="captures-table__cell">
                <dt className="captures-table__term">Habitat</dt>
                <dd>{capture.filters_readable.habitat}</dd>
              </div>
              <div className="captures-table__cell">
                <dt className="captures-table__term">Water body</dt>
                <dd>{capture.filters_readable.water_body}</dd>
              </div>
              <div className="captures-table__cell">
                <dt className="captures-table__term">Null record</dt>
                <dd>{capture.null_record == "true" ? "True": "False"}</dd>
              </div>
              <div className="captures-table__cell">
                <dt className="captures-table__term">Formal Biodiversity Survey</dt>
                <dd>{capture.is_bam == "true" ? "True": "False"}</dd>
              </div>
            </dl>
          ))
        }
      </>
    )
  }
}

// const statusLabel = status => {
//   switch (status) {
//     case 'published':
//       return 'Validated'
//     default:
//       return _.startCase(status);
//   }
// }

// const validatedStatusLabelForUser = (capture) => {
//   switch (capture.validated_status) {
//     case 'pending':
//       return _.startCase(capture.pending_status)
//     case 'spam':
//       return ''
//     case 'validated':
//       return ''
//     default:
//       return ' - ' + _.startCase(capture.validated_type)
//   }
// }
// const validatedTypeLabelForUser = (capture) => {

//   if (capture.validated_status !== 'published') {
//     return null
//   }

//   switch (capture.validated_type) {
//     case 'frogs':
//       return capture.validated_frog_names.join(', ')
//     case 'not_a_frog':
//       return _.startCase(capture.not_a_frog_type)
//     case 'unidentified':
//       return capture.frog_group_title
//     default:
//       return null
//   }
// }

export default GroupCaptureList
