import hostUrl from 'Portal/constants/api.jsx';
import { createResource, mergeReducers } from 'redux-rest-resource';

const resource = createResource({
  name: 'frogGroup',
  url: `${hostUrl}/frog_groups/:id`
});

const types = {
  ...resource.types,
};

const actions = {
  ...resource.actions,
}

const reducers = mergeReducers(
  resource.reducers, {
  });

export {types, actions, reducers};