import React, { Component } from 'react'
import { Field } from 'redux-form'
import { FormInput, FormAutocomplete, FormImageUpload } from 'Admin/components/FormInputs'

class GroupFields extends Component {
  render() {
    const { isSchool } = this.props

    return (
      <div className="centre-column edit-form">
        <div className="edit-form__row edit-form__row--w-full">
          <label>Name</label>
          <Field autoFocus name="name" component={FormInput} />
        </div>
        <div className="edit-form__row edit-form__row--w-full">
          <label>Description</label>
          <Field name="desc" componentClass="textarea" component={FormInput} className="group-edit__label-tall" />
        </div>
        <div className="edit-form__row edit-form__row--w-full">
          <label>Postcode</label>
          <Field name="postcode" component={FormInput} />
        </div>
        <div className="edit-form__row edit-form__row--w-full">
          <label>Avatar</label>
          <Field
            name="avatar"
            component={FormImageUpload}
          />
        </div>
        <div className="edit-form__row edit-form__row--w-full">
          <label className="checkbox-label">
            <Field type="checkbox" name="private" component={FormInput} />
            Hide this group from the group listing?
          </label>
        </div>
        <div className="edit-form__row edit-form__row--w-full">
          <label className="checkbox-label">
            <Field type="checkbox" name="all_captures_private" component={FormInput} />
            Keep all captures from this group as private (hide from DataVis)?
          </label>
        </div>
        <div className="edit-form__row edit-form__row--w-full">
          <label className="checkbox-label">
            <Field type="checkbox" name="is_school" component={FormInput} />
            Is this a school group?
          </label>
        </div>

        {
          isSchool &&
          <div className="form-group">
            <div className="edit-form__row edit-form__row--w-full">
              <label>School Name</label>
              <Field name="school_name" component={FormInput} />
            </div>
          </div>
        }
        </div>
        )
  }
}

        export default GroupFields
