import React from 'react'
import { Link } from 'react-router'


class ProfileSharing extends React.Component {
  state = {
    copyAlertClosed: true,
  }

  copyLink(text) {
    navigator.clipboard.writeText("https://www.frogid.net.au" + text)
    this.setState({ copyAlertClosed: !this.state.copyAlertClosed })
  }

  closeCopyLinkDialog() {
    this.setState({ copyAlertClosed: true })
  }

  render() {
    const { profile_url, display_name } = this.props
    return (
      <>
        {
          <div>
            {/* Copy link alert */}
            <div className={this.state.copyAlertClosed ? "alert--closed" : "alert--opened"}>
              <div className="alert__background"></div>
              <div className="alert__dialog-box">
                <button className="alert__close-button" onClick={this.closeCopyLinkDialog.bind(this)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="10.599" height="10.599" viewBox="0 0 10.599 10.599">
                    <path id="Path_75" data-name="Path 75" d="M6,13.77,13.77,6M6,6l7.77,7.77" transform="translate(-4.586 -4.586)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                  </svg>
                </button>
                <p>Profile link has been copied to your clipboard</p>
              </div>
            </div>
            <button
              onClick={(e) => {
                e.preventDefault();
                window.location.href = 'https://www.facebook.com/sharer/sharer.php?u=https://www.frogid.net.au' + profile_url
              }
              }
              className="profile-info__sharing-button">
              <svg id="Group_27" data-name="Group 27" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
                <path id="fb" d="M4.78,14.16V7.08H6.7l.26-2.44H4.78V3.42c0-.64.06-.98.96-.98h1.2V0H5.02C2.71,0,1.89,1.18,1.89,3.17V4.63H.45V7.07H1.89v7.08H4.78Z" transform="translate(8.55 5)" fillRule="evenodd" />
                <g id="Ellipse_3" data-name="Ellipse 3" fill="none" stroke="#000" strokeWidth="1">
                  <circle cx="12.5" cy="12.5" r="12.5" stroke="none" />
                  <circle cx="12.5" cy="12.5" r="12" fill="none" />
                </g>
              </svg>
            </button>

            <button
              onClick={(e) => {
                e.preventDefault();
                window.location.href = 'https://twitter.com/share?text=&url=https://www.frogid.net.au' + profile_url + '&via=FrogIDAus'
              }
              }
              className="profile-info__sharing-button">
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
                <g id="Group_29" data-name="Group 29" transform="translate(-66)">
                  <path id="twitter" d="M5.99,2.77l.02.43-.43-.06A6.951,6.951,0,0,1,1.5,1.13L.93.57.78.99a2.484,2.484,0,0,0,.54,2.56c.34.36.27.42-.33.2a1.245,1.245,0,0,0-.4-.1A2.969,2.969,0,0,0,.9,4.82,2.766,2.766,0,0,0,2.08,5.93l.42.2L2,6.14c-.48,0-.5.01-.45.19a2.6,2.6,0,0,0,1.6,1.42l.53.18-.46.28a4.877,4.877,0,0,1-2.3.64,2.349,2.349,0,0,0-.7.07,6.442,6.442,0,0,0,1.66.76,7.269,7.269,0,0,0,5.63-.64,7.588,7.588,0,0,0,2.86-3.36,8.941,8.941,0,0,0,.58-2.6c0-.4.03-.45.51-.93a5.691,5.691,0,0,0,.6-.67c.09-.16.08-.16-.36-.02-.73.26-.83.23-.47-.16a2.455,2.455,0,0,0,.58-.93c0-.03-.13.02-.27.1a4.106,4.106,0,0,1-.76.29l-.46.15L9.89.62A3.43,3.43,0,0,0,9.16.24a2.921,2.921,0,0,0-1.5.04A2.472,2.472,0,0,0,5.99,2.77Z" transform="translate(72.78 7.839)" fillRule="evenodd" />
                  <g id="Ellipse_5" data-name="Ellipse 5" transform="translate(66)" fill="none" stroke="#000" strokeWidth="1">
                    <circle cx="12.5" cy="12.5" r="12.5" stroke="none" />
                    <circle cx="12.5" cy="12.5" r="12" fill="none" />
                  </g>
                </g>
              </svg>
            </button>
            <button
              onClick={(e) => {
                e.preventDefault();
                window.location.href = 'mailto:?body=%20' + display_name + '%20public%20profile%20on%20the%20FrogID%20website%20-%20https://frogid.net.au' + profile_url
              }
              }
              className="profile-info__sharing-button">
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
                <g id="Group_28" data-name="Group 28" transform="translate(-33)">
                  <path id="email" d="M.91,8.42V0L5.64,4.76.91,8.42Zm13,0L9.17,4.76,13.91,0ZM7.41,6.54,6.28,5.4.91,9h13L8.54,5.4ZM1.3,0H13.52L7.41,5.09Z" transform="translate(38.09 8)" fillRule="evenodd" />
                  <g id="Ellipse_4" data-name="Ellipse 4" transform="translate(33)" fill="none" stroke="#000" strokeWidth="1">
                    <circle cx="12.5" cy="12.5" r="12.5" stroke="none" />
                    <circle cx="12.5" cy="12.5" r="12" fill="none" />
                  </g>
                </g>
              </svg>
            </button>
            <Link to={profile_url} className="profile-info__sharing-button">{profile_url}</Link>
            <button
              onClick={() => this.copyLink(profile_url)}
              className="profile-info__sharing-button">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                <path id="Path_91" data-name="Path 91" d="M8,16H6a2,2,0,0,1-2-2V6A2,2,0,0,1,6,4h8a2,2,0,0,1,2,2V8M10,20h8a2,2,0,0,0,2-2V10a2,2,0,0,0-2-2H10a2,2,0,0,0-2,2v8A2,2,0,0,0,10,20Z" transform="translate(-3 -3)" fill="none" stroke="#1e852c" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
              </svg>
            </button>
          </div>
        }
      </>
    )
  }
}

export default ProfileSharing
