import React from 'react';
import { IndexLink, Link, browserHistory } from 'react-router';
import { FormGroup, FormControl, Button } from 'react-bootstrap';
import Partners from 'Portal/components/Partners.jsx'
import Navigation from 'Portal/components/Navigation.jsx';
import Footer from 'Portal/components/Footer.jsx';
import { scrollTo } from 'shared/scrollTo'

export default class Page extends React.Component {

  render() {
    return (
      <div className="app-portal">
        <Navigation />
        <div className="home-feature">
          <div className="container">
            <div className="feature-inner hor">
              <div className="detail">
                <h1>Get Involved</h1>
                <p>
                  There are two ways to get involved in FrogID count and help save Australia’s frogs.
                </p>
                <div className="steps get-involved">
                  <div className="step">
                    <p className="title">Out in the field</p>
                    <img className="img-icon" src="/images/litoria_bella_call_600x600-white.png" />
                    <p>
                      Download the FrogID app, create a FrogID account, find, record and help match your frog calls.
                    </p>
                    <a onClick={() => scrollTo('#record_steps')} className="btn btn-primary">Download App</a>
                  </div>
                  <div className="step">
                    <p className="title">Explore FrogID records</p>
                    <img className="img-icon" src="/images/litoria_bella_laptop600x600.png" />
                    <p>
                      Discover citizen scientist submissions by species and location, with both first year results and all records, revealed on an interactive map!
                    </p>
                    <a href="/explore" className="btn btn-primary">View data</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section-gray">
          <div className="container">
            <div id="record_steps" className="section section-steps text-center">
              <h2>Out in the field sign up steps</h2>
              <div className="steps">
                <div className="step">
                  <p className="title">Download <br />App</p>
                  <img className="img-icon" src="/images/01.png" />
                  <p>
                    Download the FrogID app, <a target="new" href="http://bit.ly/Frogid-ios">iOS App</a> or <a target="new" href="
https://play.google.com/store/apps/details?id=au.net.australianmuseum.frogid">Android app</a>, create an account to start recording frogs.
                  </p>
                </div>
                <div className="step">
                  <p className="title">Explore <br />Habitat</p>
                  <img className="img-icon" src="/images/02.png" />
                  <p>
                    Get out in the field to discover different frogs and their diverse habitats, remember to wash your shoes between sites, and be careful where you tread.
                  </p>
                </div>
                <div className="step">
                  <p className="title">Record <br />&amp; Submit</p>
                  <img className="img-icon" src="/images/03.png" />
                  <p>
                    Record the frog calls you hear in the field with the FrogID app and submit them to Australia's first national frog count.
                  </p>
                </div>
                <div className="step">
                  <p className="title">Get <br />Results</p>
                  <img className="img-icon" src="/images/04.png" />
                  <p>
                    Check back later to find your frog calls identified by the Australian Musuem and Audio DNA experts.
                  </p>
                </div>
              </div>
              <a target="new" href="http://bit.ly/Frogid-ios" className="btn btn-primary">Download iOS App</a>
              <a target="new" href="https://play.google.com/store/apps/details?id=au.net.australianmuseum.frogid" className="btn btn-primary">Download Android App</a>
              <p>Have a question? See our <Link to="/faq">FAQs section</Link> and view our <Link to="/terms">Terms of Use.</Link></p>
            </div>
            <div id="join_lab_steps" className="section section-steps text-center">
              <h2>Back at your desk sign up steps</h2>
              <div className="steps">
                <div className="step">
                  <p className="title">Set Up &amp; Join a <br />Group</p>
                  <img className="img-icon" src="/images/05.png" />
                  <p>
                    <Link to="/signup">Create an account</Link> and join or create a group to contribute together
                  </p>
                </div>
                <div className="step">
                  <p className="title">Benchmark &amp; <br />Compare Groups</p>
                  <img className="img-icon" src="/images/06.png" />
                  <p>
                    Taking part in the project allows you to compare your work with other participants of the program.
                  </p>
                </div>
                <div className="step">
                  <p className="title">Listen &amp; Identify <br />Calls</p>
                  <img className="img-icon" src="/images/07.png" />
                  <p>
                    Become an audio DNA expert by listening and validating the calls submitted by participants.
                  </p>
                </div>
                <div className="step">
                  <p className="title">Review &amp; Discuss <br />Findings</p>
                  <img className="img-icon" src="/images/08.png" />
                  <p>
                    Discuss the findings of the program by viewing species validations, distribution and emerging patterns.
                  </p>
                </div>
              </div>
              <a href="/explore" className="btn btn-primary">View data</a>
              <p>Have a question? See our <Link to="/faq">FAQs section</Link></p>
            </div>
          </div>
        </div>

        <div id="education" className="container">
          <div className="section hor">
            <div className="image">
              <img src="https://res.cloudinary.com/beaconmaker/image/upload/c_fit,h_450,w_680/v1509831012/education_01_dwmhag.jpg" />
            </div>
            <div className="detail">
              <div className="desc">
                <h2>Education</h2>
                <p>
                  Teachers can register a class of students as a group to contribute towards this wonderful citizen science project.
                </p>
              </div>
              <div>
                <Link className="btn btn-cta" to="/frogs">Learn more about FrogID</Link>
                <Link className="btn btn-cta" to="/signup">Create a group</Link>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );

  }
}
