import _ from 'lodash'

const questions = [
  // Easy questions
  {
    audio: 'http://res.cloudinary.com/ausmus/video/upload/v1512098614/s6eyzhuchwdgev6lnass.m4a',
    answers: [
      {audio: 'http://res.cloudinary.com/ausmus/video/upload/v1502254442/rrkywfzwcs2wnb4wm5gp.m4a', label: 'Litoria peronii', correct: true},
      {audio: 'http://res.cloudinary.com/ausmus/video/upload/v1479990993/ab3kjrv5qe8tyghv1shx.m4a', label: 'Litoria tylerii'},
      {audio: 'http://res.cloudinary.com/ausmus/video/upload/v1522637576/hjxm7q0fmomhrss43ae4.m4a', label: 'Litoria personata'},
      {audio: 'http://res.cloudinary.com/ausmus/video/upload/v1479991752/hvqp1gbbp5f8uylgtqj2.m4a', label: 'Litoria phyllochroa'},
      {audio: 'http://res.cloudinary.com/ausmus/video/upload/v1479991056/ttetbvtm6yn5aj1mzt0z.m4a', label: 'Limnodynastes tasmaniensis'},
    ]
  },
  {
    audio: 'http://res.cloudinary.com/ausmus/video/upload/v1514111930/cxgxcwkwwgp7rrbjw4kn.m4a',
    answers: [
      {audio: 'http://res.cloudinary.com/ausmus/video/upload/v1514943706/owwr6n9j4mx7oeytjda0.m4a', label: 'Limnodynastes peronii', correct: true},
      {audio: 'http://res.cloudinary.com/ausmus/video/upload/v1479991679/iyooa78yjkwya9rz2jlk.m4a', label: 'Limnodynastes dumerilii, '},
      {audio: 'http://res.cloudinary.com/ausmus/video/upload/v1479991056/ttetbvtm6yn5aj1mzt0z.m4a', label: 'Limnodynastes tasmaniensis'},
      {audio: 'http://res.cloudinary.com/ausmus/video/upload/v1479990764/zyba01rfqbuatw3owdma.m4a', label: 'Crinia signifera'},
      {audio: 'http://res.cloudinary.com/ausmus/video/upload/v1506406996/vf0nzqsgzqhjellc6hjs.m4a', label: 'Platyplectrum ornatum'},
    ]
  },
  {
    audio: 'http://res.cloudinary.com/ausmus/video/upload/v1502254089/emuls6jtbycwwkvbwvxx.m4a',
    answers: [
      {audio: 'http://res.cloudinary.com/ausmus/video/upload/v1522637358/q2t2wnsfez8x7tadtwm7.m4a', label: 'Litoria aurea', correct: true},
      {audio: 'http://res.cloudinary.com/ausmus/video/upload/v1514151355/eawvqlxk1v1iavtlsezo.m4a', label: 'Litoria raniformis'},
      {audio: 'http://res.cloudinary.com/ausmus/video/upload/v1479991352/speidjcpbkg6yurflban.m4a', label: 'Litoria gracilenta'},
      {audio: 'http://res.cloudinary.com/ausmus/video/upload/v1511222196/bmwpluztnatwat8lstgt.m4a', label: 'Litoria infrafrenata'},
      {audio: 'http://res.cloudinary.com/ausmus/video/upload/v1502255275/nzztlw9onwwxmx4qmjfi.m4a', label: 'Rhinella marina'},
    ]
  },
  // Hard questions
  {
    audio: 'http://res.cloudinary.com/ausmus/video/upload/v1509607602/kjcauia1li9ikxozeyqo.m4a',
    answers: [
      {audio: 'http://res.cloudinary.com/ausmus/video/upload/v1509607602/adkxuxsyxw4gpxbfplgu.m4a', label: 'Litoria nasuta', correct: true},
      {audio: 'http://res.cloudinary.com/ausmus/video/upload/v1479991775/qzse7suxxdnkobjy6qin.m4a', label: 'Litoria latopalmata'},
      {audio: 'http://res.cloudinary.com/ausmus/video/upload/v1479990943/f8yory4ze9ethwk8f6wc.m4a', label: 'Litoria brevipalmata'},
      {audio: 'http://res.cloudinary.com/ausmus/video/upload/v1507771225/dgsmvohqwbt1fmihznmk.m4a', label: 'Litoria cooloolensis'},
      {audio: 'http://res.cloudinary.com/ausmus/video/upload/v1506402787/ldremawa1ufobesxfgdz.m4a', label: 'Litoria coplandi'},
    ]
  },
  {
    audio: 'http://res.cloudinary.com/ausmus/video/upload/v1479991112/f7wswnqshcvy96xe1fkd.m4a',
    answers: [
      {audio: 'http://res.cloudinary.com/ausmus/video/upload/v1479991442/nbx4axt3s5odti2lbpad.m4a', label: 'Litoria barringtonensis', correct: true},
      {audio: 'http://res.cloudinary.com/ausmus/video/upload/v1479991297/mductufbdsot7ffyniqm.m4a', label: 'Litoria pearsoniana'},
      {audio: 'http://res.cloudinary.com/ausmus/video/upload/v1479991752/hvqp1gbbp5f8uylgtqj2.m4a', label: 'Litoria phyllochroa'},
      {audio: 'http://res.cloudinary.com/ausmus/video/upload/v1506404307/thltdged3mw1aknskde1.m4a', label: 'Litoria jungguy'},
      {audio: 'http://res.cloudinary.com/ausmus/video/upload/v1508122388/oroe2aeknhabta4uy6jq.m4a', label: 'Litoria citropa'},
    ]
  },
  {
    audio: 'http://res.cloudinary.com/ausmus/video/upload/v1479991827/fxqiiaepkqhvn0ixlz9o.m4a',
    answers: [
      {audio: 'http://res.cloudinary.com/ausmus/video/upload/v1520054742/ceawa0jfg7vqpmwbnavd.m4a', label: 'Uperoleia fusca', correct: true},
      {audio: 'http://res.cloudinary.com/ausmus/video/upload/v1479990802/kqbet3fopwbzrwmgtpkq.m4a', label: 'Pseudophryne bibronii'},
      {audio: 'http://res.cloudinary.com/ausmus/video/upload/v1479991188/w5wuq3eq7cb81ba6b2dx.m4a', label: 'Pseudophryne coriacea'},
      {audio: 'http://res.cloudinary.com/ausmus/video/upload/v1479991596/aqhqmxv1vxsjpuar9owz.m4a', label: 'Pseudophryne australis'},
      {audio: 'http://res.cloudinary.com/ausmus/video/upload/v1479991723/plarjyvi3ccld4bswo12.m4a', label: 'Crinia subinsignifera'},
    ]
  },
]

const shuffledQuestions = _.map(questions, question => ({
  audio: question.audio,
  answers: _.shuffle(question.answers)
}))

export default _.shuffle(shuffledQuestions)