import moment from "moment";
export const toDateLong = (date) => moment(date).format("MM/DD/YYYY hh:mm a");

export const isEncoded = (value) => {
    try {
        decodeURIComponent(value);
    } catch (error) {
        return false;
    }
    return true;
}

// convert http to https (required for db stored cloudinary urls)
export const convertToHttps = (inputString, page) => {
    console.log('###########', page, inputString)
    if (!inputString) return inputString
	return inputString.replace(/^http:/, 'https:');
}